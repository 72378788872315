<!-- <i class="material-icons" style="font-size: 100px;color: #ff0000">info</i> -->
<div mat-dialog-content>
    <!-- <h1 style="text-align:center"><strong></strong></h1> -->
    <h2 style="text-align:center;font-size: x-large; color: rgb(170, 32, 32);">Détail des véhicules :
    </h2>
    <div class="div-form">
        <div class="form-group">
            <div class="table-responsive">
                <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th>N° VD</th>
                            <th class="text-center">Type</th>
                            <th class="text-center">Immatriculation</th>
                            <th class="text-center">Volume theorique</th>
                            <th class="text-center">Volume reel de chargement</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let element of data.vehicles, let index = index">
                            <td class="text-center tnt"><b>{{index+1}}</b></td>
                            <td><strong>{{element?.num_VD}}</strong>
                            </td>
                            <td class="text-center">{{element?.type}}</td>
                            <td class="text-center">
                                <div class="badge badge-warning">{{element?.immatriculation}}</div>
                            </td>
                            <td class="text-center">
                                <b>{{element?.theoretical_volume}}</b>
                            </td>
                            <td class="text-center">
                                <b>{{element?.real_loading_volume}}</b>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="row float-right margin-top">
    <!-- <button style="margin-right: 15px;" [mat-dialog-close]="false" class="btn btn-info">NON</button> -->

    <button style="margin-right: 15px;" [mat-dialog-close]="true" class="btn btn-danger">OUI</button>
</div>