import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-detail-commande-client',
  templateUrl: './detail-commande-client.component.html',
  styleUrls: ['./detail-commande-client.component.css']
})
export class DetailCommandeClientComponent implements OnInit {

  mapLoaded: boolean;
  map: google.maps.Map;
  center: google.maps.LatLngLiteral;

  source: google.maps.LatLngLiteral;
  destination: google.maps.LatLngLiteral;

  options: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    scrollwheel: true,
    disableDefaultUI: true,
    disableDoubleClickZoom: true,
    zoom: 12
  }


  cmd: any = {}
  cmdID: string = '';
  statusCmds: any = []

  constructor(private route: ActivatedRoute,
    private cmdsService: CommandesService) { }

  ngOnInit(): void {
    this.cmdsService.getLabeledStatus().then(
      (lst) => {
        this.statusCmds = lst
      }
    )

    this.route.queryParams.subscribe((params) => {
      this.cmdID = params.cmd;
      this._fetchCmd();
      // console.log(this.cmd);
    });
  }

  _fetchCmd() {
    // console.log(cid);
    this.cmdsService.getOneCMD(this.cmdID).then((d) => {
      console.log("dddddddddddddd")
      console.log(d)

      if (d) {
        // console.log("dddddddddddddd")
        // console.log(d)
        this.cmd = d;
        this._initMap();
      } else {
        alert('Aucune commande valide');
        location.href = '/commandes';
      }
    });


  }

  _initMap() {
    // this.ds = new google.maps.DirectionsService();
    // this.dr = new google.maps.DirectionsRenderer({
    //   map: null,
    //   suppressMarkers: true
    // });

    navigator.geolocation.getCurrentPosition(position => {

      this.source = {
        lat: this.cmd.latitude,
        lng: this.cmd.longitude
      };

      // console.log("this.cmd.transport_affect")
      // console.log(this.cmd.transport_affect)
      this.destination = (this.cmd.transport_affect != null && this.cmd.transport_affect.length > 0) ? {
        lat: this.cmd.transport_affect[this.cmd.transport_affect.length - 1].transporter_id.latitude,
        lng: this.cmd.transport_affect[this.cmd.transport_affect.length - 1].transporter_id.longitude
      } : null;

      // initialize the map container
      this.map = new google.maps.Map(document.getElementById('map-canvas'), {
        ...this.options,
        center: this.source
      });

      this.map.addListener('tilesloaded', () => {
        this.mapLoaded = true;
      });

      // adding a marker
      var markerStart = new google.maps.Marker({
        position: this.source,
        icon: {
          url: './../../../../assets/images/destination_map_marker.png',
          // anchor: new google.maps.Point(35, 10),
          // scaledSize: new google.maps.Size(100, 100)
        },
        map: this.map
      });

      if (this.destination != null) {
        var destinationMarker = new google.maps.Marker({
          position: this.destination,
          icon: {
            url: './../../../../assets/images/destination_map_marker2.png',
            // anchor: new google.maps.Point(35, 10),
            // scaledSize: new google.maps.Size(100, 100)
          },
          map: this.map
        });
      }

      // this.setRoutePolyline();
    });
  }

  addComment(comment) {
    // console.log(cid);
    if (comment?.value != null && comment?.value != "")
      this.cmdsService.addComment(this.cmdID, "6221fc2e46764446a99c7d74",
        "Admin", "admin", comment.value).then((data: any) => {
          // console.log("dddddddddddddd")
          // console.log(data)

          if (data) {
            this.cmd = data;
            comment.value = ""
          }
        });
  }

}
