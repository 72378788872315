import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DetailsCommandesComponent } from 'src/app/components/shared/dialogs/details-commandes/details-commandes.component';
import { DetailsVehiclesComponent } from 'src/app/components/shared/dialogs/details-vehicles/details-vehicles.component';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-delivery-forecasts',
  templateUrl: './delivery-forecasts.component.html',
  styleUrls: ['./delivery-forecasts.component.css']
})
export class DeliveryForecastsComponent implements OnInit {

  cmdsByVille: any = [];

  constructor(private cmdsService: CommandesService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.listValidatedCmds()
  }

  async listValidatedCmds() {
    await this.cmdsService.allDeliveryForecastsCmds().then((res: any) => {
      console.log("res listCmdsClient")
      console.log(res)
      if (res) {
        this.cmdsByVille = res
      }
    });
  }

  async showCommandes(cmds) {
    window.scrollTo(0, 0);
    // console.log("amineeeeeeeeee")
    // this.selectedVirement = index
    const confirmDialog = this.dialog.open(DetailsCommandesComponent, {
      data: {
        commandes: cmds,
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
      }
    });
  }

  showVehicles(vehicles) {
    if (vehicles.length > 0) {
      window.scrollTo(0, 0);
      // console.log("amineeeeeeeeee")
      // this.selectedVirement = index
      const confirmDialog = this.dialog.open(DetailsVehiclesComponent, {
        data: {
          vehicles: vehicles,
        }
      });

      confirmDialog.afterClosed().subscribe(result => {
        if (result === true) {
        }
      });
    }
  }

}
