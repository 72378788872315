<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-display2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Virements
                <div class="page-title-subheading">Liste des virements du client.
                </div>
            </div>
        </div>
        <!-- <div class="page-title-actions">
            <div>
                <form class="form-inline" action="" method="post" onsubmit="return false;"
                    (onsubmit)="getVirrementClients();">
                    <div class="mb-2 mr-sm-2 mb-sm-0 position-relative form-group">
                        <select class="form-control selectric" (change)="selectClient($event)">
                            <option value="Selectionnez le client">
                                Selectionnez le client</option>
                            <option [value]="cl.client._id" *ngFor="let cl of clientsList">
                                {{"["+cl.client.company_name+"] " +cl.client.prenom_client + " " + cl.client.nom_client}}</option>
                        </select>
                    </div>
                    <button type="submit" (click)="getVirrementClients()"
                        class="btn btn-primary">Submit</button>
                </form>
            </div>
        </div> -->
    </div>
</div>

<div class="row space">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Virements
                <div class="btn-actions-pane-right">
                </div>
            </div>
            <div class="table-responsive">
                <div class="budget-price justify-content-center" *ngIf="listVirements?.length === 0">
                    <h5 class="text-center">Aucune virement du client {{client?.company_name}} </h5>
                </div>
                <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                    *ngIf="listVirements?.length > 0">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th class="text-left">Expéditeur</th>
                            <th class="text-center">Total</th>
                            <th class="text-center">Date de virement</th>
                            <th class="text-center">Etat</th>
                            <th class="text-center">Nombre<br>colis<br>livrée</th>
                            <th class="text-center">Nombre<br>colis<br>retournée</th>
                            <th class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let virment of listVirements, let index = index" [class]="(index==selectedVirement)?'row-selected':''">
                            <td class="text-center text-muted">#{{index+1}}</td>
                            <td class="text">Nano</td>
                            <td class="text-center">{{virment.total}}</td>
                            <td class="text-center">{{virment.date_action | date:'d MMM y'}}<br>
                                <b>{{virment.date_action | date:'HH:mm'}}</b></td>
                            <td class="text-center">
                                <div *ngIf="virment.etat_account == 'demande'" class="badge badge-warning">
                                    EN COURS DE PAIEMENT</div>
                                    <div *ngIf="virment.etat_account == 'paied'" class="badge badge-success">
                                        PAYÉE</div>
                            </td>
                            <td class="text-center">{{livredCmd(virment.commandes)}}</td>
                            <td class="text-center">{{returnedCmd(virment.commandes)}}</td>
                            <td class="text-center">
                                <button class="btn btn-warning text-white"
                                    (click)="goToNewTab(virment._id, '/facture-paiement-client', index)"><i
                                        class="metismenu-icon pe-7s-print text-white"></i>
                                    Imprimer</button>
                                <button class="btn-wide btn btn-primary" (click)="showVirement(virment, index)">Détail</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>