<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-next-2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Importion Via Excel
                <div class="page-title-subheading">Importation des produits.
                </div>
            </div>
        </div>
    </div>
</div>

<div class="main-card mb-12 card">
    <div class="card-header-tab card-header">
        <div class="card-header-title">
            <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
            Etat import - Produits
        </div>
        <div class="btn-actions-pane-right">
            <input hidden type="file" (change)="readExcel($event)" multiple="false" #file>
            <button type="button" (click)="file.click()" data-toggle="tooltip" title="Example Tooltip"
                data-placement="bottom" class="btn-shadow mr-3 btn btn-info">
                <i class="fa fa-star"> Importer fichier excel</i>
            </button>
        </div>
    </div>
    <div class="card-body">
        <div class="budget-price justify-content-center" *ngIf="msgError != null">
            <h2 class="text-center" style="color: red;">{{msgError}}<br>{{itemsError}}</h2>
        </div>
        <form class="" action="" method="post" onsubmit="return false;" (onsubmit)="addProducts();">

            <ul class="list-group">
                <li class="list-group-item" *ngFor="let item of products, let index = index">
                    <div class="main-card mb-12 card">
                        <div class="card-header-tab card-header">

                            <div class="card-header-title">
                                <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
                                Produit
                            </div>
                            <div class="btn-actions-pane-right">
                                <button type="button" (click)="deleteCmd(index)" class="btn-shadow mr-3 btn btn-danger">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>

                        </div>
                        <div class="card-body">
                            <div class="position-relative row form-group">
                                <!-- <div class="row"> -->
                                <div class="col-sm-3">
                                    <legend for="product" class="space-bottom">Code
                                    </legend>
                                    <input name="ia_item_code" id="ia_item_code" type="text" disabled class="form-control"
                                        value="{{getValue(item?.product?.ia_item_code)}}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.product?.hasOwnProperty('ia_item_code') || getValue(item?.product?.ia_item_code)==''">
                                        Ajoutez le code du produit
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="product" class="space-bottom">Nom du produit</legend>
                                    <input name="ia_name" id="ia_name" type="text" disabled
                                        value="{{getValue(item?.product?.ia_name)}}" class="form-control">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.product?.hasOwnProperty('ia_name') || getValue(item?.product?.ia_name)==''">
                                        Ajoutez le nom du produit
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="product" class="space-bottom">FA Code</legend>
                                    <input name="ia_fa_code" id="ia_fa_code" type="text" disabled
                                        class="form-control" value="{{getValue(item?.product?.ia_fa_code)}}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.product?.hasOwnProperty('ia_fa_code') || getValue(item?.product?.ia_fa_code)==''">
                                        Ajoutez FA Code
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="product" class="space-bottom">Désignation du produit</legend>
                                    <input name="Colonne1" id="Colonne1" type="text" disabled class="form-control"
                                        value="{{getValue(item?.product['Colonne1'])}}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.product?.hasOwnProperty('Colonne1') || getValue(item?.product['Colonne1'])==''">
                                        Ajoutez désignation du produit
                                    </mat-error>
                                </div>

                            </div>
                        </div>
                        <div class="d-block text-center card-footer" *ngIf="item?.msg != null && item?.msg.msg!=''">
                            <div style="color: red;"><b>{{item?.msg?.msg}}</b></div>
                        </div>
                    </div>
                </li>
            </ul>

            <div class="position-relative row form-group" *ngIf="products.length > 0">
                <div class="col-sm-10 offset-sm-10">
                    <button type="submit" (click)="addProducts()" class="btn btn-primary">Insertion
                        manifest</button>
                </div>
            </div>
        </form>

    </div>
</div>
