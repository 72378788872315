import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { StoresService } from 'src/app/services/stores/stores.service';
import { UsersTrackingService } from 'src/app/services/users-tracking/users-tracking.service';

@Component({
  selector: 'app-add-tracking-user',
  templateUrl: './add-tracking-user.component.html',
  styleUrls: ['./add-tracking-user.component.css']
})
export class AddTrackingUserComponent implements OnInit {

  profiles = ["POINT_COLLECT", "COURSIER", "DISPATCH"/*, "LOUAGISTE", "COLLECTOR"*/];
  selectedProfiles: any = [];
  isResponsable: boolean = true;
  leadersList: Observable<Array<any>>;
  superieur: string = "";

  arrests: any = []
  lastName: string = ""
  part1: string = ""
  part2: string = ""
  startingLouage: string = ""
  arrivedLouage: string = ""

  storesList: Observable<Array<any>>;
  idStore: String = null;

  imageUrl: string = '';
  fileToUpload: File = null;

  constructor(private trackingServices: UsersTrackingService,
    private storesService: StoresService) { }

  ngOnInit(): void {
    this.listLeaders()
    this.getArrests()
    this.listStores()
  }

  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  // selectProfil(event: any) {
  //   this.selectedProfile = event.target.value;
  // }

  setResponsable(event: any) {
    this.isResponsable = (event.target.value.toLowerCase() == 'true')
  }

  selectSuperieur(event: any) {
    this.superieur = event.target.value;
  }

  selectStore(event: any) {
    this.idStore = event.target.value;
  }

  isProfileIncludes(profile) {
    if (this.selectedProfiles.includes(profile)) {
      return true;
    }
    return false
  }

  async listStores() {
    await this.storesService.getStores().then((res: any) => {
      if (res) {
        this.storesList = res;
      }
    });
  }

  async addUser(name, username, password, confirmPassword, numTel, numTel2) {
    // if (this.selectedProfiles.includes("LOUAGISTE")) {
    //   // console.log("last name : " + this.lastName)
    //   await this.trackingServices.subscribeLouage(name.value, 
    //     this.lastName, username.value,
    //     password.value, true, numTel.value, numTel2.value,
    //     this.startingLouage, this.arrivedLouage, this.part1, this.part2)
    //     .then((res: any) => {
    //       // console.log("resssssssss : ")
    //       // console.log(res)
    //       if (res) {
    //           location.href = '/louagistes';
    //         //this.sacsList = res;
    //       }
    //     });
    // } else {

    if (name.value != "" && username.value.includes('@')
      && username.value != "" && this.imageUrl != "" && password.value != '' && password.value == confirmPassword.value
      && numTel.value != "" && numTel.value.length == 8 && this.selectedProfiles.length > 0
      && ((this.selectedProfiles.includes("DISPATCH") && this.idStore != null) || !this.selectedProfiles.includes("DISPATCH"))
      ) {

      const formData: any = new FormData();

      if (this.fileToUpload != null) {
        formData.append("profile", this.fileToUpload, this.fileToUpload['name']);
      }

      var user = {
        name: name.value,
        username: username.value,
        password: password.value,
        profil: this.selectedProfiles,
        numTel: numTel.value,
        numTel2: numTel2.value,
        pcollect: null,
        // responsable: isResponsable,
        // superieur: superieur
      }
      if(this.selectedProfiles.length > 0 && this.selectedProfiles.includes("DISPATCH") && this.idStore != null) {
        user["store"] = this.idStore
      }

      for (var key in user) {
        formData.append(key, user[key]);
      }

      await this.trackingServices.subscribe(formData)
        .then((res: any) => {
          // console.log("resssssssss : ")
          // console.log(res)
          if (res) {
            if (this.selectedProfiles.includes("COURSIER")) {
              location.href = '/tracking-user';
            } else if (this.selectedProfiles.includes("DISPATCH")) {
              location.href = '/preparers';
            }
            //this.sacsList = res;
          }
        });
    } else {
      alert("Les données de l'utilisateur est invalide");
    }

    // console.log("this.selectedProfiles : ")
    // console.log(this.selectedProfiles)
  }

  async getArrests() {
    await this.trackingServices.getArrest().then((res: any) => {
      if (res) {
        // console.log("leader res");
        // console.log(res);
        this.arrests = res;
      }

    });
  }

  async listLeaders() {
    await this.trackingServices.getResponsables().then((res: any) => {
      if (res) {
        // console.log("leader res");
        // console.log(res);
        this.leadersList = res;
      }

    });
  }

}
