import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import { ClientsService } from 'src/app/services/clients/clients.service';

@Component({
  selector: 'app-import-excel-clients',
  templateUrl: './import-excel-clients.component.html',
  styleUrls: ['./import-excel-clients.component.css']
})
export class ImportExcelClientsComponent implements OnInit {

  @ViewChild('file')
  myfileImport: ElementRef;

  msgError: string = null
  itemsError: any = []
  clients: any = []

  constructor(private clientsService: ClientsService) { }

  ngOnInit(): void {
  }

  docIsValidate(array) {
    // console.log("array")
    // console.log(array)
    if (array.includes("CLIENT")
      && array.includes("VILLE")
      && array.includes('ZONE')
      && array.includes("TYPE DE MAGASINS")
      && array.includes('PRIORITE')
      && array.includes('DELAI DE LIVRAISON')

    ) {
      // this.labelType = "Fedex"
      // this.isFedex = true
      return true
    } else {
      if (!array.includes("CLIENT")) {
        this.itemsError.push("CLIENT")
      }
      if (!array.includes("VILLE")) {
        this.itemsError.push("VILLE")
      }
      if (!array.includes("ZONE")) {
        this.itemsError.push("ZONE")
      }
      if (!array.includes("TYPE DE MAGASINS")) {
        this.itemsError.push("TYPE DE MAGASINS")
      }
      if (!array.includes("PRIORITE")) {
        this.itemsError.push("PRIORITE")
      }
      if (!array.includes("DELAI DE LIVRAISON")) {
        this.itemsError.push("DELAI DE LIVRAISON")
      }
      return false
    }
  }

  getValue(item) {
    // console.log("itemmmmmmm")
    // console.log(item)
    if (item != null && item.hasOwnProperty("result")) {
      return item.result
    } else {
      return item
    }
  }

  deleteCmd(index) {
    this.clients.splice(index, 1)
  }
  addClient(item) {
    let promise = new Promise((resolve, reject) => {
      this.clientsService.subscribe(
        this.getValue(item.client["CLIENT"]),
        "",
        this.getValue(item.client["CLIENT"]),
        this.getValue(item.client["CLIENT"]) + "@gmail.com",
        this.getValue(item.client.ZONE),
        this.getValue(item.client["VILLE"]),
        "",
        null,
        this.getValue(item.client["TYPE DE MAGASINS"]),
        this.getValue(item.client["PRIORITE"]),
        this.getValue(item.client["DELAI DE LIVRAISON"])
    /*cmd.quantite * cmd.prix_unitaire, products*/)
        .then((res: any) => {
          // console.log("resssssssss : ")
          // console.log(res)
          if (res) {
            resolve(res);
          }
        });
    });
    return promise

  }
  readExcel(event) {

    const workbook = new Excel.Workbook();
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }

    /**
     * Final Solution For Importing the Excel FILE
     */
    this.msgError = null
    // this.labelType = ""
    // this.isFedex = false
    // this.isTNT = false
    this.clients = []
    const header = [] //["nameDest", "adressDest", "numTelDest", "destination", "nom_prod", "quantite", "prix_unitaire"]

    const arryBuffer = new Response(target.files[0]).arrayBuffer();
    arryBuffer.then((data) => {
      workbook.xlsx.load(data)
        .then(async () => {

          // play with workbook and worksheet now
          // console.log(workbook);
          const worksheet = workbook.getWorksheet('Données');
          // console.log('rowCount: ', worksheet.getRow(1).values);
          if (worksheet == null) {
            this.msgError = "Impossible d'importer, veuillez vérifier le fichier excel"
            return false
          } else {
            if (!this.docIsValidate(worksheet.getRow(1).values.map(Function.prototype.call, String.prototype.trim))
                /*&& !this.docIsTNT(worksheet.getRow(1).values.map(Function.prototype.call, String.prototype.trim))*/) {
              // console.log("file incorrecte !!!")
              this.msgError = "Impossible d'importer le fichier excel à cause d'un champ manquant"
              return false
            }
          }

          const promises = [];
          // worksheet.
          worksheet.eachRow((row, rowNumber) => {
            // console.log('Row: ' + rowNumber + ' Value: ' + row.values);
            // console.log('Row ' + rowNumber + ' = ' + JSON.stringify(row.values));
            let client = {}
            if (rowNumber == 1) {
              row.eachCell((cell, colNumber) => {
                // console.log('Cell ' + colNumber + ' , ' + header[colNumber - 1] + ' = ' + cell.value);
                header.push(cell.value.toString().trim())
              });
            } else {
              // const promise = db.insert(row); // <-- whatever async operation you have here
              row.eachCell((cell, colNumber) => {
                // console.log('Cell ' + colNumber + ' , ' + header[colNumber - 1] + ' = ' + cell.value);
                client[header[colNumber - 1]] = cell.value
              });
              promises.push({ client: client, msg: null });
              // this.cmds.push(cmd)
              // return cmd
            }
          });
          this.clients = await Promise.all(promises)
          console.log("all cmds : ")
          console.log(this.clients)
        });
    });

    this.myfileImport.nativeElement.value = "";
  }

  async addClients() {
    // if (!this.cmdsIsValidated()) {
    //   alert('Quelques commandes sont invalides');
    //   this.cmds = []
    // } else {
    // let validated = false

    let promises: Promise<any>[] = [];
    this.clients.forEach(item => {
      let promise: Promise<any>
      // if(this.isFedex) {
      promise = this.addClient(item).then((value: any) => {
        // console.log("value")
        // console.log(value)
        return { client: item.client, msg: value };
      });
      // } else if(this.isTNT) {
      //   promise = this.addCommandeTNT(item).then((value: any) => {
      //     return { cmd: item.cmd, msg: value };
      //   });
      // }
      console.log("promiseeeee")
      console.log(promise)


      promises.push(promise);
    });

    Promise.all(promises)
      .then((results) => {
        console.log("promiseeesssssssssssssssssssssss : ")
        console.log(results)
        this.clients = results.filter(item => item.msg != null && item.msg.response == false);
      })

  }

}
