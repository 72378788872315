<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-hourglass icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Demande
                <div class="page-title-subheading">Détail de la demande.
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="tab-content" id="tab-content">
                <div class="main-card mb-12 card">
                    <div class="card-body">
                        <div id="commande" #commande>
                            <div class="row">
                                <div class="col-md-7">
                                    <div class="text space">
                                        <h4><strong>Demande</strong></h4>
                                    </div>
                                </div>
                                <!-- <div class="col-md-5 text-md-right">
                                    <h4><strong>#{{cmd.ref_facture_cmd}}</strong></h4>
                                </div> -->
                            </div>

                            <div class="row">
                                <div class="col-md-7">
                                    <img width="170"
                                        src="{{env.transportorUsersAssetsBaseURL + demande?.user_transportor?.profile_user}}"
                                        alt="">
                                </div>
                                <div class="col-md-5 text-md-right">
                                    <strong>Client:</strong><br />
                                    {{demande.user_transportor?.nom_user + " " +
                                    demande.user_transportor?.prenom_user}}<br />
                                    {{demande.user_transportor?.email_user}}<br />
                                    {{demande.user_transportor?.tel_user}}<br />
                                    <!-- {{demande.destination_cmd}}<br /><br /> -->

                                    <strong>Date de la demande:</strong><br />
                                    {{demande.action_date_demande | date:'d MMM y HH:mm'}}<br />
                                    <div class="badge badge-{{statusDemandes[demande.etat_demande]?.color}}">
                                        {{statusDemandes[demande.etat_demande]?.label}}
                                    </div>
                                </div>
                            </div>

                            <div class="clearfix"></div>



                            <!-- <div class="row mt-4">
                                <div class="col-lg-8">
                                    <div class="section-title">Mode de livraison</div>
                                    <p class="section-lead">
                                        À domicile
                                        <br />→ {{cmd.destination_cmd}}
                                    </p>
                                </div>
                                <div class="col-lg-4 text-right">
                                    <div class="invoice-detail-item">
                                        <div class="invoice-detail-name">Prix des article(s):
                                            {{cmd.total_ca_cmd |
                                            number:'0.3'}} TND</div>
                                        <div class="invoice-detail-name">Frais de livraison:
                                            {{cmd.frais_livraison | number:'0.3'}} TND
                                        </div>
                                        <div class="invoice-detail-value">Montant total:
                                            {{cmd.total_ca_cmd+cmd.frais_livraison |
                                            number:'0.3'}} TND
                                        </div>
                                    </div>

                                </div>
                            </div> -->
                        </div>
                    </div>

                    <div class="d-block text-center card-footer" *ngIf="demande.isPlanified == true">
                        <div class="text-center"><b>Transport planifié le {{demande?.planificationDate | date:'d MMM y HH:mm'}}</b></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="row">
    <div class="col-6">
        <div class="row">
            <div class="col-lg-6">
                <div class="card mb-3 widget-chart">
                    <div class="widget-chart-content">
                        <div class="icon-wrapper rounded-circle">
                            <div class="icon-wrapper-bg bg-primary"></div>
                            <i class="pe-7s-map-marker text-primary"></i>
                        </div>
                        <div class="widget-numbers size-heading">Origine de la demande</div>
                        <div class="widget-subheading">{{demande?.user_location?.label_target}}</div>
                        <div class="widget-heading">{{demande?.user_location?.main_target}}</div>
                        <div class="widget-heading"><b>{{demande?.user_location?.sencondary_target}}</b></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card mb-3 widget-chart">
                    <div class="widget-chart-content">
                        <div class="icon-wrapper rounded-circle">
                            <div class="icon-wrapper-bg bg-danger"></div>
                            <i class="pe-7s-map-2 text-danger"></i>
                        </div>
                        <div class="widget-numbers size-heading">Déstination de la demande</div>
                        <div class="widget-subheading">{{demande?.user_destination?.label_target}}</div>
                        <div class="widget-heading">{{demande?.user_destination?.main_target}}</div>
                        <div class="widget-heading"><b>{{demande?.user_destination?.sencondary_target}}</b></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card mb-3 widget-chart">
                    <div class="widget-chart-content">
                        <div class="icon-wrapper rounded-circle">
                            <div class="icon-wrapper-bg bg-primary"></div>
                            <i class="pe-7s-cash text-primary"></i>
                        </div>
                        <div class="widget-numbers">{{(demande?.price != null) ? demande?.price : '-'}} DT</div>
                        <div class="widget-heading">
                            <h2>{{demande?.type_vehicule}}</h2>
                        </div>
                        <div class="widget-heading">{{demande?.type_service}}</div>
                        <div class="widget-heading">{{demande?.type_products}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-6 card mb-3">
        <div class="row">
            <!-- <div class="col-lg-12"> -->
                <!-- <div class="card mb-3 widget-chart">
                    <div class="widget-chart-content"> -->
                        <!-- <div class="my-google-map"> -->
                            <div #map id="map-canvas"></div>
                            <div class="map-loader-container" *ngIf="!mapLoaded">
                                <div class="map-loader"></div>
                            </div>
                        <!-- </div> -->
                    <!-- </div> -->
                <!-- </div> -->
            <!-- </div> -->
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Propositions chauffeur
                <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button type="button" id="PopoverCustomT-1" class="btn btn-danger btn-sm">Détails <i
                                class="metismenu-icon pe-7s-angle-right"></i></button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <div class="budget-price justify-content-center" *ngIf="demande.driver_responses?.length === 0">
                        <h5 class="text-center">Aucune proposition affecté dans la
                            demande
                        </h5>
                    </div>
                    <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                        *ngIf="demande.driver_responses?.length > 0">
                        <thead>
                            <tr>
                                <th class="text-center">#</th>
                                <th class="text-left">Transportor</th>
                                <th class="text-center">Distance</th>
                                <th class="text-center">Durée</th>
                                <th class="text-center">Etat<br>de laresponse</th>
                                <th class="text-center">Proposition</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let response of demande.driver_responses, let index = index">
                                <td class="text-center text-muted">#{{index+1}}</td>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <img width="40" height="40" class="rounded-circle"
                                                        src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + response?.driver_id?.user_transportor?.profile_user}}"
                                                        alt="">
                                                    <!-- <ngx-avatar class="rounded-circle" name="{{trans.user_transportor.prenom_user}}
                                                    {{trans.user_transportor.nom_user}}">
                                                    </ngx-avatar> -->
                                                </div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading"
                                                    (click)="goToNewTab(response?.driver_id?._id, '/detail-transportor')">
                                                    {{response?.driver_id?.user_transportor?.prenom_user}}
                                                    {{response?.driver_id?.user_transportor?.nom_user}}
                                                </div>
                                                <div class="widget-subheading opacity-7">
                                                    {{response?.driver_id?.user_transportor?.email_user}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">{{response.distance}}
                                </td>
                                <td class="text-center">{{response.duree}}
                                </td>
                                <td class="text-center">{{response.etat_response}}</td>
                                <td class="text-center">
                                    {{response.proposition}} DT</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="row" *ngIf="demande?.acceptedBy != null">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Demande accepté par "<strong>{{demande?.acceptedBy?.driver_id?.user_transportor?.prenom_user + " " + demande?.acceptedBy?.driver_id?.user_transportor?.nom_user}}</strong>"
                <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button type="button" id="PopoverCustomT-1" class="btn btn-danger btn-sm">Détails <i
                                class="metismenu-icon pe-7s-angle-right"></i></button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                        <thead>
                            <tr>
                                <th>Transporteur</th>
                                <th>Etat</th>
                                <th>Proposition
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <img width="40" height="40" class="rounded-circle"
                                                        src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + demande?.acceptedBy?.driver_id?.user_transportor?.profile_user}}"
                                                        alt="">
                                                    <!-- <ngx-avatar class="rounded-circle" name="{{trans.user_transportor.prenom_user}}
                                                    {{trans.user_transportor.nom_user}}">
                                                    </ngx-avatar> -->
                                                </div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading"
                                                    (click)="goToNewTab(demande?.acceptedBy?.driver_id?._id, '/detail-transportor')">
                                                    {{demande?.acceptedBy?.driver_id?.user_transportor?.prenom_user}}
                                                    {{demande?.acceptedBy?.driver_id?.user_transportor?.nom_user}}</div>
                                                <div class="widget-subheading opacity-7">
                                                    {{demande?.acceptedBy?.driver_id?.user_transportor?.email_user}}
                                                </div>
                                                <div class="widget-subheading opacity-7">
                                                    {{demande?.acceptedBy?.driver_id?.user_transportor?.tel_user}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="font-weight-600">
                                    {{demande?.acceptedBy?.etat_response}}
                                </td>
                                <td>{{demande?.acceptedBy?.proposition}} DT</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</div>