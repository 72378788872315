import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommandesService } from 'src/app/services/commandes/commandes.service';
import { SacsService } from 'src/app/services/sacs/sacs.service';

@Component({
  selector: 'app-bon-achat-sac',
  templateUrl: './bon-achat-sac.component.html',
  styleUrls: ['./bon-achat-sac.component.css']
})
export class BonAchatSacComponent implements OnInit {
  
  idSac: string = ""
  idHistoricSac: string = ""
  sac: any = {}
  nowDate = new Date();

  constructor(private route: ActivatedRoute,
    private sacsServices: SacsService,) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(async (params) => {
      if(params.sac) {
        // console.log("ifffff")
        this.idSac = params.sac;
        this._fetchSac();
      } else {
        // console.log("elessseee")
        this.idHistoricSac = params.historicSac
        this._fetchHistoricSac();
      }
    });
  }

  _fetchHistoricSac() {
    this.sacsServices.getOneHistoricSac(this.idHistoricSac).then((d) => {
      if (d) {
        // console.log("dddddddddddddd")
        // console.log(d)
        this.sac = d
      } else {
        alert('Aucune sac valide');
        location.href = '/sacs';
      }
    });
  }

  _fetchSac() {
    // console.log(cid);
    this.sacsServices.getSac(this.idSac).then((d) => {
      if (d) {
        // console.log("dddddddddddddd")
        // console.log(d)
        this.sac = d;
      } else {
        alert('Aucune sac valide');
        location.href = '/sacs';
      }
    });
  }

  generatePDF() {
    var divContents = document.getElementById("commandePrete").innerHTML;
    var a = window.open('', '', 'height=860, width=1600');
    a.document.write('<html><head> ');
    a.document.write(
      '<link rel="stylesheet" type="text/css" href="/assets/css/bootstrap.min.css" />'
    );
    a.document.write(
      '<link rel="stylesheet" type="text/css" href="/assets/css/style.css" />'
    );
    a.document.write(
      '<link rel="stylesheet" type="text/css" href="/assets/css/components.css" />'
    );
    a.document.write(
      '</head><body style="background:#FFFFFF" ><div  class="container invoice">'
    );

    a.document.write(divContents);
    a.document.write('</div></body></html>');
    a.document.close();
    a.print();
  }
}
