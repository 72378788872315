import { Component, OnInit } from '@angular/core';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-add-commande',
  templateUrl: './add-commande.component.html',
  styleUrls: ['./add-commande.component.css']
})
export class AddCommandeComponent implements OnInit {

  typeColis = [
    // "DOC",
    "LV",
    "HV"
  ]
  currencies = [
    "EUR",
    "USD"]
  
    villes = ["Selectionnez une ville",
    "Abengourou",
    "ABOBO",
    "ABOISSO",
    "ADIAKE",
    "ADJAME",
    "ADZOPE",
    "Adzope",
    "AFFERY",
    "AGBOVILLE",
    "Agboville",
    "AKOUPE",
    "ANYAMA",
    "AYAME",
    "AZAGUIE",
    "BASSAM",
    "BINGERVILLE",
    "BONGOUANOU",
    "BONOUA",
    "Bouafle",
    "BOUAKE",
    "COCODY",
    "COMMERCIAL",
    "DABOU",
    "Daloa",
    "Divo",
    "Duikeue",
    "EXPORT",
    "Gagnoa",
    "GRAND BASSAM",
    "GRAND LAHOU",
    "GUIGLO",
    "HERMANKONO",
    "Issa",
    "JACQUEVILLE",
    "KORHOGO",
    "Korogho",
    "KOUMASSI",
    "MAFERE",
    "MAN",
    "MARCORY",
    "MARKETING",
    "Meagui",
    "N'DOUCI",
    "NANO",
    "Odienne",
    "PK26",
    "PLATEAU",
    "PORT BOUET",
    "PROPRE MOYEN",
    "SAN PEDRO",
    "SIKENSI",
    "SINFRA",
    "SOUBRE",
    "Soubre",
    "TIASSALE",
    "Toumodi",
    "TREICHVILLE",
    "Vavoua",
    "Vente directe",
    "YAKASSE",
    "YAMOUSSOUKRO",
    "YOPOUGON"
    ];
  selectedVille: string = '';
  currency: string = null
  type_colis: string = null
  totalCmd: number = 0;
  frais: any = {}

  clientsList: any = []
  idClient: any = null

  constructor(private clientService: ClientsService,
    private cmdService: CommandesService) { }

  ngOnInit(): void {
    this.listClients()
  }

  async listClients() {
    await this.clientService.getClients().then((res: any) => {
      // console.log(res)
      if (res) {
        this.clientsList = res;
        // this.clientsList.splice(0, 0, "Selectionnez le client");
      }
    });
  }

  selectClient(event: any) {
    // console.log(event.target.value)
    if (event.target.value == "Selectionnez le client") {
      this.idClient = null;
    } else {
      this.idClient = event.target.value;
    }
  }

  async addCommande(nameDest, adressDest, numTelDest, nom_prod, 
    quantite, shpmtWeight, totalCmd) {
    if (nameDest.value != "" && adressDest.value != ""
      && numTelDest.value != ""
      && this.selectedVille != '' && this.selectedVille != 'Selectionnez une ville'
      ) {
      await this.cmdService.addCmdClient(this.idClient, "270136810950", nameDest.value,
        adressDest.value, numTelDest.value, this.selectedVille,quantite.value, 
        shpmtWeight.value, nom_prod.value, totalCmd.value,
        this.currency, "IN", "TN", 1000, this.type_colis
        /*this.form.value.products*/)
        .then((res: any) => {
          // console.log("resssssssss : ")
          // console.log(res)
          if (res) {
            alert('Commande est ajoutée avec succès');
            this.cleanData(nameDest, adressDest, numTelDest, nom_prod, 
              quantite, shpmtWeight, totalCmd)
            //location.href = '/sacs';
            //this.sacsList = res;
          }
        });
    } else {
      alert('Commande est invalide');
    }
  }

  cleanData(nameDest, adressDest, numTelDest, nom_prod, 
    quantite, shpmtWeight, totalCmd) {
    nameDest.value = ""
    adressDest.value = ""
    numTelDest.value = ""
    nom_prod.value = ""
    quantite.value = ""
    shpmtWeight.value = ""
    totalCmd.value = ""
    this.selectedVille = ""
    this.type_colis = null
    this.currency = null
    // const add = this.form.get('products') as FormArray;
    // while (add.length !== 0) {
    //   add.removeAt(0)
    // }
    // this.addNewProductGroup()
  }

  selectVille(event: any) {
    this.selectedVille = event.target.value;
  }

}
