<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <!-- <div class="widget-content p-0">
                                <div class="widget-content-wrapper">
                                    <div class="widget-content-left mr-3">
                                        <div class="widget-content-left">
                                            <ngx-avatar class="rounded-circle" name="{{client.prenom_client}}
                                            {{client.nom_client}}">
                                            </ngx-avatar>
                                        </div>
                                    </div>
                                    <div class="widget-content-left flex2">
                                        <div class="widget-heading">{{client.prenom_client}}
                                            {{client.nom_client}}</div>
                                        <div class="widget-subheading opacity-7">{{client.company_name}}</div>
                                    </div>
                                </div>
                            </div> -->

            <div class="page-title-icon">
                <i class="pe-7s-box2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Base clients
                <div class="page-title-subheading">La liste des colis de tous les client.
                </div>
            </div>
        </div>
        <!-- <div class="page-title-actions">
                            <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                                class="btn-shadow mr-3 btn btn-dark">
                                <i class="fa fa-star"></i>
                            </button>
                            <div class="d-inline-block dropdown">
                                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                    class="btn-shadow dropdown-toggle btn btn-info">
                                    <span class="btn-icon-wrapper pr-2 opacity-7">
                                        <i class="fa fa-business-time fa-w-20"></i>
                                    </span>
                                    Buttons
                                </button>
                                <div tabindex="-1" role="menu" aria-hidden="true"
                                    class="dropdown-menu dropdown-menu-right">
                                    <ul class="nav flex-column">
                                        <li class="nav-item">
                                            <a href="javascript:void(0);" class="nav-link">
                                                <i class="nav-link-icon lnr-inbox"></i>
                                                <span>
                                                    Inbox
                                                </span>
                                                <div class="ml-auto badge badge-pill badge-secondary">86</div>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="javascript:void(0);" class="nav-link">
                                                <i class="nav-link-icon lnr-book"></i>
                                                <span>
                                                    Book
                                                </span>
                                                <div class="ml-auto badge badge-pill badge-danger">5</div>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="javascript:void(0);" class="nav-link">
                                                <i class="nav-link-icon lnr-picture"></i>
                                                <span>
                                                    Picture
                                                </span>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a disabled href="javascript:void(0);" class="nav-link disabled">
                                                <i class="nav-link-icon lnr-file-empty"></i>
                                                <span>
                                                    File Disabled
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Filtre
                <div class="btn-actions-pane-right">
                    <!-- <div role="group" class="btn-group-sm btn-group">
                                        <div class="badge badge-warning">{{statusCmds}}</div>
                                    </div> -->
                </div>
            </div>
            <div class="tab-content">
                <div class="tab-pane tabs-animation fade show active" id="tab-content-0" role="tabpanel">
                    <div class="card-body">
                        <form class="" action="" method="post" onsubmit="return false;"
                            (onsubmit)="filterCmds(ref, numero, datein, datefin);">
                            <div class="form-row">
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="ref" class="">Référence</legend>
                                        <input name="ref" id="ref" type="text" class="form-control" #ref ngModel>
                                    </div>
                                </div>
                                <div class="col-md-1">
                                    <div class="position-relative form-group">
                                        <legend for="type" class="">Types colis</legend>
                                        <select class="form-control" [(ngModel)]="selectedTypeColis" name="profile">
                                            <option value="Selectionnez type">
                                                Selectionnez type</option>
                                            <option [value]="type" *ngFor="let type of typeColis">
                                                {{type}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-1">
                                    <div class="position-relative form-group">
                                        <legend for="stat" class="">Status</legend>
                                        <select class="form-control selectric" (change)="selectChangeStatus($event)">
                                            <option value="Selectionnez status">
                                                Selectionnez status</option>
                                            <option [value]="stat.key" *ngFor="let stat of statusCmds | keyvalue">
                                                {{stat.value.label}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="ville" class="">Ville</legend>
                                        <select class="form-control selectric" (change)="selectChangeVille($event)">
                                            <option [value]="ville" *ngFor="let ville of villes">
                                                {{ville}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-1">
                                    <div class="position-relative form-group">
                                        <legend for="numero" class="">Téléphone
                                        </legend>
                                        <input name="numero" id="numero" type="text" class="form-control" #numero
                                            ngModel>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="datein" class="">Date Début</legend>
                                        <input id="datein" type="date" class="form-control" name="datein" tabindex="1"
                                            #datein ngModel>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="position-relative form-group">
                                        <legend for="datefin" class="">Date Fin</legend>
                                        <input id="datefin" type="date" class="form-control" name="datefin" tabindex="1"
                                            #datefin ngModel>
                                    </div>
                                </div>
                                <button type="submit" (click)="filterCmds(ref, numero, datein, datefin)"
                                    class="btn btn-primary btn-lg btn-icon icon-righ btn-search" id="search"
                                    tabindex="4">
                                    <i class="fas fa-search"></i>
                                </button>
                                <!-- <div class="col-md-1">
                                                        <div class="position-relative form-group">
                                                            <legend for="firstName" class="">dd</legend>
                                                            <button class="mb-2 mr-2 btn btn-primary">
                                                                <i class="nav-link-icon pe-7s-search"></i>
                                                            </button>
                                                        </div>
                                                    </div> -->
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Colis
                <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button (click)="exportToExcel(datein, datefin)"
                            class="mr-2 btn-icon btn-icon-only btn btn-outline-success"><i
                                class="pe-7s-download btn-icon-wrapper"> Exporter statistique</i></button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="tab-content">
                    <div class="table-responsive">
                        <div class="budget-price justify-content-center" *ngIf="cmdList?.length === 0">
                            <h2 class="text-center" style="color: red;">Aucun colis créé</h2>
                        </div>
                        <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                            *ngIf="cmdList?.length > 0">
                            <thead>
                                <tr>
                                    <th class="text-center">#</th>
                                    <th class="text-left">Référence</th>
                                    <th class="text-center">Date Création</th>
                                    <th class="text-center">Nom Client<br>&<br>Téléphone</th>
                                    <th class="text-center">Ville</th>
                                    <th class="text-center">Produits<br>
                                        Quantité - Nom produit</th>
                                    <th>Commentaire</th>
                                    <th class="text-center">Status</th>
                                    <th class="text-center">Total<br>Quantité</th>
                                    <th class="text-center">Total<br>Volume</th>
                                    <th class="text-center" data-width="100">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let cmd of cmdList, let index = index">
                                    <td class="text-center tnt">
                                        #<b>{{index+1}}</b>
                                    </td>
                                    <!-- <td>
                                                <div class="widget-content p-0">
                                                    <div class="widget-content-wrapper">
                                                        <div class="widget-content-left mr-3">
                                                            <div class="widget-content-left">
                                                                <ngx-avatar class="rounded-circle" name="{{client.prenom_client}}
                                                                {{client.nom_client}}">
                                                                </ngx-avatar>
                                                            </div>
                                                        </div>
                                                        <div class="widget-content-left flex2">
                                                            <div class="widget-heading">{{client.prenom_client}}
                                                                {{client.nom_client}}</div>
                                                            <div class="widget-subheading opacity-7">{{client.company_name}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td> -->
                                    <!-- <td>
                                        <div class="widget-content p-0">
                                            <div class="widget-content-wrapper">
                                                <div class="widget-content-left mr-3">
                                                    <div class="widget-content-left">
                                                        <ngx-avatar class="rounded-circle" name="{{cmd?.client_cmd?.prenom_client}}
                                                                {{cmd?.client_cmd?.nom_client}}">
                                                        </ngx-avatar>
                                                    </div>
                                                </div>
                                                <div class="widget-content-left flex2">
                                                    <div class="widget-heading">
                                                        {{cmd?.client_cmd?.company_name}}
                                                    </div>
                                                    <div class="widget-subheading opacity-7">
                                                        {{cmd?.client_cmd?.prenom_client}}
                                                        {{cmd?.client_cmd?.nom_client}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </td> -->
                                    <td class="text">
                                        {{cmd?.tra_ref_id}}
                                    </td>
                                    <td class="text-center">
                                        {{cmd?.date_creation_cmd | date:'d MMM y'}}<br>
                                        <b>{{cmd?.date_creation_cmd | date:'HH:mm'}}</b>
                                    </td>
                                    <td class="text-center">
                                        <div class="widget-content p-0">
                                            <div class="widget-content-wrapper">
                                                <div class="widget-content-left flex2">
                                                    <div class="widget-heading">
                                                        {{cmd?.client_cmd?.prenom_client + " " +
                                                        cmd?.client_cmd?.nom_client}}</div>
                                                    <div class="widget-subheading opacity-7">
                                                        {{cmd?.client_cmd?.username_client}}
                                                    </div>
                                                    <div class="widget-subheading opacity-7">
                                                        {{cmd?.client_cmd?.numTel_client}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        {{cmd?.client_cmd?.adresse_client}}<br>
                                        <strong>{{cmd?.client_cmd?.zone_client}}</strong>
                                    </td>
                                    <td>
                                        <div *ngFor="let prd of cmd?.products?.prdts, let index = index">
                                            {{prd?.quantite}} - {{prd?.prd_id?.item_name}}<br>
                                        </div>
                                        <!-- <b>{{cmd?.type_colis}}</b> -->
                                    </td>
                                    <td class="text-center">
                                        <span class="badge badge-pill badge-danger"
                                            (click)="listComments(cmd)">{{cmd?.comments?.length}}</span>
                                    </td>
                                    <td class="text-center">
                                        <div
                                            class="badge badge-{{statusCmds[cmd?.etat_cmd]?.color}}">
                                            {{statusCmds[cmd?.etat_cmd]?.label}}</div>

                                        <div *ngIf="!includes(['validated', 'transporting_depot', 'prepared', 'paied'], cmd.etat_cmd)"
                                            [routerLink]="['/commandes-tracking-user']"
                                            [queryParams]="{ idUser: cmd?.transport_affect[cmd?.transport_affect.length-1]?.transporter_id?._id }"
                                            style="color: red; cursor: pointer;">
                                            <strong>{{cmd?.transport_affect[cmd?.transport_affect.length-1]?.transporter_id?.nom_prenom_tracking}}</strong>
                                        </div>

                                        <!-- <div *ngIf="cmd.etat_cmd == 'transporting_depot'"
                                            class="badge badge-{{statusCmds[cmd.etat_cmd].color}}">
                                            {{cmd?.store?.store_name}}</div> -->
                                    </td>
                                    <td class="text-center">
                                        <span class="badge badge-pill badge-danger">
                                            {{cmd?.products?.total_qt}}
                                        </span>
                                    </td>
                                    <td class="text-center">
                                        <span class="badge badge-pill badge-danger">
                                            {{cmd?.products?.total_vm | number:'0.2'}} m³
                                        </span>
                                    </td>

                                    <td class="text-center">
                                        <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                            <button class="btn-wide btn btn-info">Actions</button>
                                            <button type="button" ngbDropdownToggle
                                                class="dropdown-toggle-split btn btn-info"><span
                                                    class="sr-only">Actions</span></button>
                                            <div ngbDropdownMenu>
                                                <button type="button" (click)="goToNewTab(cmd._id, '/detail-cmd')"
                                                    tabindex="0" class="dropdown-item">
                                                    <i class="metismenu-icon pe-7s-news-paper"
                                                        style="margin-right: 10px;"></i>Détails
                                                </button>
                                                <button type="button" tabindex="0"
                                                    (click)="goToNewTab(cmd._id, '/facture-cmd')"
                                                    class="dropdown-item"><i class="metismenu-icon pe-7s-print"
                                                        style="margin-right: 10px;"></i>Facture</button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="d-block text-center card-footer" *ngIf="cmdList?.length > 0 && pages?.length > 1">
                        <nav aria-label="Page navigation example">
                            <ul class="pagination justify-content-center">
                                <li [ngClass]="(currentPage===0)?'page-item disabled':'page-item'">
                                    <a class="page-link" tabindex="-1"
                                        (click)="previousPage(ref, numero, datein, datefin)">
                                        <i class="nav-link-icon pe-7s-angle-left-circle"></i>
                                        Précédent</a>
                                </li>

                                <div *ngFor="let p of pagesDisplaied">
                                    <li [ngClass]="(currentPage===p)?'page-item active':'page-item'">
                                        <a *ngIf="currentPage!==p" class="page-link"
                                            (click)="selectPage(p, ref, numero, datein, datefin)">{{p+1}}</a>

                                        <span *ngIf="currentPage===p" class="page-link">
                                            {{p+1}}
                                            <span class="sr-only">(current)</span>
                                        </span>
                                    </li>
                                </div>
                                <!-- <li class="page-item active">
                                                    <span class="page-link">
                                                        2
                                                        <span class="sr-only">(current)</span>
                                                    </span>
                                                </li>
                                                <li class="page-item"><a class="page-link" href="#">3</a></li> -->

                                <li [ngClass]="(currentPage===totalPages-1)?'page-item disabled':'page-item'">
                                    <a class="page-link" tabindex="-1"
                                        (click)="nextPage(ref, numero, datein, datefin)">Suivant <i
                                            class="nav-link-icon pe-7s-angle-right-circle"></i></a>
                                </li>
                            </ul>
                        </nav>

                        <!-- <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                                                class="pe-7s-trash btn-icon-wrapper"> </i></button>
                                        <button class="btn-wide btn btn-success">Save</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>