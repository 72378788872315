<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-car icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Recherche
                <div class="page-title-subheading"><strong>Recherche par :</strong> Nom de destinataire
                    / E-mail / Numéro de téléphone / Adresse du client / Destination des colis.
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <div class="d-inline-block dropdown">
                <div class="search-wrapper active">
                    <div class="input-holder">
                        <!-- <form class="needs-validation" action="" method="post" onsubmit="return false;"
                            (onsubmit)="listCmdsClient(keyword);"> -->
                        <input type="text" id="keyword" name="keyword" class="search-input"
                            placeholder="Type to search" #keyword ngModel>

                        <button type="button" (click)="listCmdsClient(keyword)"
                            class="search-icon"><span></span></button>
                        <!-- </form> -->
                    </div>
                    <!-- <button class="close"></button> -->
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Colis
                <div class="btn-actions-pane-right">
                    <!-- <div role="group" class="btn-group-sm btn-group">
                        <div class="badge badge-warning">{{statusCmds}}</div>
                    </div> -->
                </div>
            </div>
            <div class="table-responsive">
                <div class="budget-price justify-content-center" *ngIf="listCmds?.length === 0">
                    <h2 class="text-center" style="color: red;">Aucun colis {{statusCmds}} </h2>
                </div>
                <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                    *ngIf="listCmds?.length > 0">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th class="text-left">Référence</th>
                            <th>Master</th>
                            <th class="text-center">Date Création</th>
                            <th class="text-center">Nom Client & Téléphone</th>
                            <th class="text-center">Ville</th>
                            <th class="text-center">Produits<br>
                                Quantité - Nom produit</th>
                            <th class="text-center">Status</th>
                            <th class="text-center">Total<br>Colis</th>
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let cmd of listCmds, let index = index">
                            <td class="text-center" [class]="(cmd?.type_cmd == 'FEDEX') ? 'fedex' : 'tnt'">#<b>{{index+1}}</b></td>
                            <td class="text">{{cmd?.qr_code}}<br><b [class]="(cmd?.type_cmd == 'FEDEX') ? 'text-fedex' : 'text-tnt'">{{cmd?.type_cmd}}</b></td>
                                    <td class="text-center">{{cmd?.parent}}<br><b>{{cmd?.pkg_count}}</b></td>
                                    <td class="text-center">{{cmd.date_creation_cmd | date:'d MMM y'}}<br>
                                <b>{{cmd.date_creation_cmd | date:'HH:mm'}}</b>
                            </td>
                            <td class="text-center">
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading">
                                                {{cmd.user_destination.nom_destination}}</div>
                                            <div class="widget-subheading opacity-7">
                                                {{cmd.user_destination.numtel_destination}}</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center">{{cmd.user_destination.adresse_destination}}<br>
                                <strong>{{cmd.destination_cmd}}</strong>
                            </td>
                            <td>
                                {{cmd?.pkg_count}} - {{cmd?.commodity_desc}}
                            </td>
                            <td class="text-center">
                                <!-- <div class="badge badge-{{statusCmds[cmd.etat_cmd].color}}">
                                    {{statusCmds[cmd.etat_cmd].label}}</div> -->

                                    <div *ngIf="cmd.etat_cmd != 'transporting_depot'" class="badge badge-{{statusCmds[cmd.etat_cmd].color}}">
                                        {{statusCmds[cmd?.etat_cmd]?.label}}</div>
                                    <div *ngIf="cmd.etat_cmd == 'transporting_depot'" class="badge badge-{{statusCmds[cmd.etat_cmd].color}}">
                                        {{cmd?.store?.store_name}}</div>
                            </td>
                            <td class="text-center">{{cmd?.total_ca_cmd |
                                number:'0.2'}} {{cmd?.currency}}</td>
                            <td class="text-center">
                                <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                    <button class="btn-wide btn btn-info">Actions</button>
                                    <button type="button" ngbDropdownToggle
                                        class="dropdown-toggle-split dropdown-toggle btn btn-info"><span
                                            class="sr-only">Actions</span></button>
                                    <div ngbDropdownMenu>
                                        <button type="button"
                                            (click)="goToNewTab(cmd._id, '/detail-cmd')" tabindex="0"
                                            class="dropdown-item">
                                            <i class="metismenu-icon pe-7s-news-paper"
                                                style="margin-right: 10px;"></i>Détails
                                        </button>
                                        <button type="button" tabindex="0"
                                            (click)="goToNewTab(cmd._id, '/facture-cmd')"
                                            class="dropdown-item"><i class="metismenu-icon pe-7s-print"
                                                style="margin-right: 10px;"></i>Facture</button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="d-block text-center card-footer" *ngIf="listCmds?.length > 0">
                <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                        class="pe-7s-trash btn-icon-wrapper"> </i></button>
                <button class="btn-wide btn btn-success">Save</button>
            </div> -->
        </div>
    </div>
</div>