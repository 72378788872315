// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// var baseURLAPI = 'http://localhost:3004/';
var baseURLAPI = 'https://nano.ewassali.tn/api/v0/';

var assetsBaseURL = "https://assets.marketbey.tn/Nano/";

export const environment = {
  production: false,

  apiUpload: baseURLAPI + 'sacs/upload-video',

  // API COMMERCIAL TRANSPORTORS
  apiAddCommercial: baseURLAPI + 'transportor/add-commercial',
  apiAllCommercials: baseURLAPI + 'transportor/all-commercials',

  // API SOCIETY TRANSPORTORS
  apiAddSociety: baseURLAPI + 'transportor/add-society',
  apiAllSocieties: baseURLAPI + 'transportor/all-societies',

  // API TRANSPORTORS
  apiAllTansportors: baseURLAPI + 'transportor/driver-transportors',
  apiOneTansportor: baseURLAPI + 'transportor/details-transportor',
  apiValidateTansportor: baseURLAPI + 'transportor/validate-transportor',
  apiMarkersTansportors: baseURLAPI + 'transportor/get-markers-driver',
  apiStatusDemandes: baseURLAPI + 'transportor/liststatus',
  apiAllDemandes: baseURLAPI + 'transportor/demandes',
  apiOneDemande: baseURLAPI + 'transportor/get-demande',
  apiTypeCamion: baseURLAPI + 'transportor/type-camions',
  apiAllTansportorsByVehicule: baseURLAPI + 'transportor/parc-transportors',
  apiBlockedTansportor: baseURLAPI + 'transportor/block-transportor',
  apiDeblockedTansportor: baseURLAPI + 'transportor/deblock-transportor',
  apiAllVehicles: baseURLAPI + 'transportor/all-vehicles',

  // API RETURN TRANSPORTORS
  apiAllReturnByStatus: baseURLAPI + 'transportor/empty-return-by-status',
  apiUpdateEmptyReturn: baseURLAPI + 'transportor/update-empty-return',

  // API STORES
  apiAddStore: baseURLAPI + 'stores/add-store',
  apiAllDetailStores: baseURLAPI + 'stores/details-stores',
  apiAllDisplayStores: baseURLAPI + 'stores/display-stores',
  apiAllStores: baseURLAPI + 'stores/stores',
  apiAllStore: baseURLAPI + 'stores/store',

  // API SACS
  apiAddSac: baseURLAPI + 'sacs/add-sac',
  apiAllSacs: baseURLAPI + 'sacs/list-sacs',
  apiAllSacsByVille: baseURLAPI + 'sacs/sacs-ville',
  apiAllCons: baseURLAPI + 'sacs/list-cons',
  apiAllVille: baseURLAPI + 'sacs/all-cities',
  apiOneSac: baseURLAPI + 'sacs/sac',
  apiHistoricSac: baseURLAPI + 'sacs/historic-cmds-sac',
  apiGetHistoricSac: baseURLAPI + 'sacs/get-history-sac',
  apiEmptySac: baseURLAPI + 'sacs/empty-sac',
  apiGetSacsFromLouagiste: baseURLAPI + 'sacs/sacs-louagiste',
  apiAffectSacToLouagiste: baseURLAPI + 'sacs/affect-sac',

  // API USERS TRACKING
  apiGetOneUserTracking: baseURLAPI + 'tracking/get-users-tracking',
  apiAddUsersTracking: baseURLAPI + 'tracking/subscribe',
  apiAddUsersLouage: baseURLAPI + 'louage/subscribe',
  apiGetUsersTracking: baseURLAPI + 'tracking/user-responsable',
  apiGetCollector: baseURLAPI + 'tracking/list-collectors',
  apiGetPreparers: baseURLAPI + 'tracking/preparers',
  apiAffectPrepaToStore: baseURLAPI + 'tracking/affect-prepa-store',
  apiGetLouagistes: baseURLAPI + 'louage/louagistes',
  apiGetLouagistesByCity: baseURLAPI + 'louage/louagistes-city',
  apiGetOneLouagiste: baseURLAPI + 'louage/one-louagiste',
  apiCmdsLivredByTransporter: baseURLAPI + 'tracking/cmds-livred',
  apiCmdsReturnedByTransporter: baseURLAPI + 'tracking/cmds-returned',
  apiCmdsTrackingNotPaiedAndNotReturnedAndInProgress: baseURLAPI + 'tracking/details-cmds-tracking',
  apiTrakingNotAffectedToTurned: baseURLAPI + 'tracking/transportor-not-affected-turned',

  // API CLIENTS
  apiAddClient: baseURLAPI + 'client/add-client',
  apiAddFraisClient: baseURLAPI + 'client/add-frais-client',
  apiGetFraisClient: baseURLAPI + 'client/frais-client',
  apiAllClients: baseURLAPI + 'client/list-clients',
  apiOneClient: baseURLAPI + 'client/one-client',
  apiOneAccountClient: baseURLAPI + 'client/account-client',
  apiSearchClient: baseURLAPI + 'client/search-client',
  apiAllCmdsByStatus: baseURLAPI + 'cmds/cmds-all-status',
  apiAccountsClient: baseURLAPI + "client/accounts-client",
  apiAddComment: baseURLAPI + 'cmds/add-comment',

  apiAffectCollector: baseURLAPI + 'client/affect-pickup-collector',

  apiCmdsNotPaied: baseURLAPI + "client/cmds-not-paied",
  apiCmdsNotReturned: baseURLAPI + "client/cmds-not-returned",
  apiCmdsNotPaiedAndNotReturned: baseURLAPI + "client/details-cmds-client",

  apiAddAccountClient: baseURLAPI + "client/add-account-client",

  apiDemandesClient: baseURLAPI + "client/demandes-client",

  // API PRD   
  apiAddPrd: baseURLAPI + "cmds/add-prd",
  apiListPrds: baseURLAPI + "cmds/list-prds",

  // API CMDS
  apiAddCmd: baseURLAPI + 'cmds/add-cmd',
  apiAllCmds: baseURLAPI + 'cmds/list-cmds',
  apiStatisticsFluxCmds: baseURLAPI + 'cmds/statistics-flux-cmds',
  apiAllCmdsByClient: baseURLAPI + 'cmds/list-cmds-client',
  apiStatsCmds: baseURLAPI + 'cmds/statistics-cmds',
  apiOneCmd: baseURLAPI + 'cmds/cmd',
  apiAllCmdsByVille: baseURLAPI + 'cmds/cmds-ville',//////
  apiStatus: baseURLAPI + 'cmds/liststatus',
  apiSearchCmd: baseURLAPI + 'cmds/search',
  apiUpdateCmdStatus: baseURLAPI + 'cmds/updatecmdstatus',
  apiEnterInStore: baseURLAPI + 'cmds/enter-in-store',
  apiAllCmdsByStatusAndUser: baseURLAPI + 'cmds/cmds-status-transport',
  apiAllDeliveryForecastsCmds: baseURLAPI + 'cmds/delivery-forecasts',
  apiAllCmdsByStatusAndClient: baseURLAPI + 'cmds/cmds-status-client',
  apiAllCmdsInZone: baseURLAPI + 'cmds/cmds-in-zone',
  apiFilterCmds: baseURLAPI + 'cmds/filter-cmds',
  apiAllComments: baseURLAPI + 'cmds/all-comments',

  apiPaiedCmdsByTransporter: baseURLAPI + 'cmds/paied-cmds-transporter',
  apiValidateReturnedCmds: baseURLAPI + 'cmds/validate-return-cmds',

  // user travelers
  apiAllTravelers: baseURLAPI + 'traveler-user/travelers',
  apiAllTrips: baseURLAPI + 'louage/all_trips',
  apiOneTrip: baseURLAPI + 'louage/info_trip',
  apiStatsTransportor: baseURLAPI + 'tracking/statistics-transportor',

  // arrest
  apiGetArrest: baseURLAPI + 'traveler/arrests',

  // AUTH Teams WASSALI
  apiUsersAuth: baseURLAPI + "teams-ws/auth",
  apiAllTeams: baseURLAPI + "teams-ws/all-teams",
  apiAddUserPH: baseURLAPI + "teams-ws/add-user-fedex",
  
  // URL ASSETS IMAGE
  transportorUsersAssetsBaseURL: assetsBaseURL + "coursiers/",
  
  driverTransportorAssetsBaseURL: assetsBaseURL + "TRANSPORTOR/driver-trans/driver-licence/",
  cardTransportorAssetsBaseURL: assetsBaseURL + "TRANSPORTOR/driver-trans/driver-card/",
  vehiculeTransportorAssetsBaseURL: assetsBaseURL + "TRANSPORTOR/driver-trans/driver-vehicule/",
  patentTransportorAssetsBaseURL: assetsBaseURL + "TRANSPORTOR/driver-trans/driver-patent/",
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
