<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-user icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Clients
                <div class="page-title-subheading">Ajouter un nouveau client.
                </div>
            </div>
        </div>
        <!-- <div class="page-title-actions">
            <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                class="btn-shadow mr-3 btn btn-dark">
                <i class="fa fa-star"></i>
            </button>
            <div class="d-inline-block dropdown">
                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    class="btn-shadow dropdown-toggle btn btn-info">
                    <span class="btn-icon-wrapper pr-2 opacity-7">
                        <i class="fa fa-business-time fa-w-20"></i>
                    </span>
                    Buttons
                </button>
                <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu dropdown-menu-right">
                    <ul class="nav flex-column">
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-inbox"></i>
                                <span>
                                    Inbox
                                </span>
                                <div class="ml-auto badge badge-pill badge-secondary">86</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-book"></i>
                                <span>
                                    Book
                                </span>
                                <div class="ml-auto badge badge-pill badge-danger">5</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-picture"></i>
                                <span>
                                    Picture
                                </span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a disabled href="javascript:void(0);" class="nav-link disabled">
                                <i class="nav-link-icon lnr-file-empty"></i>
                                <span>
                                    File Disabled
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div> -->
    </div>
</div>
<div class="tab-content">
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="tab-content">
                    <div class="tab-pane tabs-animation fade show active">
                        <div class="card-body">
                            <form class="" action="" method="post" onsubmit="return false;"
                                (onsubmit)="addClient(firstName, lastName, companyName, username, zone, adress, numTel, store, priority, deliveryTime);">
                                <div class="form-row">
                                    <div class="col-md-6">
                                        <div class="position-relative form-group">
                                            <legend for="firstName" class="">Prénom</legend>
                                            <input name="firstName" id="firstName" type="text" class="form-control"
                                                #firstName ngModel>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="position-relative form-group">
                                            <legend for="lastName" class="">Nom</legend>
                                            <input name="lastName" id="lastName" type="text" class="form-control"
                                                #lastName ngModel>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-6">
                                        <div class="position-relative form-group">
                                            <legend for="companyName" class="">Nom de la sociétè</legend>
                                            <input name="companyName" id="companyName" type="text" class="form-control"
                                                #companyName ngModel>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="position-relative form-group">
                                            <legend for="username" class="">Email</legend>
                                            <input name="username" id="username" type="text" class="form-control"
                                                #username ngModel>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="form-row">
                                    <div class="col-md-6">
                                        <div class="position-relative form-group">
                                            <legend for="password" class="">Password</legend>
                                            <input name="password" id="password" type="password" class="form-control"
                                                #password ngModel>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="position-relative form-group">
                                            <legend for="confirmPassword" class="">Confirmer password</legend>
                                            <input name="confirmPassword" id="confirmPassword" type="password"
                                                class="form-control" #confirmPassword ngModel>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="form-row">
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="adress" class="">Adresse</legend>
                                            <input name="adress" id="adress" type="text" class="form-control" #adress
                                                ngModel>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="zone" class="">Zone</legend>
                                            <input name="zone" id="zone" type="text" class="form-control" #zone
                                                ngModel>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="numTel" class="">Numéro Tél</legend>
                                            <input name="numTel" id="numTel" type="number" class="form-control" #numTel
                                                ngModel>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="store" class="">TYPE DE MAGASINS</legend>
                                            <input name="store" id="store" type="text" class="form-control" #store
                                                ngModel>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="priority" class="">PRIORITE</legend>
                                            <input name="priority" id="priority" type="number" class="form-control" #priority
                                                ngModel>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="deliveryTime" class="">DELAI DE LIVRAISON</legend>
                                            <input name="deliveryTime" id="deliveryTime" type="number" class="form-control" #deliveryTime
                                                ngModel>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div class="form-row">
                                    <legend for="exampleText" class="col-3 col-form-label">Genre</legend>
                                    <div class="col-1">
                                        <div class="position-relative form-check">
                                            <label class="form-check-label">
                                                <input name="genre" type="radio" checked [value]="true"
                                                    class="form-check-input" (change)="setGenre($event)"> M.
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-1">
                                        <div class="position-relative form-check">
                                            <label class="form-check-label">
                                                <input name="genre" type="radio" [value]="false"
                                                    class="form-check-input" (change)="setGenre($event)"> Mme
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="position-relative row form-group">
                                <legend for="exampleSelect" class="col-sm-2 col-form-label">Profiles</legend>
                                <div class="col-sm-10">
                                    <select class="form-control" (change)="selectProfil($event)">
                                        <option [value]="profile" *ngFor="let profile of profiles" #profile ngModel>
                                            {{profile}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="position-relative row form-group">
                                <legend for="exampleText" class="col-sm-4 col-form-label">Responsable</legend>
                                <div class="col-sm-3">
                                    <div class="position-relative form-check">
                                        <label class="form-check-label">
                                            <input name="responsable" type="radio" checked [value]="true"
                                                class="form-check-input" (change)="setResponsable($event)"> Oui
                                        </label>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="position-relative form-check">
                                        <label class="form-check-label">
                                            <input name="responsable" type="radio" [value]="false"
                                                class="form-check-input" (change)="setResponsable($event)"> Non
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="position-relative row form-group" *ngIf="isResponsable==false">
                                <legend for="exampleSelect" class="col-sm-2 col-form-label">Supérieur</legend>
                                <div class="col-sm-10">
                                    <select class="form-control" (change)="selectSuperieur($event)">
                                        <option>Selectionnez le supérieur</option>
                                        <option value="{{superieur.element._id}}" *ngFor="let superieur of leadersList" #superieur ngModel>
                                            {{superieur.element.nom_prenom_tracking}}</option>
                                    </select>
                                </div>
                            </div> -->

                                <div class="position-relative row form-check">
                                    <div class="col-sm-10 offset-sm-10">
                                        <button type="submit"
                                            (click)="addClient(firstName, lastName, companyName, username, zone, adress, numTel, store, priority, deliveryTime)"
                                            class="btn btn-primary">Ajouter Client</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>