<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-box2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Ajouter tourné
                <div class="page-title-subheading">Ajouter un nouveau tourné.
                </div>
            </div>
        </div>
        <!-- <div class="page-title-actions">
            <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                class="btn-shadow mr-3 btn btn-dark">
                <i class="fa fa-star"></i>
            </button>
            <div class="d-inline-block dropdown">
                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    class="btn-shadow dropdown-toggle btn btn-info">
                    <span class="btn-icon-wrapper pr-2 opacity-7">
                        <i class="fa fa-business-time fa-w-20"></i>
                    </span>
                    Buttons
                </button>
                <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu dropdown-menu-right">
                    <ul class="nav flex-column">
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-inbox"></i>
                                <span>
                                    Inbox
                                </span>
                                <div class="ml-auto badge badge-pill badge-secondary">86</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-book"></i>
                                <span>
                                    Book
                                </span>
                                <div class="ml-auto badge badge-pill badge-danger">5</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-picture"></i>
                                <span>
                                    Picture
                                </span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a disabled href="javascript:void(0);" class="nav-link disabled">
                                <i class="nav-link-icon lnr-file-empty"></i>
                                <span>
                                    File Disabled
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div> -->
    </div>
</div>

<div class="tab-content">
    <div class="row">
        <div class="col-12">
            <div class="main-card mb-12 card">
                <div class="card-body">
                    <form class="" action="" method="post" onsubmit="return false;" (onsubmit)="addSac();">
                        <div class="row form-group">
                            <legend for="exampleSelect" class="col-sm-2 col-form-label">Coursier </legend>
                            <div class="col-sm-10">
                                <select name="destination" id="destination" class="form-control" [(ngModel)]="transportor">
                                    <option [value]="null">Selectionner un transporteur</option>
                                    <option *ngFor="let user of leadersList" [value]="user._id">
                                        {{user.nom_prenom_tracking}}
                                    </option>
                                </select>
                                <mat-error style="color: red;font-weight: bold;"
                                    *ngIf="transportor == null || transportor == 'null'">
                                    Il faut sélectionner le transporteur de tournée !!!
                                </mat-error>
                            </div>
                        </div>
        
                        <div [formGroup]="form">
                            <div class="row form-group"
                                *ngFor="let productGroup of form.get('destinations')['controls'];let index = index"
                                [formGroup]="productGroup">
        
                                <legend for="exampleSelect" class="col-sm-2 col-form-label">Destination {{index+1}}</legend>
                                <!-- <div class="col-sm-5">
                                    <input type="text" class="form-control" placeholder="Nom du produit"
                                        formControlName="nom_prod">
                                </div>
        
                                <div class="col-sm-3">
                                    <input type="number" class="form-control" placeholder="Quantité"
                                        formControlName="quantite">
                                </div> -->
        
                                <div class="col-sm-5">
                                    <select name="destination" id="destination" class="form-control"
                                        formControlName="destination" placeholder="Prix unitaire">
                                        <option *ngFor="let destination of villes" [ngValue]="destination" ngDefaultControl>
                                            {{destination}}
                                        </option>
                                    </select>
                                    <!-- <input type="number" class="form-control"
                                        placeholder="Prix unitaire" formControlName="prix_unitaire"> -->
                                </div>
        
                                <div class="col-sm-1">
                                    <button type="button" (click)="deleteProductGroup(i)"
                                        class="mr-2 btn-icon btn-icon-only btn btn-danger"><i
                                            class="pe-7s-trash btn-icon-wrapper"> </i></button>
                                </div>
        
                            </div>
                        </div>
                        <div class="row">
                            <mat-error style="color: red;font-weight: bold;"
                                *ngIf="form.value.destinations.length == 0 || (form.value.destinations.length > 0 && !destinationsNotEmpty())">
                                Les destinations sont invalides
                            </mat-error><br>
                        </div>
                        <div class="position-relative row form-group">
                            <button type="button" (click)="addNewDestinationGroup()" class="mt-1 btn btn-warning"><i
                                    class="pe-7s-plus"></i> Ajouter une
                                destination</button>
                        </div>
        
                        <div class="form-group">
                            <!-- <div class="col-sm-10 offset-sm-10"> -->
                                <button type="submit" (click)="addSac()" class="btn btn-primary btn-lg btn-block">Ajouter
                                    tourné</button>
                            <!-- </div> -->
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

