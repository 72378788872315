import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import jwt_decode from "jwt-decode";
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {

  private currentUserSubject: BehaviorSubject<any>;
  public currentUserNano: Observable<any>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUserNano')));
    this.currentUserNano = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  isConnected() {
    var token = localStorage.getItem('token-nano')
    // console.log("token : " + token)
    if (token)
      return true;

    return false
  }

  saveUserCreds(usercreds) {
    // localStorage.setItem('token-ws', JSON.stringify(usercreds.token));
    localStorage.setItem('token-nano', JSON.stringify(usercreds.token));
    localStorage.setItem('currentUserNano', JSON.stringify(usercreds));
  }

  login(creds) {
    //console.log("creds");
    //console.log(creds);
    return new Promise((solve) => {
      this.http.post(environment.apiUsersAuth, creds).subscribe((res: any) => {
        console.log("hajerrrrrrrrrrrrrrrrrrrrrr");
        console.log(res);
        if (res) {
          if (res.response && res.data) {
            this.saveUserCreds(res.data);
            this.currentUserSubject.next(res.data);
            solve(true);
          } else {
            solve(false);
          }
        }
      })
    });
  }

  deconnexion() {
    localStorage.removeItem('token-nano');
    localStorage.removeItem('currentUserNano');
    this.currentUserSubject.next(null);
  }

  allTeams() {
    return new Promise((solve) => {
      this.http.post(environment.apiAllTeams, {}).subscribe((res: any) => {
        //console.log("hajerrrrrrrrrrrrrrrrrrrrrr");
        //console.log(res);
        if (res) {
          if (res.response && res.data) {
            solve(res.data);
          } else {
            solve(res);
          }
        }
      })
    });
  }

  addUserFX(creds) {
    return new Promise((solve) => {
      this.http.post(environment.apiAddUserPH, creds).subscribe((res: any) => {
        //console.log("hajerrrrrrrrrrrrrrrrrrrrrr");
        //console.log(res);
        if (res) {
          // if (res.response) {
          //   solve(res.response);
          // } else {
            solve(res);
          // }
        }
      })
    });
  }
}
