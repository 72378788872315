import { Component, OnInit } from '@angular/core';
import { TransportorsService } from 'src/app/services/transportors/transportors.service';

@Component({
  selector: 'app-list-transportors',
  templateUrl: './list-transportors.component.html',
  styleUrls: ['./list-transportors.component.css']
})
export class ListTransportorsComponent implements OnInit {
  
  allTrans: any = [];
  allTransValid: any = [];
  allTransNotValid: any = [];
  nbrAllTrans: number = 0
  nbrTransHorsLine: number = 0
  tagTransportor: string = "Tous les Coursiers"

  constructor(private transServices: TransportorsService,) { }

  ngOnInit(): void {
    this.listSacs()
  }

  async listSacs() {
    this.tagTransportor = "Tous les Coursiers"
    await this.transServices.getTransportors().then((res: any) => {
      if (res) {
        // console.log("ressssssssssssss")
        // console.log(res)
        this.allTrans = res;
        this.nbrAllTrans = this.allTrans.length
        this.allTransValid = this.filterTransportor(true)
        this.nbrTransHorsLine = this.getTransHorsLine(this.allTransValid)
        this.allTransNotValid = this.filterTransportor(false)
      }
    });
  }

  getTransHorsLine(arrayTrans) {
    var array= arrayTrans.filter(trans => trans.onLine == false);
    return array.length
  }

  filterTransportor(isValid) {
    return this.allTrans.filter(trans => trans.isValidate == isValid);
  }

  isValidTransportor() {
    this.tagTransportor = "Transporteur validé"
    this.allTrans = this.allTransValid
  }

  isNotValidTransportor() {
    this.tagTransportor = "Transporteur non validé"
    this.allTrans = this.allTransNotValid
  }

  onLineTrans() {
    this.allTrans = this.allTransValid.filter(trans => trans.onLine == true);
  }

  offLineTrans() {
    this.allTrans = this.allTransValid.filter(trans => trans.onLine == false);
  }

}
