import { Component, OnInit } from '@angular/core';
import { TransportorsService } from 'src/app/services/transportors/transportors.service';

@Component({
  selector: 'app-list-vehicles',
  templateUrl: './list-vehicles.component.html',
  styleUrls: ['./list-vehicles.component.css']
})
export class ListVehiclesComponent implements OnInit {

  allVehs: any = [];

  constructor(private transServices: TransportorsService,) { }

  ngOnInit(): void {
    this.listVehicles()
  }

  async listVehicles() {
    await this.transServices.getVehicles().then((res: any) => {
      // console.log(res)
      if (res) {
        this.allVehs = res;
      }
    });
  }

}
