<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-next-2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Etat import
                <div class="page-title-subheading">Ajouter état import via Excel.
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="main-card mb-3 card">
    <div class="card-header-tab card-header">
        <div class="card-header-title">
            <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
            L'expéditeur
        </div>
    </div>
    <div class="card-body">
        <div class="position-relative row form-group">
            <div class="col-sm-1"></div>
            <div class="col-sm-5">
                <input name="nameCompany" id="nameCompany" type="text" disabled class="form-control"
                    [value]="client?.prenom_client">
            </div>

            <div class="col-sm-5">
                <input name="nameExp" id="nameExp" type="text" disabled class="form-control"
                    [value]="client?.nom_client">
            </div>
        </div>
    </div>
</div> -->

<div class="main-card mb-12 card">
    <div class="card-header-tab card-header">
        <div class="card-header-title">
            <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
            Etat import - NANO
        </div>
        <div class="btn-actions-pane-right">
            <input hidden type="file" (change)="readExcel($event)" multiple="false" #file>
            <button type="button" (click)="file.click()" data-toggle="tooltip" title="Example Tooltip"
                data-placement="bottom" class="btn-shadow mr-3 btn btn-info">
                <i class="fa fa-star"> Importer fichier excel</i>
            </button>
        </div>
    </div>
    <div class="card-body">
        <div class="budget-price justify-content-center" *ngIf="msgError != null">
            <h2 class="text-center" style="color: red;">{{msgError}}<br>{{itemsError}}</h2>
        </div>
        <form class="" action="" method="post" onsubmit="return false;" (onsubmit)="addCommandes();">

            <ul class="list-group">
                <li class="list-group-item" *ngFor="let item of cmds, let index = index">
                    <div class="main-card mb-12 card">
                        <div class="card-header-tab card-header">

                            <div class="card-header-title">
                                <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
                                Colis {{index+1}}
                            </div>
                            <div class="btn-actions-pane-right">
                                <button type="button" (click)="deleteCmd(index)" class="btn-shadow mr-3 btn btn-danger">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>

                        </div>
                        <div class="card-body">
                            <div class="position-relative row form-group">
                                <!-- <div class="row"> -->
                                <div class="col-sm-3">
                                    <legend for="qrCode" class="space-bottom">tra_ref_id
                                    </legend>
                                    <input name="qrCode" id="qrCode" type="text" disabled class="form-control"
                                        value="{{ getValue(item?.cmd['tra_ref_id']) }}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.cmd?.hasOwnProperty('tra_ref_id') || getValue(item?.cmd['tra_ref_id'])==''">
                                        Ajoutez le code à barre
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">name
                                    </legend>
                                    <input name="name" id="name" type="text" disabled class="form-control"
                                        value="{{getValue(item?.cmd['name'])}}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.cmd?.hasOwnProperty('name') || getValue(item?.cmd['name'])==''">
                                        Ajoutez le name
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">tra_date</legend>
                                    <input name="tra_date" id="tra_date" type="text" disabled
                                        value="{{getValue(item?.cmd?.tra_date)}}" class="form-control">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.cmd?.hasOwnProperty('tra_date') || getValue(item?.cmd?.tra_date)==''">
                                        Ajoutez le consignee
                                    </mat-error>
                                </div>
<!-- 
                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">Colonne1</legend>
                                    <input name="Colonne1" id="destination_{{index}}" type="text"
                                        class="form-control"
                                        [(ngModel)]="item.cmd.Dest">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.cmd?.hasOwnProperty('Dest') || getValue(item?.cmd?.Dest)==''">
                                        Ajoutez la destination
                                    </mat-error>
                                </div> -->

                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">Colonne1</legend>
                                    <input name="Colonne1" id="Colonne1" type="text" disabled
                                        class="form-control" value="{{getValue(item?.cmd['Colonne1'])}}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.cmd?.hasOwnProperty('Colonne1') || getValue(item?.cmd['Colonne1'])==''">
                                        Ajoutez Colonne1
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">tra_print_flag</legend>
                                    <input name="tra_print_flag" id="tra_print_flag" type="text" disabled class="form-control"
                                        value="{{getValue(item?.cmd['tra_print_flag'])}}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.cmd?.hasOwnProperty('tra_print_flag') || getValue(item?.cmd['tra_print_flag'])==''">
                                        Ajoutez tra_print_flag
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">Nombre des produits</legend>
                                    <input name="prds" id="prds" type="number" disabled class="form-control"
                                        value="{{getValue(item?.cmd['prds'].length)}}">
                                    <!-- <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.cmd?.hasOwnProperty('Destination Postal Code') || getValue(item?.cmd['Destination Postal Code'])==''">
                                        Ajoutez Destination Postal Code
                                    </mat-error> -->
                                </div>

                                <!-- <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">Consignee Telephone No
                                    </legend>
                                    <input name="numTelDest" id="numTelDest" type="number" disabled class="form-control"
                                        value="{{getValue(item?.cmd['Consignee Telephone No'])}}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.cmd?.hasOwnProperty('Consignee Telephone No') || getValue(item?.cmd['Consignee Telephone No'])==''">
                                        Ajoutez le ['Consignee Telephone No']
                                    </mat-error>
                                </div> -->
                                <!-- </div> -->
                                <!-- <div class="row"> -->
                                <!-- <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">Commodity Desc</legend>
                                    <input name="nom_prod" id="nom_prod" type="text" disabled class="form-control"
                                        value="{{getValue(item?.cmd['Commodity Desc'])}}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.cmd?.hasOwnProperty('Commodity Desc') || getValue(item?.cmd['Commodity Desc'])==''">
                                        Ajoutez Commodity Desc
                                    </mat-error>
                                </div> -->

                                <!-- <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">UOM</legend>
                                    <input name="UOM" id="UOM" type="text" disabled class="form-control"
                                        value="{{item?.cmd['UOM']}}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.cmd?.hasOwnProperty('UOM') || item?.cmd['UOM']==''">
                                        Ajoutez UOM
                                    </mat-error>
                                </div> -->

                                <!-- <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">Pkg Count</legend>
                                    <input name="quantite" id="quantite" type="number" class="form-control"
                                        value="{{getValue(item?.cmd['Pkg Count'])}}" disabled>
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.cmd?.hasOwnProperty('Pkg Count') || getValue(item?.cmd['Pkg Count'])==''">
                                        Ajoutez Pkg Count
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">Shpmt Weight</legend>
                                    <input name="prix_unitaire" id="prix_unitaire" type="number" disabled
                                        class="form-control" value="{{getValue(item?.cmd['Shpmt Weight'])}}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.cmd?.hasOwnProperty('Shpmt Weight') || getValue(item?.cmd['Shpmt Weight'])==''">
                                        Ajoutez Shpmt Weight
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">Custom Value</legend>
                                    <input name="prix_unitaire" id="prix_unitaire" type="number" disabled
                                        class="form-control" value="{{getValue(item?.cmd['Custom Value'])}}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.cmd?.hasOwnProperty('Custom Value') || getValue(item?.cmd['Custom Value'])==''">
                                        Ajoutez Custom Value
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">Currency</legend>
                                    <input name="prix_unitaire" id="prix_unitaire" type="text" disabled
                                        class="form-control" value="{{getValue(item?.cmd['Currency'])}}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.cmd?.hasOwnProperty('Currency') || getValue(item?.cmd['Currency'])==''">
                                        Ajoutez Currency
                                    </mat-error>
                                </div> -->
                                <!-- </div> -->
                            </div>
                        </div>
                        <div class="d-block text-center card-footer" *ngIf="item?.msg != null && item?.msg.msg!=''">
                            <div style="color: red;"><b>{{item?.msg?.msg}}</b></div>
                        </div>
                    </div>
                </li>
            </ul>

            <!-- <ul *ngIf="isTNT" class="list-group">
                <li class="list-group-item" *ngFor="let item of cmds, let index = index">
                    <div class="main-card mb-12 card">
                        <div class="card-header-tab card-header">

                            <div class="card-header-title">
                                <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
                                Colis {{index+1}}
                            </div>
                            <div class="btn-actions-pane-right">
                                <button type="button" (click)="deleteCmd(index)" class="btn-shadow mr-3 btn btn-danger">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>

                        </div>
                        <div class="card-body">
                            <div class="position-relative row form-group">
                                <div class="col-sm-3">
                                    <legend for="qrCode" class="space-bottom">Code à barre
                                    </legend>
                                    <input name="qrCode" id="qrCode" type="text" disabled class="form-control"
                                        value="{{ item?.cmd['Consignment No.'] + 
                                        ('0'.repeat(2-firstPartOfString(item?.cmd['Piece']).length) +  firstPartOfString(item?.cmd['Piece']).toString()) }}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.cmd?.hasOwnProperty('Consignment No.') || !item?.cmd?.hasOwnProperty('Piece') 
                                        || item?.cmd['Consignment No.']=='' || item?.cmd['Piece']==''">
                                        Ajoutez le code à barre
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">ShprName
                                    </legend>
                                    <input name="ShprName" id="ShprName" type="text" disabled class="form-control"
                                        value="{{item?.cmd['Receiver Name']}}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.cmd?.hasOwnProperty('Receiver Name') || item?.cmd['Receiver Name']==''">
                                        Ajoutez le Receiver Name
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">Consignee</legend>
                                    <input name="Consignee" id="Consignee" type="text" disabled
                                        value="{{item?.cmd['Receiver Address_1']}}" class="form-control">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.cmd?.hasOwnProperty('Receiver Address_1') || item?.cmd['Receiver Address_1']==''">
                                        Ajoutez le consignee
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">Destination</legend>
                                    <input name="destination_{{index}}" id="destination_{{index}}" type="text"
                                        class="form-control"
                                        [(ngModel)]="item.cmd['Receiver City Name']">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.cmd?.hasOwnProperty('Receiver City Name') || item?.cmd['Receiver City Name']==''">
                                        Ajoutez la destination
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">Origin Country Code</legend>
                                    <input name="originCountryCode" id="originCountryCode" type="text" disabled
                                        class="form-control" value="{{item?.cmd['Sender Country']}}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.cmd?.hasOwnProperty('Sender Country') || item?.cmd['Sender Country']==''">
                                        Ajoutez Origin Country Code
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">Destination Country Code</legend>
                                    <input name="countryCode" id="countryCode" type="text" disabled class="form-control"
                                        value="{{item?.cmd['Receiver Country']}}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.cmd?.hasOwnProperty('Receiver Country') || item?.cmd['Receiver Country']==''">
                                        Ajoutez Destination Country Code
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">Destination Postal Code</legend>
                                    <input name="postalCode" id="postalCode" type="number" disabled class="form-control"
                                        value="{{item?.cmd['Receiver Postal Code']}}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.cmd?.hasOwnProperty('Receiver Postal Code') || item?.cmd['Receiver Postal Code']==''">
                                        Ajoutez Destination Postal Code
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                </div>
                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">Commodity Desc</legend>
                                    <input name="nom_prod" id="nom_prod" type="text" disabled class="form-control"
                                        value="{{item?.cmd['Item Description']}}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.cmd?.hasOwnProperty('Item Description') || item?.cmd['Item Description']==''">
                                        Ajoutez Commodity Desc
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">Pkg Count</legend>
                                    <input name="quantite" id="quantite" type="number" class="form-control"
                                        value="{{ firstPartOfString(item?.cmd['Piece']) }}" disabled>
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.cmd?.hasOwnProperty('Piece') || item?.cmd['Piece']==''">
                                        Ajoutez Pkg Count
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">Shpmt Weight</legend>
                                    <input name="prix_unitaire" id="prix_unitaire" type="text" disabled
                                        class="form-control" value="{{item?.cmd['Actual Weight']}}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.cmd?.hasOwnProperty('Actual Weight') || item?.cmd['Actual Weight']==''">
                                        Ajoutez Shpmt Weight
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">Custom Value</legend>
                                    <input name="prix_unitaire" id="prix_unitaire" type="number" disabled
                                        class="form-control" value="{{item?.cmd['Value']}}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.cmd?.hasOwnProperty('Value')">
                                        Ajoutez Custom Value
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">Currency</legend>
                                    <input name="prix_unitaire" id="prix_unitaire" type="text" disabled
                                        class="form-control" value="{{item?.cmd['Currency']}}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.cmd?.hasOwnProperty('Currency') || item?.cmd['Currency']==''">
                                        Ajoutez Currency
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                        <div class="d-block text-center card-footer" *ngIf="item?.msg != null && item?.msg.msg!=''">
                            <div style="color: red;"><b>{{item?.msg?.msg}}</b></div>
                        </div>
                    </div>
                </li>
            </ul> -->

            <div class="position-relative row form-group" *ngIf="cmds.length > 0">
                <div class="col-sm-10 offset-sm-10">
                    <button type="submit" (click)="addCommandes()" class="btn btn-primary">Insertion
                        manifest</button>
                </div>
            </div>
        </form>

    </div>
</div>