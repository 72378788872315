import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DetailsCommandesComponent } from 'src/app/components/shared/dialogs/details-commandes/details-commandes.component';
import { SacsService } from 'src/app/services/sacs/sacs.service';

@Component({
  selector: 'app-cons',
  templateUrl: './cons.component.html',
  styleUrls: ['./cons.component.css']
})
export class ConsComponent implements OnInit {

  consList: any = []

  constructor(private sacsServices: SacsService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.listClients()
  }

  async listClients() {
    await this.sacsServices.getCons().then((res: any) => {
      // console.log(res)
      if (res) {
        this.consList = res;
      }
    });
  }

  async showCommandes(cmds) {
    window.scrollTo(0, 0);
    // console.log("amineeeeeeeeee")
    // this.selectedVirement = index
    const confirmDialog = this.dialog.open(DetailsCommandesComponent, {
      data: {
        commandes: cmds,
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
      }
    });

  }

}
