<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-car icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>
                <div class="page-title-subheading">Liste des colis en sortie magasin
                </div>
            </div>
        </div>
        <!-- <div class="page-title-actions">
            <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                class="btn-shadow mr-3 btn btn-dark">
                <i class="fa fa-star"></i>
            </button>
            <div class="d-inline-block dropdown">
                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    class="btn-shadow dropdown-toggle btn btn-info">
                    <span class="btn-icon-wrapper pr-2 opacity-7">
                        <i class="fa fa-business-time fa-w-20"></i>
                    </span>
                    Buttons
                </button>
                <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu dropdown-menu-right">
                    <ul class="nav flex-column">
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-inbox"></i>
                                <span>
                                    Inbox
                                </span>
                                <div class="ml-auto badge badge-pill badge-secondary">86</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-book"></i>
                                <span>
                                    Book
                                </span>
                                <div class="ml-auto badge badge-pill badge-danger">5</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-picture"></i>
                                <span>
                                    Picture
                                </span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a disabled href="javascript:void(0);" class="nav-link disabled">
                                <i class="nav-link-icon lnr-file-empty"></i>
                                <span>
                                    File Disabled
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div> -->
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Colis</div>
            <div class="table-responsive">
                <div class="budget-price justify-content-center" *ngIf="listCmds?.length === 0">
                    <h2 class="text-center" style="color: red;">Aucun colis {{statusCmds}} </h2>
                </div>
                <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                    *ngIf="listCmds?.length > 0">
                    <thead>
                        <tr>
                            <th class="text-center" style="background-color: grey;">
                                <div>
                                    #
                                </div>
                            </th>
                            <!-- <th>Client</th> -->
                            <th class="text-left">Référence</th>
                            <th>Master</th>
                            <th class="text-center">Date Création</th>
                            <th class="text-center">Nom Client & Téléphone</th>
                            <th class="text-center">Ville</th>
                            <th class="text-center">Produits<br>
                                Quantité - Nom produit</th>
                            <th class="text-center">Status</th>
                            <th class="text-center">Total colis</th>
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let cmd of listCmds, let index = index">
                            <td class="text-center" [class]="(cmd?.type_cmd == 'FEDEX') ? 'fedex' : 'tnt'">
                                <!-- #<b>{{index+1}}</b> -->
                                <div>
                                    #{{index+1}}
                                </div>
                            </td>
                            <td class="text">{{cmd?.qr_code}}<br><b
                                    [class]="(cmd?.type_cmd == 'FEDEX') ? 'text-fedex' : 'text-tnt'">{{cmd?.type_cmd}}</b>
                            </td>
                            <td class="text-center">{{cmd?.parent}}<br><b>{{cmd?.pkg_count}}</b></td>
                            <td class="text-center">{{cmd?.date_creation_cmd | date:'d MMM y'}}<br>
                                <b>{{cmd?.date_creation_cmd | date:'HH:mm'}}</b>
                            </td>
                            <td class="text-center">
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading">
                                                {{cmd?.user_destination?.nom_destination}}</div>
                                            <div class="widget-subheading opacity-7">
                                                {{cmd?.user_destination?.numtel_destination}}</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center">{{cmd?.user_destination?.adresse_destination}}
                                <br><strong>{{cmd?.destination_cmd}}</strong>
                            </td>
                            <td>
                                {{cmd?.pkg_count}} - {{cmd?.commodity_desc}}<br>
                                <b>{{cmd?.type_colis}}</b>
                            </td>
                            <td class="text-center">
                                <!-- <div class="badge badge-{{statusCmd[cmd?.etat_cmd]?.color}}">
                                    {{statusCmd[cmd?.etat_cmd]?.label}}</div> -->
                                <div *ngIf="cmd.etat_cmd == 'transporting_depot'"
                                    class="badge badge-{{statusCmd[cmd.etat_cmd].color}}">
                                    {{cmd?.zone?.zone_name}}</div>
                            </td>
                            <td class="text-center">{{cmd?.total_ca_cmd |
                                number:'0.2'}} {{cmd?.currency}}</td>
                            <td class="text-center">
                                <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                    <button class="btn-wide btn btn-info">Actions</button>
                                    <button type="button" ngbDropdownToggle
                                        class="dropdown-toggle-split dropdown-toggle btn btn-info"><span
                                            class="sr-only">Actions</span></button>
                                    <div ngbDropdownMenu>
                                        <button type="button" (click)="goToNewTab(cmd._id, '/detail-cmd')" tabindex="0"
                                            class="dropdown-item">
                                            <i class="metismenu-icon pe-7s-news-paper"
                                                style="margin-right: 10px;"></i>Détails
                                        </button>
                                        <button type="button" tabindex="0" (click)="goToNewTab(cmd._id, '/facture-cmd')"
                                            class="dropdown-item"><i class="metismenu-icon pe-7s-print"
                                                style="margin-right: 10px;"></i>Facture</button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-block text-center card-footer" *ngIf="listCmds?.length > 0 && pages?.length > 1">
                <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-center">
                        <li [ngClass]="(currentPage===0)?'page-item disabled':'page-item'">
                            <a class="page-link" tabindex="-1" (click)="previousPage()">
                                <i class="nav-link-icon pe-7s-angle-left-circle"></i>
                                Précédent</a>
                        </li>

                        <div *ngFor="let p of pagesDisplaied">
                            <li [ngClass]="(currentPage===p)?'page-item active':'page-item'">
                                <a *ngIf="currentPage!==p" class="page-link" (click)="selectPage(p)">{{p+1}}</a>

                                <span *ngIf="currentPage===p" class="page-link">
                                    {{p+1}}
                                    <span class="sr-only">(current)</span>
                                </span>
                            </li>
                        </div>
                        <!-- <li class="page-item active">
                                <span class="page-link">
                                    2
                                    <span class="sr-only">(current)</span>
                                </span>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li> -->

                        <li [ngClass]="(currentPage===totalPages-1)?'page-item disabled':'page-item'">
                            <a class="page-link" tabindex="-1" (click)="nextPage()">Suivant <i
                                    class="nav-link-icon pe-7s-angle-right-circle"></i></a>
                        </li>
                    </ul>
                </nav>

                <!-- <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                            class="pe-7s-trash btn-icon-wrapper"> </i></button>
                    <button class="btn-wide btn btn-success">Save</button> -->
            </div>
        </div>
    </div>
</div>