import { Component, ElementRef, HostBinding, HostListener, OnInit, ViewChild } from '@angular/core';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

import { select } from '@angular-redux/store';
import * as $ from 'jquery';
import { TeamsService } from 'src/app/services/teams-wassali/teams.service';
import { Router } from '@angular/router';
import { ThemeOptions } from 'src/app/theme-options';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.css']
})
export class TopComponent implements OnInit {

  @select('config') public config$: Observable<any>;

  private newInnerWidth: number;
  private innerWidth: number;

  // @ViewChild('native') native: ElementRef;
  isConnected = false
  allComments: any = []

  constructor(private cmdsService: CommandesService,
    private teamService: TeamsService,
    private router: Router,
    public globals: ThemeOptions,) {
    this.isConnected = this.teamService.isConnected()
  }

  @HostBinding('class.isActive')
  get isActiveAsGetter() {
    return this.isActive;
  }

  isActive: boolean;

  async ngOnInit() {
    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
    });

    await this.fetchComments()
  }

  async fetchComments() {
    await this.cmdsService.allComments().then((res: any) => {
      // console.log("fetchComments");
      // console.log(res);
      if (res) {
        this.allComments = res;
      }
    });
  }

  async deconnexion() {
    await this.teamService.deconnexion()
    // this.router.navigate(['/home'])
    window.location.reload()
    this.router.navigate(['/login'])
  }

  toggleSidebar() {
    // console.log("toggleSidebar")
    // console.log(this.globals.toggleSidebar)
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
  }

  sidebarHover() {
    // console.log("sidebarHover")
    // console.log(this.globals.sidebarHover)
    this.globals.sidebarHover = !this.globals.sidebarHover;
    // if(this.globals.sidebarHover) {
    //   this.globals.toggleSidebar = true
    //   this.globals.sidebarHover = false
    // }
  }

  toggleSidebarMobile() {
    // console.log("toggleSidebarMobile")
    // console.log(this.globals.toggleSidebarMobile)
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
    if(this.globals.toggleSidebarMobile) {
      this.globals.toggleHeaderMobile = false
    }
  }

  toggleHeaderMobile() {
    // console.log("toggleHeaderMobile")
    // this.globals.toggleHeaderMobile = !this.globals.toggleHeaderMobile;
    if(this.globals.toggleHeaderMobile) {
      this.globals.toggleSidebarMobile = false
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    if (this.newInnerWidth < 1200) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }

  }
}
