<!-- <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
    <agm-marker *ngFor="let m of markers; let i = index" [latitude]="m.latitude" [longitude]="m.longitude">

        <agm-info-window>
            <img width="40" height="40" class="rounded-circle"
                src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + m?.user_transportor?.profile_user}}" alt=""><br>
            <strong>{{m?.user_transportor?.prenom_user + " " + m?.user_transportor?.nom_user}}</strong><br>
            <strong>{{m?.user_transportor?.tel_user}}</strong>
        </agm-info-window>

    </agm-marker>
</agm-map> -->

<div class="my-google-map">
    <google-map [options]="mapOptions" width="100%" [height]="500">


        <!-- <map-marker *ngFor="let m of markers; let i = index" [position]="{ lat: m.latitude, lng: m.longitude }"
            [label]="label" [title]="title" (mapClick)="openInfo(markerElem)">

        </map-marker> -->


        <map-marker #marker="mapMarker" *ngFor="let m of markers; let i = index"
            [position]="{ lat: m.latitude, lng: m.longitude }" [options]="{icon: (m.onLine) ? 'http://maps.google.com/mapfiles/ms/icons/green-dot.png' : 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'}"
            (mapClick)="openInfoWindow(marker, i)">
        
            <map-info-window>
                <img width="40" height="40" class="rounded-circle"
                    src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + m?.user_transportor?.profile_user}}"
                    alt=""><br>
                <strong>{{m?.user_transportor?.prenom_user + " " + m?.user_transportor?.nom_user}}</strong><br>
                <strong>{{m?.user_transportor?.tel_user}}</strong>
            </map-info-window>
        </map-marker>
    </google-map>
</div>