<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-box2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Colis
                <div class="page-title-subheading">Détail du colis.
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="tab-content" id="tab-content">
                <div class="main-card mb-12 card">
                    <div class="card-body">
                        <div id="commande" #commande>
                            <div class="row">
                                <div class="col-md-7">
                                    <div class="text space">
                                        <h4><strong>Colis</strong></h4>
                                    </div>
                                </div>
                                <div class="col-md-5 text-md-right">
                                    <h4><strong>#{{cmd.tra_ref_id}}</strong></h4>
                                    <!-- <qrcode print [width]="150" usesvg="true" [elementType]="'svg'" [qrdata]="cmd._id"
                            [errorCorrectionLevel]="'M'"></qrcode> -->
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-7">
                                    <div class="text space">
                                        <qrcode print [width]="200" usesvg="true" [elementType]="'svg'"
                                            [qrdata]="cmd._id" [errorCorrectionLevel]="'M'">
                                        </qrcode>

                                        <!-- <ngx-barcode
                                            [bc-value]="(cmd != null && cmd.real_qr_code != null) ? cmd.real_qr_code : (cmd.type_cmd == 'TNT' ? '1100' : '16516516') + cmd.qr_code + (cmd.type_cmd == 'TNT' ? '00000' : '')"
                                            [bc-display-value]="true"></ngx-barcode> -->
                                    </div><br><br>
                                    <strong>Nombre des colis: {{cmd?.products?.prdts?.length}}</strong>
                                </div>
                                <div class="col-md-5 text-md-right">
                                    <strong>Client:</strong><br />
                                    {{cmd.client_cmd?.nom_client + " " +cmd.client_cmd?.prenom_client}}<br />
                                    {{cmd.client_cmd?.company_name}}<br />
                                    {{cmd.client_cmd?.username_client}}<br />
                                    {{cmd.client_cmd?.numTel_client}}<br /><br />

                                    <strong>Date du colis:</strong><br />
                                    {{cmd.date_creation_cmd | date:'dd/MM/yyyy'}}<br />
                                    <!-- <div class="badge badge-{{statusCmds[cmd.etat_cmd]?.color}}">
                                        {{statusCmds[cmd.etat_cmd]?.label}}
                                    </div> -->

                                    <div
                                        class="badge badge-{{statusCmds[cmd.etat_cmd]?.color}}">
                                        {{statusCmds[cmd?.etat_cmd]?.label}}</div>
                                    <!-- <div *ngIf="cmd.etat_cmd == 'transporting_depot'"
                                        class="badge badge-{{statusCmds[cmd.etat_cmd]?.color}}">
                                        {{cmd?.store?.store_name}}</div> -->
                                </div>
                            </div>

                            <div class="clearfix"></div>

                            <div class="row">
                                <div class="col-md-12">
                                    <!-- <div class="main-card mb-3 card"> -->
                                    <div class="section-title">
                                        Détails du colis
                                    </div>

                                    <div class="table-responsive">
                                        <div class="budget-price justify-content-center"
                                            *ngIf="cmd.products?.length === 0">
                                            <h5 class="text-center">Aucune produit affecté dans la
                                                commande
                                            </h5>
                                        </div>
                                        <table
                                            class="align-middle mb-0 table table-borderless table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    <th class="text-center">#</th>
                                                    <th class="text-left">Code</th>
                                                    <th class="text-left">Description du produit</th>
                                                    <th class="text-center">Famille</th>
                                                    <th class="text-center">Sous famille</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let prd of cmd?.products?.prdts, let index = index">
                                                    <td class="text-center text-muted">#{{index + 1}}</td>
                                                    <td class="text">{{prd?.prd_id?.item_code}}</td>
                                                    <td class="text">{{prd?.prd_id?.item_name}}</td>
                                                    <td class="text-center">{{prd?.prd_id?.Family}}</td>
                                                    <td class="text-center">{{prd?.prd_id?.SubFamily}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- </div> -->
                                </div>
                            </div>

                            <div class="clearfix"></div>

                            <div class="row mt-4">
                                <div class="col-lg-8">
                                    <div class="section-title">Mode de livraison</div>
                                    <p class="section-lead">
                                        À domicile
                                        <br />→ <b>{{cmd.destination_cmd}}</b>
                                    </p>
                                </div>
                                <!-- <div class="col-lg-4 text-right">
                                    <div class="invoice-detail-item">
                                        <div class="invoice-detail-name">Prix des article(s):
                                            {{cmd.total_ca_cmd + " " + cmd.currency}}</div>
                                        <div class="invoice-detail-value">Montant total:
                                            {{cmd.total_ca_cmd + " " + cmd.currency}}
                                        </div>
                                    </div>

                                </div> -->
                            </div>
                        </div>
                    </div>

                    <div class="d-block text-center card-footer">
                        <!-- <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                                class="pe-7s-trash btn-icon-wrapper"> </i></button> -->

                        <!-- <button class="btn-wide btn btn-success">Save</button> -->
                        <button class="btn btn-danger float-left" [routerLink]="[ '/all-commandes']"><i
                                class="metismenu-icon pe-7s-back"></i> Retour</button>

                        <button class="btn btn-warning float-right text-white" [routerLink]="['/facture-cmd']"
                            [queryParams]="{ cmd: cmd._id }"><i class="metismenu-icon pe-7s-print text-white"></i>
                            Imprimer</button>
                        <!-- <button class="btn btn-warning float-right" (click)="generatePDF()"><i
                                class="metismenu-icon pe-7s-print"></i> Imprimer</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Commentaires</div>
            <div class="card-body">
                <div class="chat-container">
                    <ul class="chat-box chatContainerScroll">
                        <div *ngFor="let comment of cmd.comments, let index = index">

                            <li class="chat-right" *ngIf="comment.type_user == 'client'">
                                <div class="chat-hour">{{comment.date_ajout | date:'d MMM y HH:mm'}} <span
                                        class="fa fa-check-circle"></span></div>
                                <div class="chat-text">{{comment.content}}</div>
                                <div class="chat-avatar">
                                    <!-- <img src="https://www.bootdey.com/img/Content/avatar/avatar4.png"
                                        alt="Retail Admin"> -->
                                    <ngx-avatar width="42" class="rounded-circle" name="{{comment?.name_user}}">
                                    </ngx-avatar>
                                    <div class="chat-name">{{comment.name_user}}</div>
                                </div>
                            </li>

                            <li class="chat-left" *ngIf="comment.type_user !== 'client'">
                                <div class="chat-avatar">
                                    <ngx-avatar width="42" class="rounded-circle" name="{{comment?.name_user}} ">
                                    </ngx-avatar>
                                    <div class="chat-name">{{comment.name_user}}</div>
                                </div>
                                <div class="chat-text">{{comment.content}}</div>
                                <div class="chat-hour">{{comment.date_ajout | date:'d MMM y HH:mm'}} <span
                                        class="fa fa-check-circle"></span></div>
                            </li>

                        </div>

                    </ul>

                    <div class="form-group mt-3 mb-0">
                        <form class="" action="" method="post" onsubmit="return false;"
                            (onsubmit)="addComment(comment);">
                            <div class="form-row">
                                <div class="col-md-1">
                                    <div class="position-relative form-group">
                                        <ngx-avatar width="42" class="rounded-circle" name="Admin">
                                        </ngx-avatar>
                                    </div>
                                    <!-- {{client?.prenom_client +" "+client?.nom_client}} -->
                                </div>
                                <div class="col-md-10">
                                    <div class="position-relative form-group">
                                        <textarea name="comment" id="comment" class="form-control" rows="2"
                                            placeholder="Ajouter un commentaire..." #comment ngModel></textarea>
                                    </div>
                                </div>
                                <div class="col-md-1">
                                    <div class="position-relative form-group">
                                        <button type="submit" (click)="addComment(comment)"
                                            class="form-control btn btn-success">Envoyer</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-6">
        <div class="main-card mb-3 card">
            <div class="card-header">Détail du colis "<strong>{{cmd.tra_ref_id}}</strong>"
                <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button type="button" id="PopoverCustomT-1" class="btn btn-danger btn-sm">Détails <i
                                class="metismenu-icon pe-7s-angle-right"></i></button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Description</th>
                                <th>Date de l'action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <!-- <td><a href="#"  class="font-weight-600">INV-87239</a></td> -->
                                <td>
                                    <div class="badge badge-warning">Créé</div>
                                </td>
                                <td class="font-weight-600">Par
                                    {{cmd?.user_destination?.nom_destination}}
                                </td>
                                <td>{{cmd.date_creation_cmd | date:'MMM d, y h:mm:ss a'}}</td>
                            </tr>
                            <tr *ngIf="cmd.date_reception_cmd!=null">
                                <!-- <td><a href="#">INV-76824</a></td> -->
                                <td>
                                    <!-- <div class="badge badge-{{statusCmds['transporting_depot']?.color}}">
                                        {{statusCmds['transporting_depot'].label}}</div> -->

                                    <div class="badge badge-{{statusCmds['transporting_depot'].color}}">
                                        {{"Au " + cmd?.store?.store_name}}</div>
                                </td>
                                <td class="font-weight-600">Réceptionné par
                                    {{cmd.reception_by?.transporter_id?.nom_prenom_tracking}}
                                </td>
                                <td>{{cmd?.date_reception_cmd | date:'MMM d, y h:mm:ss a'}}
                                </td>
                            </tr>
                            <tr *ngIf="cmd.prepared_by!=null">
                                <!-- <td><a href="#">INV-76824</a></td> -->
                                <td>
                                    <div class="badge badge-{{statusCmds['prepared']?.color}}">
                                        {{statusCmds['prepared'].label}}</div>
                                </td>
                                <td class="font-weight-600">Préparé par
                                    {{cmd.prepared_by?.transporter_id?.nom_prenom_tracking}}
                                </td>
                                <td>{{cmd.prepared_by?.date_accept | date:'MMM d, y h:mm:ss a'}}
                                </td>
                            </tr>
                            <tr *ngIf="cmd.pcollect_transport!=null">
                                <!-- <td><a href="#">INV-76824</a></td> -->
                                <td>
                                    <div class="badge badge-{{statusCmds['transporting_depot']?.color}}">
                                        {{statusCmds['transporting_depot'].label}}</div>
                                </td>
                                <td class="font-weight-600">Collecté par
                                    {{cmd.pcollect_transport?.transporter_id?.nom_prenom_tracking}}
                                </td>
                                <td>{{cmd.pcollect_transport?.date_accept | date:'MMM d, y h:mm:ss a'}}
                                </td>
                            </tr>
                            <tr *ngIf="cmd.to_governorate!=null">
                                <!-- <td><a href="#">INV-76824</a></td> -->
                                <td>
                                    <div class="badge badge-{{statusCmds['transporting_to']?.color}}">
                                        {{statusCmds['transporting_to'].label}}</div>
                                </td>
                                <td class="font-weight-600">Colis en cours de transport vers
                                    "{{cmd.destination_cmd}}"
                                </td>
                                <td>{{cmd.to_governorate | date:'MMM d, y h:mm:ss a'}}
                                </td>
                            </tr>
                            <tr *ngIf="cmd.between_governorate!=null">
                                <!-- <td><a href="#">INV-76824</a></td> -->
                                <td>
                                    <div class="badge badge-{{statusCmds['transporting_between']?.color}}">
                                        {{statusCmds['transporting_between'].label}}</div>
                                </td>
                                <td class="font-weight-600">Colis en cours de transport entre
                                    "{{cmd.destination_cmd}}"
                                </td>
                                <td>{{cmd.to_governorate | date:'MMM d, y h:mm:ss a'}}
                                </td>
                            </tr>
                            <tr *ngIf="cmd.transport_topcollect!=null">
                                <!-- <td><a href="#">INV-48574</a></td> -->
                                <td>
                                    <div class="badge badge-{{statusCmds['transporting']?.color}}">
                                        {{statusCmds['transporting'].label}}</div>
                                </td>
                                <td class="font-weight-600">Colis en cours de transport dans
                                    "{{cmd.destination_cmd}}"<br>
                                    Par {{cmd.transport_topcollect?.transporter_id?.nom_prenom_tracking}}
                                </td>
                                <td>{{cmd.transport_topcollect?.date_accept | date:'MMM d, y h:mm:ss
                                    a'}}
                                </td>
                            </tr>
                            <tr *ngIf="cmd.date_relivered_cmd!=null">
                                <!-- <td><a href="#">INV-48574</a></td> -->
                                <td>
                                    <div class="badge badge-{{statusCmds['delivered']?.color}}">
                                        {{statusCmds['delivered'].label}}</div>
                                </td>
                                <td class="font-weight-600">Colis livré au client
                                    "{{cmd.user_destination.nom_destination}}"</td>
                                <td>{{cmd.date_relivered_cmd | date:'MMM d, y h:mm:ss
                                    a'}}
                                </td>
                            </tr>
                            <tr *ngIf="cmd.payed_cmd==true">
                                <!-- <td><a href="#">INV-84990</a></td> -->
                                <td>
                                    <div class="badge badge-{{statusCmds['paied']?.color}}">
                                        {{statusCmds['paied'].label}}
                                    </div>
                                </td>
                                <td class="font-weight-600">Colis payé à l'expéditeur<br>
                                    "{{cmd.client_cmd.prenom_client}} {{cmd.client_cmd.nom_client}}"</td>
                                <td>{{cmd.date_payed_cmd | date:'MMM d, y h:mm:ss a'}}</td>
                            </tr>
                            <tr *ngIf="cmd.date_returned_cmd!=null">
                                <!-- <td><a href="#">INV-87320</a></td> -->
                                <td>
                                    <div class="badge badge-{{statusCmds['returned']?.color}}">
                                        {{statusCmds['returned'].label}}
                                    </div>
                                </td>
                                <td class="font-weight-600"
                                    *ngIf="cmd?.transport_affect[cmd?.transport_affect?.length-1]?.transporter_id==null">
                                    Par
                                    {{cmd?.transport_affect[cmd?.transport_affect?.length-2]?.transporter_id?.nom_prenom_tracking}}<br />{{cmd.msg_returned_cmd}}
                                </td>
                                <td class="font-weight-600"
                                    *ngIf="cmd?.transport_affect[cmd?.transport_affect?.length-1]?.transporter_id!=null">
                                    Par
                                    {{cmd?.transport_affect[cmd?.transport_affect?.length-1]?.transporter_id?.nom_prenom_tracking}}<br />{{cmd.msg_returned_cmd}}
                                </td>
                                <td>{{cmd.date_returned_cmd | date:'MMM d, y h:mm:ss a'}}</td>
                            </tr>
                            <!-- <tr>
                                <td class="text-center text-muted">#345</td>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <img width="40" class="rounded-circle"
                                                        src="assets/images/avatars/4.jpg" alt="">
                                                </div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading">John Doe</div>
                                                <div class="widget-subheading opacity-7">Web Developer</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">Madrid</td>
                                <td class="text-center">
                                    <div class="badge badge-warning">Pending</div>
                                </td>
                                <td class="text-center">
                                    <button type="button" id="PopoverCustomT-1"
                                        class="btn btn-primary btn-sm">Détails</button>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center text-muted">#347</td>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <img width="40" class="rounded-circle"
                                                        src="assets/images/avatars/3.jpg" alt="">
                                                </div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading">Ruben Tillman</div>
                                                <div class="widget-subheading opacity-7">Etiam sit amet orci
                                                    eget</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">Berlin</td>
                                <td class="text-center">
                                    <div class="badge badge-success">Completed</div>
                                </td>
                                <td class="text-center">
                                    <button type="button" id="PopoverCustomT-2"
                                        class="btn btn-primary btn-sm">Détails</button>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center text-muted">#321</td>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <img width="40" class="rounded-circle"
                                                        src="assets/images/avatars/2.jpg" alt="">
                                                </div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading">Elliot Huber</div>
                                                <div class="widget-subheading opacity-7">Lorem ipsum dolor
                                                    sic</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">London</td>
                                <td class="text-center">
                                    <div class="badge badge-danger">In Progress</div>
                                </td>
                                <td class="text-center">
                                    <button type="button" id="PopoverCustomT-3"
                                        class="btn btn-primary btn-sm">Détails</button>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center text-muted">#55</td>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <img width="40" class="rounded-circle"
                                                        src="assets/images/avatars/1.jpg" alt="">
                                                </div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading">Vinnie Wagstaff</div>
                                                <div class="widget-subheading opacity-7">UI Designer</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">Amsterdam</td>
                                <td class="text-center">
                                    <div class="badge badge-info">On Hold</div>
                                </td>
                                <td class="text-center">
                                    <button type="button" id="PopoverCustomT-4"
                                        class="btn btn-primary btn-sm">Détails</button>
                                </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>

    <div class="col-6 card mb-3">
        <div class="row">
            <div #map id="map-canvas"></div>
            <div class="map-loader-container" *ngIf="!mapLoaded">
                <div class="map-loader"></div>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="cmd?.appel_client?.length>0">
    <div class="col-md-7">
        <div class="main-card mb-3 card">
            <div class="card-header">Détail des appels du colis
                "<strong>{{cmd.tra_ref_id}}</strong>"
                <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button type="button" id="PopoverCustomT-1"
                            class="btn btn-danger btn-sm">{{cmd.appel_client.length}}</button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                        <thead>
                            <tr>
                                <th>Indice</th>
                                <th>Transporteur</th>
                                <th>Date de l'appel</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let appel of cmd.appel_client, let index = index">
                                <td>
                                    {{index+1}}
                                </td>
                                <td class="font-weight-600">Par
                                    {{appel.transporter_id?.nom_prenom_tracking}}</td>
                                <td>{{appel.date_accept | date:'MMM d, y h:mm:ss a'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</div>