<i class="material-icons" style="font-size: 100px;color: #ff0000">info</i>
<div mat-dialog-content>
    <h2 style="text-align:center">Vous allez sélectionner le DISPATCH</h2>
    <div class="div-form">
        <div class="form-group">
            <select class="form-control selectric" (change)="selectChangeHandler($event)" >
                <option [value]="null">Selectionner un DISPATCH</option>
                <option [value]="user._id" *ngFor="let user of leadersList">
                    {{user.nom_prenom_tracking}} {{user.lastName}}
                </option>
            </select>
        </div>
    </div>
</div>
<div class="row float-right margin-top">
    <button
    [mat-dialog-close]="false" class="btn btn-info">NON</button>

    <button style="margin-right: 15px;" 
    [mat-dialog-close]="true" 
    class="btn btn-danger">OUI</button>
</div>
