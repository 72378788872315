import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StoresService {

  constructor(private http: HttpClient) { }

  addStore(storeName, typesColis,
    cityName) {
    return new Promise((slv) => {
      this.http.post(environment.apiAddStore, {
        storeName: storeName,
        cityName: cityName,
        typesColis: typesColis
      }).subscribe(
        (data: any) => {
          if (data && data.response) {
            slv(true);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
  async getDetailsStores() {
    return await new Promise((slv) => {
      this.http.post(environment.apiAllDetailStores, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async getDisplayStores() {
    return await new Promise((slv) => {
      this.http.post(environment.apiAllDisplayStores, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async getStores() {
    return await new Promise((slv) => {
      this.http.post(environment.apiAllStores, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async getStore(idStore) {
    return await new Promise((slv) => {
      this.http.post(environment.apiAllStore, {idStore}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
}
