<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-next-2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Importion Via Excel
                <div class="page-title-subheading">Importation des clients.
                </div>
            </div>
        </div>
    </div>
</div>

<div class="main-card mb-12 card">
    <div class="card-header-tab card-header">
        <div class="card-header-title">
            <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
            Etat import - Clients
        </div>
        <div class="btn-actions-pane-right">
            <input hidden type="file" (change)="readExcel($event)" multiple="false" #file>
            <button type="button" (click)="file.click()" data-toggle="tooltip" title="Example Tooltip"
                data-placement="bottom" class="btn-shadow mr-3 btn btn-info">
                <i class="fa fa-star"> Importer fichier excel</i>
            </button>
        </div>
    </div>
    <div class="card-body">
        <div class="budget-price justify-content-center" *ngIf="msgError != null">
            <h2 class="text-center" style="color: red;">{{msgError}}<br>{{itemsError}}</h2>
        </div>
        <form class="" action="" method="post" onsubmit="return false;" (onsubmit)="addClients();">

            <ul class="list-group">
                <li class="list-group-item" *ngFor="let item of clients, let index = index">
                    <div class="main-card mb-12 card">
                        <div class="card-header-tab card-header">

                            <div class="card-header-title">
                                <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
                                Client
                            </div>
                            <div class="btn-actions-pane-right">
                                <button type="button" (click)="deleteCmd(index)" class="btn-shadow mr-3 btn btn-danger">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>

                        </div>
                        <div class="card-body">
                            <div class="position-relative row form-group">
                                <!-- <div class="row"> -->
                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">CLIENT
                                    </legend>
                                    <input name="client" id="client" type="text" disabled class="form-control"
                                        value="{{ getValue(item?.client['CLIENT']) }}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.client?.hasOwnProperty('CLIENT') || getValue(item?.client['CLIENT'])==''">
                                        Ajoutez le nom du client
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">VILLE
                                    </legend>
                                    <input name="ville" id="ville" type="text" disabled class="form-control"
                                        value="{{getValue(item?.client['VILLE'])}}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.client?.hasOwnProperty('VILLE') || getValue(item?.client['VILLE'])==''">
                                        Ajoutez la ville
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">ZONE</legend>
                                    <input name="ZONE" id="ZONE" type="text" disabled
                                        value="{{getValue(item?.client?.ZONE)}}" class="form-control">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.client?.hasOwnProperty('ZONE') || getValue(item?.client?.ZONE)==''">
                                        Ajoutez la Zone
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">TYPE DE MAGASINS</legend>
                                    <input name="typemagazin" id="typemagazin" type="text" disabled
                                        class="form-control" value="{{getValue(item?.client['TYPE DE MAGASINS'])}}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.client?.hasOwnProperty('TYPE DE MAGASINS') || getValue(item?.client['TYPE DE MAGASINS'])==''">
                                        Ajoutez Origin Country Code
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">PRIORITE</legend>
                                    <input name="priorite" id="priorite" type="text" disabled class="form-control"
                                        value="{{getValue(item?.client['PRIORITE'])}}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.client?.hasOwnProperty('PRIORITE') || getValue(item?.client['PRIORITE'])==''">
                                        Ajoutez Priorite
                                    </mat-error>
                                </div>

                                <div class="col-sm-3">
                                    <legend for="client" class="space-bottom">DELAI DE LIVRAISON</legend>
                                    <input name="delai" id="delai" type="text" disabled class="form-control"
                                        value="{{getValue(item?.client['DELAI DE LIVRAISON'])}}">
                                    <mat-error style="color: red;font-weight: bold;"
                                        *ngIf="!item?.client?.hasOwnProperty('DELAI DE LIVRAISON') || getValue(item?.client['DELAI DE LIVRAISON'])==''">
                                        Ajoutez delai de livraison
                                    </mat-error>
                                </div>
                                <!-- </div> -->
                            </div>
                        </div>
                        <div class="d-block text-center card-footer" *ngIf="item?.msg != null && item?.msg.msg!=''">
                            <div style="color: red;"><b>{{item?.msg?.msg}}</b></div>
                        </div>
                    </div>
                </li>
            </ul>

            <div class="position-relative row form-group" *ngIf="clients.length > 0">
                <div class="col-sm-10 offset-sm-10">
                    <button type="submit" (click)="addClients()" class="btn btn-primary">Insertion
                        manifest</button>
                </div>
            </div>
        </form>

    </div>
</div>