import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-multiple-commandes',
  templateUrl: './multiple-commandes.component.html',
  styleUrls: ['./multiple-commandes.component.css']
})
export class MultipleCommandesComponent implements OnInit {

  @ViewChild('file')
  myfileImport: ElementRef;

  msgError: string = null
  itemsError: any = []
  cmds: any = []

  // labelType: string = ""
  // isFedex: boolean = false
  // isTNT: boolean = false

  constructor(private cmdService: CommandesService) { }

  ngOnInit(): void {
  }

  docIsValid(array) {
    // console.log("array")
    // console.log(array)
    if (array.includes("ia_item_code")
      && array.includes("tra_ref_id")
      && array.includes("name")
      && array.includes("Total")
      && array.includes("tra_po_number")
      && array.includes("tra_date")
      && array.includes("Colonne1")
      && array.includes("tra_print_flag")
      && array.includes("Volume en m3")
      && array.includes("Volume en m4")
      && array.includes("Volume en m5")) {
      return true
    } else {
      if (!array.includes("ia_item_code")) {
        this.itemsError.push("ia_item_code")
      }
      if (!array.includes("tra_ref_id")) {
        this.itemsError.push("tra_ref_id")
      }
      if (!array.includes("name")) {
        this.itemsError.push("name")
      }
      if (!array.includes("Total")) {
        this.itemsError.push("Total")
      }
      if (!array.includes("tra_po_number")) {
        this.itemsError.push("tra_po_number")
      }
      if (!array.includes("tra_date")) {
        this.itemsError.push("tra_date")
      }
      if (!array.includes("Colonne1")) {
        this.itemsError.push("Colonne1")
      }
      if (!array.includes("tra_print_flag")) {
        this.itemsError.push("tra_print_flag")
      }
      if (!array.includes("Volume en m3")) {
        this.itemsError.push("Volume en m3")
      }
      if (!array.includes("Volume en m4")) {
        this.itemsError.push("Volume en m4")
      }
      if (!array.includes("Volume en m5")) {
        this.itemsError.push("Volume en m5")
      }
      return false
    }
  }

  // docIsTNT(array) {
  //   if(array.includes("Consignment No.") && array.includes("Piece") 
  //       && array.includes('Receiver Name') 
  //       && array.includes("Receiver Address_1") 
  //       /*&& array.includes('Orgn')*/ 
  //       && array.includes('Receiver City Name') 
  //       && array.includes('Quantity') 
  //       && array.includes('Actual Weight')
  //       && array.includes('Item Description') 
  //       && array.includes('Value') 
  //       && array.includes('Currency') 
  //       /*&& array.includes('Consignee Telephone No')*/
  //       && array.includes('Sender Country') 
  //       && array.includes('Receiver Country') 
  //       && array.includes('Receiver Postal Code')
  //       /*&& array.includes('UOM')*/
  //       && array.includes('HV-LV')
  //       ) {
  //         this.labelType = "TNT"
  //         this.isTNT = true
  //         return true
  //   } else {
  //     return false
  //   }
  // }

  firstPartOfString(str) {
    return str.split(' ')[0]
  }

  // lastPartOfString(str) {
  //   var array = str.split(' ')
  //   return array[array.length]
  // }

  readExcel(event) {

    const workbook = new Excel.Workbook();
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }

    /**
     * Final Solution For Importing the Excel FILE
     */
    this.msgError = null
    // this.labelType = ""
    // this.isFedex = false
    // this.isTNT = false
    this.cmds = []
    const header = [] //["nameDest", "adressDest", "numTelDest", "destination", "nom_prod", "quantite", "prix_unitaire"]

    const arryBuffer = new Response(target.files[0]).arrayBuffer();
    arryBuffer.then((data) => {
      workbook.xlsx.load(data)
        .then(async () => {

          // play with workbook and worksheet now
          // console.log(workbook);
          const worksheet = workbook.getWorksheet('BL non facturés');
          console.log(worksheet)
          console.log('rowCount: ', worksheet.getRow(1).values);
          if (!this.docIsValid(worksheet.getRow(1).values.map(Function.prototype.call, String.prototype.trim))
                /*&& !this.docIsTNT(worksheet.getRow(1).values.map(Function.prototype.call, String.prototype.trim))*/) {
            // console.log("file incorrecte !!!")
            this.msgError = "Impossible d'importer le fichier excel à cause d'un champ manquant"
            return false
          }

          const promises = [];
          // worksheet.
          worksheet.eachRow((row, rowNumber) => {
            // console.log('Row: ' + rowNumber + ' Value: ' + row.values);
            // console.log('Row ' + rowNumber + ' = ' + JSON.stringify(row.values));
            let cmd = {}
            if (rowNumber == 1) {
              row.eachCell((cell, colNumber) => {
                // console.log('Cell ' + colNumber + ' , ' + header[colNumber - 1] + ' = ' + cell.value);
                header.push(cell.value.toString().trim())
              });

            } else {
              // const promise = db.insert(row); // <-- whatever async operation you have here
              row.eachCell((cell, colNumber) => {
                // console.log('Cell ' + colNumber + ' , ' + header[colNumber - 1] + ' = ' + cell.value);
                cmd[header[colNumber - 1]] = cell.value
              });
              // const found = promises.some();
              if (cmd["tra_ref_id"] != null && cmd["tra_ref_id"] != "") {
                var found = promises.find(el => el.cmd.tra_ref_id === cmd["tra_ref_id"])
                if (!found)
                  promises.push({ cmd: this.synchroniseCmd(cmd), msg: null });
                else {
                  let index = promises.indexOf(found);
                  promises[index].cmd.prds.push(this.getPrdFromCmd(cmd))
                }
              }
            }
          });
          this.cmds = await Promise.all(promises)

          console.log("this.cmdsssssssss : ")
          console.log(this.cmds)
        });
    });

    this.myfileImport.nativeElement.value = "";
  }

  getPrdFromCmd(cmd) {
    var prd = {
      ia_item_code: cmd.ia_item_code,
      Total: cmd.Total,
      "Volume_m3": cmd["Volume en m3"].result,
      "Volume_m4": cmd["Volume en m4"]
    }
    return prd
  }
  synchroniseCmd(cmd) {
    var prd = {
      ia_item_code: cmd.ia_item_code,
      Total: cmd.Total,
      "Volume_m3": cmd["Volume en m3"].result,
      "Volume_m4": cmd["Volume en m4"]
    }
    var theCmd = {
      tra_ref_id: cmd.tra_ref_id,
      name: cmd.name,
      "tra_date": cmd.tra_date,
      "Colonne1": cmd.Colonne1,
      "tra_print_flag": cmd.tra_print_flag,
      prds: [
        prd
      ]
    }
    return theCmd
  }

  deleteCmd(index) {
    this.cmds.splice(index, 1)
  }

  cmdIsValidated(cmd) {
    if ((!cmd.hasOwnProperty('Tracking Number') || (cmd.hasOwnProperty('Tracking Number') && cmd.nameDest == ""))
      || (!cmd.hasOwnProperty('Shpr Name') || (cmd.hasOwnProperty('Shpr Name') && cmd.adressDest == ""))
      || (!cmd.hasOwnProperty('Orgn') || (cmd.hasOwnProperty('Orgn') && cmd.numTelDest == ""))
      || (!cmd.hasOwnProperty('Consignee') || (cmd.hasOwnProperty('Consignee') && cmd.destination == ""))
      // || (!cmd.hasOwnProperty('nom_prod') || (cmd.hasOwnProperty('nom_prod') && cmd.nom_prod == ""))
      // || (!cmd.hasOwnProperty('quantite') || (cmd.hasOwnProperty('quantite') && cmd.quantite == ""))
      // || (!cmd.hasOwnProperty('prix_unitaire') || (cmd.hasOwnProperty('prix_unitaire') && cmd.prix_unitaire == ""))
    ) {
      // console.log("falseeee")
      return false
    } else {
      return true
    }
  }

  cmdsIsValidated() {
    for (let i = 0; i < this.cmds.length; i++) {
      if (!this.cmdIsValidated(this.cmds[i])) {
        // console.log("falseeee")
        return false
      }
    }
    return true
  }

  getValue(item) {
    // console.log("itemmmmmmm")
    // console.log(item)
    if (item != null && item.hasOwnProperty("result")) {
      return item.result
    } else {
      return item
    }
  }

  addCommande(item) {
    let promise = new Promise((resolve, reject) => {
      this.cmdService.addCmd(item.cmd)
        .then((res: any) => {
          console.log("resssssssss : ")
          console.log(res)
          if (res) {
            //   alert('Commande est ajoutée avec succès');
            //   this.cleanData(nameDest, adressDest, numTelDest)
            //   //location.href = '/sacs';
            //   //this.sacsList = res;
            // validated = validated && true
            // console.log("validated : " + validated)
            // promises.push({ cmd: item, msg: res });
            // item.msg = res
            resolve(res);
          }
        });
    });
    return promise
  }

  async addCommandes() {
    // if (!this.cmdsIsValidated()) {
    //   alert('Quelques commandes sont invalides');
    //   this.cmds = []
    // } else {
    // let validated = false

    let promises: Promise<any>[] = [];
    this.cmds.forEach(item => {
      let promise: Promise<any>
      // if(this.isFedex) {
      promise = this.addCommande(item).then((value: any) => {
        return { cmd: item.cmd, msg: value };
      });

      promises.push(promise);
    });

    Promise.all(promises)
      .then((results) => {
        this.cmds = results.filter(item => item.msg != null && item.msg.response == false);
      })

  }

}
