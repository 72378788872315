<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-box2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Colis
                <div class="page-title-subheading">Ajouter export.
                </div>
            </div>
        </div>
        <!-- <div class="page-title-actions">
            <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                class="btn-shadow mr-3 btn btn-dark">
                <i class="fa fa-star"></i>
            </button>
            <div class="d-inline-block dropdown">
                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    class="btn-shadow dropdown-toggle btn btn-info">
                    <span class="btn-icon-wrapper pr-2 opacity-7">
                        <i class="fa fa-business-time fa-w-20"></i>
                    </span>
                    Buttons
                </button>
                <div tabindex="-1" role="menu" aria-hidden="true"
                    class="dropdown-menu dropdown-menu-right">
                    <ul class="nav flex-column">
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-inbox"></i>
                                <span>
                                    Inbox
                                </span>
                                <div class="ml-auto badge badge-pill badge-secondary">86</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-book"></i>
                                <span>
                                    Book
                                </span>
                                <div class="ml-auto badge badge-pill badge-danger">5</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-picture"></i>
                                <span>
                                    Picture
                                </span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a disabled href="javascript:void(0);" class="nav-link disabled">
                                <i class="nav-link-icon lnr-file-empty"></i>
                                <span>
                                    File Disabled
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div> -->
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-12 card">
            <div class="card-header-tab card-header">
                <div class="card-header-title">
                    <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
                    Détail colis
                </div>
                <div class="btn-actions-pane-right">
                    <form class="form-inline">
                        <div class="mb-2 mr-sm-2 mb-sm-0 position-relative form-group">
                            <select class="form-control selectric" (change)="selectClient($event)">
                                <option value="Selectionnez le client">
                                    Selectionnez le client</option>
                                <option [value]="cl.client._id" *ngFor="let cl of clientsList">
                                    {{"["+cl.client.company_name+"] " +cl.client.prenom_client + " " +
                                    cl.client.nom_client}}</option>
                            </select>
                        </div>
                    </form>
                </div>
            </div>
            <div class="card-body">
                <div class="container">
                    <!-- <h5 class="card-title">Détail commande</h5> -->
                    <form class="" action="" method="post" onsubmit="return false;" (onsubmit)="addCommande(nameDest, adressDest, 
                        numTelDest, nom_prod, quantite, shpmtWeight, totalCmd);">
                        <!-- <div class="position-relative row form-group">
                    <legend for="client" class="space-bottom">L'expéditeur</legend>

                    <div class="col-sm-6">
                        <input name="nameCompany" id="nameCompany" type="text" disabled
                            class="form-control" [value]="client?.prenom_client">
                    </div>

                    <div class="col-sm-6">
                        <input name="nameExp" id="nameExp" type="text" disabled class="form-control"
                            [value]="client?.nom_client">
                    </div>
                </div> -->

                        <div class="position-relative row form-group">
                            <legend for="client" class="space-bottom">Client destinataire</legend>

                            <div class="col-sm-4">
                                <input name="nameDest" id="nameDest" type="text" class="form-control"
                                    placeholder="Nom de destinataire" #nameDest ngModel>
                                <mat-error style="color: red;font-weight: bold;" *ngIf="nameDest.value==''">
                                    Ajoutez le nom du client
                                </mat-error>
                            </div>

                            <div class="col-sm-4">
                                <input name="adressDest" id="adressDest" type="text" class="form-control"
                                    placeholder="Adresse du client" #adressDest ngModel>
                                <mat-error style="color: red;font-weight: bold;" *ngIf="adressDest.value==''">
                                    Ajoutez l'adresse du client
                                </mat-error>
                            </div>

                            <div class="col-sm-4">
                                <input name="numTelDest" id="numTelDest" type="number" class="form-control"
                                    placeholder="Numéro de téléphone" #numTelDest ngModel>
                                <mat-error style="color: red;font-weight: bold;"
                                    *ngIf="numTelDest.value=='' || numTelDest.value.length!=8">
                                    Ajoutez le numéro de téléphone du client
                                </mat-error>
                            </div>
                        </div>

                        <div class="position-relative row form-group">
                            <legend for="destination" class="space-bottom">Colis </legend>

                            <div class="col-sm-4">
                                <select class="form-control" (change)="selectVille($event)">
                                    <option [value]="ville" *ngFor="let ville of villes">
                                        {{ville}}</option>
                                </select>
                                <mat-error style="color: red;font-weight: bold;"
                                    *ngIf="selectedVille=='' || selectedVille=='Selectionnez une ville'">
                                    Ajoutez la ville d'adresse
                                </mat-error>
                            </div>


                        </div>

                        <div class="position-relative row form-group">
                            <legend for="client">Produits</legend>
                        </div>
                        <div class="position-relative row form-group">

                            <div class="col-sm-6">
                                <input type="text" class="form-control" placeholder="Nom du produit" name="nom_prod"
                                    id="nom_prod" #nom_prod ngModel>
                            </div>

                            <div class="col-sm-3">
                                <input type="number" class="form-control" placeholder="Quantité" name="quantite"
                                    id="quantite" #quantite ngModel>
                            </div>

                            <div class="col-sm-3">
                                <input type="number" class="form-control" placeholder="Volume" name="shpmtWeight"
                                    id="shpmtWeight" #shpmtWeight ngModel>
                            </div>


                            <!-- <div class="col-sm-1">
                                    <button type="button" (click)="deleteProductGroup(i)"
                                        class="mr-2 btn-icon btn-icon-only btn btn-danger"><i
                                            class="pe-7s-trash btn-icon-wrapper"> </i></button>
                                </div> -->

                        </div>
                        <div class="position-relative row form-group">
                            <div class="col-sm-4">
                                <input name="totalCmd" id="totalCmd" type="number" class="form-control" #totalCmd
                                    ngModel placeholder="Total de colis">
                            </div>

                            <div class="col-sm-4">
                                <select name="type_colis" id="type_colis" class="form-control" [(ngModel)]="type_colis">
                                    <option value="Selectionnez type de colis">
                                        Selectionnez type de colis</option>
                                    <option [value]="type" *ngFor="let type of typeColis">
                                        {{type}}</option>
                                </select>
                                <mat-error style="color: red;font-weight: bold;"
                                    *ngIf="type_colis=='' || type_colis=='Selectionnez type de colis'">
                                    Ajoutez la ville d'adresse
                                </mat-error>
                            </div>

                            <div class="col-sm-4">
                                <select name="currency" id="currency" class="form-control" [(ngModel)]="currency">
                                    <option value="Selectionnez devise">
                                        Selectionnez devise</option>
                                    <option [value]="curr" *ngFor="let curr of currencies">
                                        {{curr}}</option>
                                </select>
                                <mat-error style="color: red;font-weight: bold;"
                                    *ngIf="currency=='' || currency=='Selectionnez devise'">
                                    Ajoutez la ville d'adresse
                                </mat-error>
                            </div>

                        </div>
                        <!-- <div class="row">
                            <mat-error style="color: red;font-weight: bold;"
                                *ngIf="form.value.products.length == 0 || (form.value.products.length > 0 && !productsNotEmpty())">
                                Les produits sont invalid
                            </mat-error><br>
                        </div> -->
                        <!-- <div class="position-relative row form-group">
                            <button type="button" (click)="addNewProductGroup()" class="mt-1 btn btn-warning"><i
                                    class="pe-7s-plus"></i> Ajouter un
                                produit</button>
                        </div> -->

                        <div class="position-relative row form-check">
                            <div class="col-sm-10 offset-sm-10">
                                <button type="submit" (click)="addCommande(nameDest, adressDest, 
                                numTelDest, nom_prod, quantite, shpmtWeight, totalCmd)" class="btn btn-primary">Ajouter
                                colis</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>