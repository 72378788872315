import { Component, OnInit } from '@angular/core';
import { SacsService } from 'src/app/services/sacs/sacs.service';

@Component({
  selector: 'app-villes',
  templateUrl: './villes.component.html',
  styleUrls: ['./villes.component.css']
})
export class VillesComponent implements OnInit {

  
  villes = ["Selectionnez une ville",
  "Abengourou",
  "ABOBO",
  "ABOISSO",
  "ADIAKE",
  "ADJAME",
  "ADZOPE",
  "Adzope",
  "AFFERY",
  "AGBOVILLE",
  "Agboville",
  "AKOUPE",
  "ANYAMA",
  "AYAME",
  "AZAGUIE",
  "BASSAM",
  "BINGERVILLE",
  "BONGOUANOU",
  "BONOUA",
  "Bouafle",
  "BOUAKE",
  "COCODY",
  "COMMERCIAL",
  "DABOU",
  "Daloa",
  "Divo",
  "Duikeue",
  "EXPORT",
  "Gagnoa",
  "GRAND BASSAM",
  "GRAND LAHOU",
  "GUIGLO",
  "HERMANKONO",
  "Issa",
  "JACQUEVILLE",
  "KORHOGO",
  "Korogho",
  "KOUMASSI",
  "MAFERE",
  "MAN",
  "MARCORY",
  "MARKETING",
  "Meagui",
  "N'DOUCI",
  "NANO",
  "Odienne",
  "PK26",
  "PLATEAU",
  "PORT BOUET",
  "PROPRE MOYEN",
  "SAN PEDRO",
  "SIKENSI",
  "SINFRA",
  "SOUBRE",
  "Soubre",
  "TIASSALE",
  "Toumodi",
  "TREICHVILLE",
  "Vavoua",
  "Vente directe",
  "YAKASSE",
  "YAMOUSSOUKRO",
  "YOPOUGON"
  ];
  selectedVille: string = 'ALL';
  sacsListByVille: any = [];

  constructor(private SacsServices: SacsService) { }

  ngOnInit(): void {
    this.listSacs();
  }

  selectChangeVille(event) {
    this.selectedVille = event.target.value
  }
  async listSacs() {
    await this.SacsServices.allCities().then((res: any) => {
      if (res) {
        console.log("resssssssss : ")
        console.log(res)
        this.sacsListByVille = res;
      }
    });
  }
  searchSacByVille() {
    this.listSacs()
  }

  getCmdsBySacs(sacs) {
    let numberCmds = 0
    sacs.forEach(sac => {
      numberCmds += sac.commandes.length
    });
    return numberCmds
  }

}
