<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-car icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>
                <div class="page-title-subheading">État actuel de la station
                    <div class="badge badge-success">
                        {{store?.store_name}}</div>
                    <div class="page-title-subheading">{{store?.types_colis}}
                    </div>
                </div>
            </div>
        </div>
        <div class="page-title-actions" *ngFor="let prepa of store.prepas, let index = index">
            <!-- La liste des preparateurs :  -->
            <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                    <div class="widget-content-left mr-3">
                        <div class="widget-content-left">
                            <!-- <img width="40" class="rounded-circle"
                                src="assets/images/avatars/2.jpg" alt=""> -->
                            <ngx-avatar class="rounded-circle" name="{{prepa.nom_prenom_tracking}}">
                            </ngx-avatar>
                        </div>
                    </div>
                    <div class="widget-content-left flex2">
                        <div class="widget-heading">{{prepa.nom_prenom_tracking}}</div>
                        <div class="widget-subheading opacity-7">
                            <b>{{prepa.numTel_tracking}}</b>
                        </div>
                    </div>
                </div>
            </div>

            <br>
            <input hidden type="file" (change)="readExcel($event)" multiple="false" #file>
            <button type="button" (click)="file.click()" data-toggle="tooltip" title="Example Tooltip"
                data-placement="bottom" class="btn-shadow mr-3 btn btn-info">
                <i class="fa fa-star"> Intégrer mes colis au dépôt</i>
            </button>
            <!-- <div class="d-inline-block dropdown">
                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    class="btn-shadow dropdown-toggle btn btn-info">
                    <span class="btn-icon-wrapper pr-2 opacity-7">
                        <i class="fa fa-business-time fa-w-20"></i>
                    </span>
                    Buttons
                </button>
                <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu dropdown-menu-right">
                    <ul class="nav flex-column">
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-inbox"></i>
                                <span>
                                    Inbox
                                </span>
                                <div class="ml-auto badge badge-pill badge-secondary">86</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-book"></i>
                                <span>
                                    Book
                                </span>
                                <div class="ml-auto badge badge-pill badge-danger">5</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-picture"></i>
                                <span>
                                    Picture
                                </span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a disabled href="javascript:void(0);" class="nav-link disabled">
                                <i class="nav-link-icon lnr-file-empty"></i>
                                <span>
                                    File Disabled
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div> -->
        </div>
    </div>
</div>

<div class="row">
    <!-- <div class="col-md-6 col-xl-3" (click)="selectStatus(store?.pendingCmds, 'validated')">
        <div class="card mb-3 widget-content bg-warning">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-more text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Manifest</div>
                    <div class="widget-heading bg-danger" *ngFor="let type of store.types_colis, let index = index">{{type}}: {{getTypeColis(store?.pendingCmds ,type)}}</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{store?.pendingCmds?.length}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="col-md-6 col-xl-4" (click)="selectStatus(store?.inStoreCmds, 'transporting_depot')">
        <div class="card mb-3 widget-content bg-primary">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-home text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Au magasin</div>
                    <div class="widget-heading bg-danger" *ngFor="let type of store.types_colis, let index = index">
                        {{type}}: {{getTypeColis(store?.inStoreCmds ,type)}}</div>
                    <!-- <div class="widget-heading bg-danger">LV: {{getTypeColis(store?.inStoreCmds ,'LV')}}</div>
                    <div class="widget-heading bg-danger">HV: {{getTypeColis(store?.inStoreCmds ,'HV')}}</div> -->
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{store?.inStoreCmds?.length}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-4" (click)="selectStatus(store?.preparedCmds, 'prepared')">
        <div class="card mb-3 widget-content bg-info">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-home text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Prêt à la livraison</div>
                    <div class="widget-heading bg-danger" *ngFor="let type of store.types_colis, let index = index">
                        {{type}}: {{getTypeColis(store?.preparedCmds ,type)}}</div>
                    <!-- <div class="widget-heading bg-danger">LV: {{getTypeColis(store?.preparedCmds ,'LV')}}</div>
                    <div class="widget-heading bg-danger">HV: {{getTypeColis(store?.preparedCmds ,'HV')}}</div> -->
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{store?.preparedCmds?.length}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-4" (click)="getStore()">
        <div class="card mb-3 widget-content bg-danger">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-photo-gallery text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Tous les colis</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white">
                        <span>{{store?.preparedCmds?.length +
                            store?.inStoreCmds?.length}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Colis {{statusCmd[tagCmds]?.label}}
                <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button (click)="exportToExcel()" class="mr-2 btn-icon btn-icon-only btn btn-outline-success"><i
                                class="pe-7s-download btn-icon-wrapper"> Exporter statistique</i></button>
                        <div *ngIf="tagCmds!='all'">
                            <button class="active btn btn-info" (click)="filterByType(type)"
                                *ngFor="let type of store.types_colis, let index = index">{{type}}</button>
                        </div>

                        <!-- <button class="btn btn-dark" (click)="filterByType('LV')">LV</button>
                        <button class="btn btn-danger" (click)="filterByType('HV')">HV</button> 
                    
                    *ngIf="tagCmds!='all'" 

                    (click)="exportToExcel(datein, datefin)"
                    -->
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <div class="budget-price justify-content-center" *ngIf="listCmds?.length === 0">
                    <h2 class="text-center">Aucune colis </h2>
                </div>
                <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                    *ngIf="listCmds?.length > 0">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <!-- <th>Client</th> -->
                            <th class="text-left">Référence</th>
                            <th>Master</th>
                            <th class="text-center">Date Création</th>
                            <th class="text-center">Nom Client & Téléphone</th>
                            <th class="text-center">Ville</th>
                            <th class="text-center">Produits<br>
                                Quantité - Nom produit</th>
                            <th class="text-center">Status</th>
                            <th class="text-center">Total colis</th>
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let cmd of listCmds, let index = index">
                            <td class="text-center" [class]="(cmd?.type_cmd == 'FEDEX') ? 'fedex' : 'tnt'">
                                #<b>{{index+1}}</b></td>
                            <!-- <td>
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left mr-6">
                                            <div class="widget-content-left">
                                                <ngx-avatar class="rounded-circle" name="{{cmd?.client_cmd?.prenom_client}}
                                                {{cmd?.client_cmd?.nom_client}}">
                                                </ngx-avatar>
                                            </div>
                                        </div>
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading">{{cmd?.client_cmd?.prenom_client}}
                                                {{cmd?.client_cmd?.nom_client}}</div>
                                            <div class="widget-subheading opacity-7">
                                                {{cmd?.client_cmd?.company_name}}</div>
                                        </div>
                                    </div>
                                </div>
                            </td> -->
                            <td class="text">{{cmd?.qr_code}}<br><b
                                    [class]="(cmd?.type_cmd == 'FEDEX') ? 'text-fedex' : 'text-tnt'">{{cmd?.type_cmd}}</b>
                            </td>
                            <td class="text-center">{{cmd?.parent}}<br><b>{{cmd?.pkg_count}}</b></td>
                            <td class="text-center">{{cmd?.date_creation_cmd | date:'d MMM y'}}<br>
                                <b>{{cmd?.date_creation_cmd | date:'HH:mm'}}</b>
                            </td>
                            <td class="text-center">
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading">
                                                {{cmd?.user_destination?.nom_destination}}</div>
                                            <div class="widget-subheading opacity-7">
                                                {{cmd?.user_destination?.numtel_destination}}</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center">{{cmd?.user_destination?.adresse_destination}}
                                <br><strong>{{cmd?.destination_cmd}}</strong>
                            </td>
                            <td>
                                {{cmd?.pkg_count}} - {{cmd?.commodity_desc}}<br>
                                <b>{{cmd?.type_colis}}</b>
                            </td>
                            <td class="text-center">
                                <!-- <div class="badge badge-{{statusCmd[cmd?.etat_cmd]?.color}}">
                                    {{statusCmd[cmd?.etat_cmd]?.label}}</div> -->
                                <div *ngIf="cmd.etat_cmd != 'transporting_depot'"
                                    class="badge badge-{{statusCmd[cmd.etat_cmd].color}}">
                                    {{statusCmd[cmd?.etat_cmd]?.label}}</div>
                                <div *ngIf="cmd.etat_cmd == 'transporting_depot'"
                                    class="badge badge-{{statusCmd[cmd.etat_cmd].color}}">
                                    {{cmd?.store?.store_name}}</div>
                            </td>
                            <td class="text-center">{{cmd?.total_ca_cmd |
                                number:'0.2'}} {{cmd?.currency}}</td>
                            <td class="text-center">
                                <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                    <button class="btn-wide btn btn-info">Actions</button>
                                    <button type="button" ngbDropdownToggle
                                        class="dropdown-toggle-split dropdown-toggle btn btn-info"><span
                                            class="sr-only">Actions</span></button>
                                    <div ngbDropdownMenu>
                                        <button type="button" (click)="goToNewTab(cmd._id, '/detail-cmd')" tabindex="0"
                                            class="dropdown-item">
                                            <i class="metismenu-icon pe-7s-news-paper"
                                                style="margin-right: 10px;"></i>Détails
                                        </button>
                                        <button type="button" tabindex="0" (click)="goToNewTab(cmd._id, '/facture-cmd')"
                                            class="dropdown-item"><i class="metismenu-icon pe-7s-print"
                                                style="margin-right: 10px;"></i>Facture</button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>