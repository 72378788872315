import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(private http: HttpClient,
    private router: Router,) { }

  goToNewTab(idVirement, route) {
    const queryParams = {
      virement: idVirement
    };
    const url = this.router.serializeUrl(
      this.router.createUrlTree([route], {
        queryParams: queryParams,
        // relativeTo: this.activeRoute,
        // skipLocationChange: true
      })
    );

    window.open(url, '_blank');
  }
  subscribe(firstName, lastName, companyName, username, 
    zone, adress, numTel, genre, store, priority, deliveryTime) {
    return new Promise((slv) => {
      this.http.post(environment.apiAddClient, {
        companyName: companyName,
        lastname: lastName,
        firstname: firstName,
        username: username,
        zone: zone,
        numtel: numTel,
        adress: adress,
        genre: genre,
        store: store,
        priority: priority,
        deliveryTime: deliveryTime
      }).subscribe(
        (data: any) => {
          // console.log("data : ")
          // console.log(data)
          // if (data && data.response) {
            slv(data);
          // } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  addFrais(isClient, fraisInterne, fraisExterne, fraisRetour) {
    return new Promise((slv) => {
      this.http.post(environment.apiAddFraisClient, {
        client: isClient,
        fraisInterne: fraisInterne,
        fraisExterne: fraisExterne,
        fraisRetour: fraisRetour
      }).subscribe(
        (data: any) => {
          if (data && data.response) {
            slv(true);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async getFraisClient(idClient) {
    return await new Promise((slv) => {
      this.http.post(environment.apiGetFraisClient + "/" + idClient, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async getClients() {
    return await new Promise((slv) => {
      this.http.post(environment.apiAllClients, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async addAccountClient(total, account_exp, account_logistic, cmds) {
    console.log("addAccountClient : ")
    return await new Promise((slv) => {
      this.http.post(environment.apiAddAccountClient, {
        total: total,
        account_exp: account_exp,
        account_logistic: account_logistic,
        cmds: cmds
      }).subscribe(
        (data: any) => {
          console.log("dataaaaaaaa : ")
          console.log(data)
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(null);
        },
        (error) => {
          slv(null);
        }
      );
    });
  }

  async getClient(idClient) {
    return await new Promise((slv) => {
      this.http.post(environment.apiOneClient + "/" + idClient, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
  async getAccount(idVirement) {
    return await new Promise((slv) => {
      this.http.post(environment.apiOneAccountClient, { accountId: idVirement }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
  async getCmdsClient(idClient) {
    return await new Promise((slv) => {
      this.http.post(environment.apiAllCmdsByClient, { idClient: idClient }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async searchClient(client) {
    return await new Promise((slv) => {
      this.http.post(environment.apiSearchClient, { client: client }).subscribe(
        (data: any) => {
          // console.log("dataaaaaaaaaaa : ")
          // console.log(data)
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async affecteCollecteur(idClient, idCollector, hourPickUp) {
    return await new Promise((slv) => {
      this.http.post(environment.apiAffectCollector, { client: idClient, collector: idCollector, hourPickUp }).subscribe(
        (data: any) => {
          // console.log("afffect collector : ")
          // console.log(data)
          if (data && data.response) {
            slv(data.response);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async allCmdsNotPaied(idClient) {
    return await new Promise((slv) => {
      this.http.post(environment.apiCmdsNotPaied, { client: idClient }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async allCmdsNotReturned(idClient) {
    return await new Promise((slv) => {
      this.http.post(environment.apiCmdsNotReturned, { client: idClient }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async allCmdsNotPaiedNotReturned() {
    return await new Promise((slv) => {
      this.http.post(environment.apiCmdsNotPaiedAndNotReturned, {}).subscribe(
        (data: any) => {
          // console.log("allCmdsNotPaiedNotReturned")
          // console.log(data)
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async demandesClient() {
    return await new Promise((slv) => {
      this.http.post(environment.apiDemandesClient, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async accountsClient() {
    return await new Promise((slv) => {
      this.http.post(environment.apiAccountsClient, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
}
