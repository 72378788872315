<!-- <app-image-viewer [imageURL]="imageURL" [open]="viewerOpen"
                                    (close)="viewerOpen = false"></app-image-viewer> -->

<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-note2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Transporteur
                <div class="page-title-subheading">Détail du transporteur.
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="tab-content" id="tab-content">
                <div class="main-card mb-12 card">
                    <div class="card-body">
                        <div id="commande" #commande>
                            <div class="row">
                                <div class="col-md-7">
                                    <!-- <div class="text space">
                                        <h4><strong>Transporteur</strong></h4>
                                    </div> -->
                                </div>
                                <div class="col-md-5 text-md-right">
                                    <div
                                        [class]="(transporter.isValidate) ? 'badge badge-success' : 'badge badge-danger'">
                                        {{(transporter.isValidate) ? 'Validé' : 'Non validé'}}
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-7">
                                    <strong>Transporteur:</strong><br />
                                    {{transporter.user_transportor?.prenom_user + " " +
                                    transporter?.user_transportor?.nom_user}}<br />
                                    {{transporter.user_transportor?.email_user}}<br />
                                    {{transporter.user_transportor?.tel_user}}<br /><br />
                                    <!-- {{transporter.destination_cmd}}<br /> -->

                                    <strong>Date de l'inscription:</strong><br />
                                    {{transporter?.user_transportor?.dateinscrip_user | date:'dd/MM/yyyy'}}<br />
                                    <div [class]="(transporter.onLine) ? 'badge badge-success' : 'badge badge-danger'">
                                        {{(transporter?.onLine) ? 'En ligne' : 'Hors ligne'}}
                                    </div>
                                    <!-- <div class="text space">
                                        <qrcode print [width]="150" usesvg="true" [elementType]="'svg'"
                                            [qrdata]="cmd.qr_code" [errorCorrectionLevel]="'M'">
                                        </qrcode>
                                    </div> -->
                                </div>
                                <div class="col-md-5 text-md-right">
                                    <img width="170"
                                        src="{{env.transportorUsersAssetsBaseURL + transporter?.user_transportor?.profile_user}}"
                                        alt=""
                                        (click)="viewerOpenImage(env.transportorUsersAssetsBaseURL + transporter?.user_transportor?.profile_user)">
                                </div>
                            </div>

                            <div class="clearfix"></div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="section-title">Carte d'identité</div>
                                            <p class="section-lead">
                                                N° carte d'identé
                                                <br />→ {{transporter?.id_card?.num_id_card}}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 text-center">
                                            <img width="170"
                                                src="{{env.cardTransportorAssetsBaseURL + transporter?.id_card?.recto}}"
                                                alt=""
                                                (click)="viewerOpenImage(env.cardTransportorAssetsBaseURL + transporter?.id_card?.recto)">
                                        </div>
                                        <div class="col-6 text-center">
                                            <img width="170"
                                                src="{{env.cardTransportorAssetsBaseURL + transporter?.id_card?.verso}}"
                                                alt=""
                                                (click)="viewerOpenImage(env.cardTransportorAssetsBaseURL + transporter?.id_card?.verso)">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="clearfix"></div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="section-title">Permis de conduire</div>
                                            <p class="section-lead">
                                                N° de permis
                                                <br />→ {{transporter?.driving_license?.num_driving_license}}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 text-center">
                                            <img width="170"
                                                src="{{env.driverTransportorAssetsBaseURL + transporter?.driving_license?.recto}}"
                                                alt=""
                                                (click)="viewerOpenImage(env.driverTransportorAssetsBaseURL + transporter?.driving_license?.recto)">
                                        </div>
                                        <div class="col-6 text-center">
                                            <img width="170"
                                                src="{{env.driverTransportorAssetsBaseURL + transporter?.driving_license?.verso}}"
                                                alt=""
                                                (click)="viewerOpenImage(env.driverTransportorAssetsBaseURL + transporter?.driving_license?.verso)">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="clearfix"></div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="section-title">Infos véhicule</div>
                                            <p class="section-lead">
                                                Type de véhicule
                                                <br />→ {{transporter?.info_vehicule?.type_camion}}
                                            </p>
                                            <p class="section-lead">
                                                Plaque d'immatriculation
                                                <br />→ {{transporter?.info_vehicule?.immatricule.part1}} Tun
                                                {{transporter?.info_vehicule?.immatricule.part2}}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 text-center">
                                            <img width="170"
                                                src="{{env.vehiculeTransportorAssetsBaseURL + transporter?.info_vehicule?.photo_vehicule}}"
                                                alt=""
                                                (click)="viewerOpenImage(env.vehiculeTransportorAssetsBaseURL + transporter?.info_vehicule?.photo_vehicule)">
                                        </div>
                                        <div class="col-4 text-center">
                                            <img width="170"
                                                src="{{env.vehiculeTransportorAssetsBaseURL + transporter?.info_vehicule?.grise_recto}}"
                                                alt=""
                                                (click)="viewerOpenImage(env.vehiculeTransportorAssetsBaseURL + transporter?.info_vehicule?.grise_recto)">
                                        </div>
                                        <div class="col-4 text-center">
                                            <img width="170"
                                                src="{{env.vehiculeTransportorAssetsBaseURL + transporter?.info_vehicule?.grise_verso}}"
                                                alt=""
                                                (click)="viewerOpenImage(env.vehiculeTransportorAssetsBaseURL + transporter?.info_vehicule?.grise_verso)">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="clearfix"></div>

                            <div class="row" *ngIf="transporter?.patent != null">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="section-title">Carte d'identification fiscale</div>
                                            <p class="section-lead">
                                                Numéro de carte d'identification fiscale
                                                <br />→ {{transporter?.patent?.num_patent}}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <img width="170"
                                                src="{{env.patentTransportorAssetsBaseURL + transporter?.patent?.patent_card}}"
                                                alt=""
                                                (click)="viewerOpenImage(env.patentTransportorAssetsBaseURL + transporter?.patent?.patent_card)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="d-block text-center card-footer">
                        <button
                            [class]="(transporter?.is_blocked != null && transporter?.is_blocked==true) ? 'btn btn-danger float-left' : 'btn btn-success float-left'"
                            (click)="blockTransportor()"><i class="metismenu-icon pe-7s-back"></i>
                            {{(transporter?.is_blocked != null && transporter?.is_blocked==true) ? 'Débloqué le
                            transporteur' : 'Bloqué le transporteur'}}
                        </button>

                        <button class="btn btn-success float-right text-white" (click)="validateTransportor()"><i
                                class="metismenu-icon pe-7s-check text-white"></i> Valider</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>