import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DetailsVirementComponent } from 'src/app/components/shared/dialogs/details-virement/details-virement.component';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';
import { UsersTrackingService } from 'src/app/services/users-tracking/users-tracking.service';

@Component({
  selector: 'app-virements-clients',
  templateUrl: './virements-clients.component.html',
  styleUrls: ['./virements-clients.component.css']
})
export class VirementsClientsComponent implements OnInit {

  // clientsList: any = []
  idClient: string = null
  listVirements: any = []
  selectedVirement: number = -1

  constructor(private cmdsService: CommandesService,
    private clientService: ClientsService,
    private route: ActivatedRoute,
    private trakingServices: UsersTrackingService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    // this.listClients()
    this.getVirrementClients()
  }
  selectClient(event: any) {
    // console.log(event.target.value)
    if (event.target.value == "Selectionnez le client") {
      this.idClient = null;
    } else {
      this.idClient = event.target.value;
    }
  }
  // async listClients() {
  //   await this.clientService.getClients().then((res: any) => {
  //     console.log(res)
  //     if (res) {
  //       this.clientsList = res;
  //       // this.clientsList.splice(0, 0, "Selectionnez le client");
  //     }
  //   });
  // }
  livredCmd(cmds) {
    var array= cmds.filter(cmd => cmd.etat_cmd == "paied");
    return array.length
  }
  returnedCmd(cmds) {
    var array= cmds.filter(cmd => cmd.etat_cmd == "returned");
    return array.length
  }
  async getVirrementClients() {
    // if(this.idClient != null) {
      await this.clientService.accountsClient()
      .then((res: any) => {
        // console.log("resssssssss : ")
        // console.log(res)
        if (res) {
          this.listVirements = res
          //location.href = '/sacs';
          //this.sacsList = res;
        }
      });
    // } else {
    //   this.listVirements = []
    // }
  }

  async showVirement(virement, index) {
    window.scrollTo(0, 0);
    // console.log("amineeeeeeeeee")
    this.selectedVirement = index
    const confirmDialog = this.dialog.open(DetailsVirementComponent, {
      data: {
        virement: virement,
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
      }
    });

  }

  goToNewTab(idVirement, route, index) {
    this.selectedVirement = index
    this.clientService.goToNewTab(idVirement, route)
  }
}
