import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { interval, Subscription } from 'rxjs';
import { TransportorsService } from 'src/app/services/transportors/transportors.service';

@Component({
  selector: 'app-positions-transportors',
  templateUrl: './positions-transportors.component.html',
  styleUrls: ['./positions-transportors.component.css']
})
export class PositionsTransportorsComponent implements OnInit {

  subscription: Subscription;

  @ViewChildren(MapInfoWindow) infoWindowsView: QueryList<MapInfoWindow>;

  mapOptions: google.maps.MapOptions = {
    center: { lat: 36.841177, lng: 10.211049 },
    zoom: 10
  }
  // marker = {
  //   position: { lat: 36.841177, lng: 10.211049 },
  // }

  markers: any = []

  constructor(private transServices: TransportorsService) { }

  ngOnInit(): void {
    this.markersTransportors()

    const source = interval(5000);
    this.subscription = source.subscribe(val => {
      this.markersTransportors()
    });
  }

  async markersTransportors() {
    await this.transServices.getMarkerTransportors().then((res: any) => {
      if (res) {
        // console.log("resssssssss : ")
        // console.log(res)
        this.markers = res;
      }
    });
  }

  openInfoWindow(marker: MapMarker, windowIndex: number) {
    /// stores the current index in forEach
    let curIdx = 0;
    this.infoWindowsView.forEach((window: MapInfoWindow) => {
      if (windowIndex === curIdx) {
        window.open(marker);
        curIdx++;
      } else {
        curIdx++;
      }
    });
  }

}
