import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersTrackingService } from 'src/app/services/users-tracking/users-tracking.service';

@Component({
  selector: 'app-affectation-dialog',
  templateUrl: './affectation-dialog.component.html',
  styleUrls: ['./affectation-dialog.component.css']
})
export class AffectationDialogComponent implements OnInit {

  leadersList: any = [];
  // selectedUser: any = '';

  constructor(public dialogRef: MatDialogRef<AffectationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private trackingServices: UsersTrackingService) { }

  ngOnInit(): void {
    this.listLouagistes()
  }

  selectChangeHandler(event: any) {
    //update the ui
    // this.selectedUser = event.target.value;
    this.data.user = event.target.value
    // console.log("event.target.value")
    // console.log(event.target.value)
  }

  async listLouagistes() {
    await this.trackingServices.getLouagistesByCity().then((res: any) => {
      if (res) {
        // console.log("leader res");
        // console.log(res);
        this.leadersList = res;
      }
    });
  }

}
