import { Component, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { TransportorsService } from 'src/app/services/transportors/transportors.service';

@Component({
  selector: 'app-demandes-transportors',
  templateUrl: './demandes-transportors.component.html',
  styleUrls: ['./demandes-transportors.component.css']
})
export class DemandesTransportorsComponent implements OnInit {
  
  subscription: Subscription;

  allDemandes: any = [];

  statusDemandes: any = [];
  status = ["Selectionnez status", "created", "accepted", "started", "arrived", "paied", "finish", "canceled"];
  selectedStatus: string = null  
  
  currentPage: number = 0
  totalPages: number = 1
  pages: Array<number> = [];
  pagesDisplaied: Array<number> = [];

  constructor(private transServices: TransportorsService) { }

  ngOnInit(): void {
    this.transServices.getLabeledStatusDemande().then(
      (lst) => {
        this.statusDemandes = lst
      }
    )

    this.listDemandes()

    // const source = interval(10000);
    // this.subscription = source.subscribe(val => {
    //   this.listDemandes()
    // });
  }

  splicePages() {
    // console.log("currentPage : ")
    //     console.log(this.currentPage)
    if(this.pages.length > 10) {
      // if(this.currentPage < this.pages.length-10) {
      //   this.pagesDisplaied = this.pages.splice(this.currentPage, 10);
      //   console.log("if 1 pagesDisplaied : ")
      //   console.log(this.pagesDisplaied)
      // } else 
      if(this.currentPage < 5) {
        this.pagesDisplaied = this.pages.splice(0, 10);
        // console.log("if 1 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else if(this.currentPage >= 5 && this.currentPage < this.pages.length-5) {
        this.pagesDisplaied = this.pages.splice(this.currentPage-4, 10);
        // console.log("if 2 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else {
        this.pagesDisplaied = this.pages.splice(this.pages.length-10, 10);
        // console.log("else pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      }
    } else {
      this.pagesDisplaied = this.pages
    }
  }

  async listDemandes(name?, numero?, datein?, datefin?) {
    await this.transServices.demandesTransportors(this.currentPage, this.selectedStatus, 
          name, numero, datein, datefin).then((res: any) => {
      if (res) {
        // console.log("resssssssss : ")
        // console.log(res)
        this.allDemandes = res.items;
        this.totalPages = res.totalPages
        this.currentPage = res.currentPage
        this.pages = Array.from(Array(res.totalPages)).map((x, i) => i )
        this.splicePages()
      }
    });
  }

  goToNewTab(idDriver, route) {
    this.transServices.goToNewTab(idDriver, route)
  }

  selectChangeStatus(event: any) {
    if (event.target.value == "Selectionnez status") {
      this.selectedStatus = null
    } else {
      this.selectedStatus = event.target.value;
    }
  }

  async filterDemandes(name, numero, datein, datefin) {
    // console.log()
    this.pages = []
    this.pagesDisplaied = []
    this.currentPage = 0
    this.listDemandes(name.value, numero.value, datein.value, datefin.value);
  }

  nextPage(name, numero, datein, datefin) {
    this.currentPage++
    this.listDemandes(name.value, numero.value, datein.value, datefin.value);
  }

  previousPage(name, numero, datein, datefin) {
    this.currentPage--
    this.listDemandes(name.value, numero.value, datein.value, datefin.value);
  }

  selectPage(page, name, numero, datein, datefin) {
    this.currentPage = page
    this.listDemandes(name.value, numero.value, datein.value, datefin.value);
  }
}
