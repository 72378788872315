import { Component, OnInit } from '@angular/core';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-list-products',
  templateUrl: './list-products.component.html',
  styleUrls: ['./list-products.component.css']
})
export class ListProductsComponent implements OnInit {

  allPrd: any = []

  constructor(private cmdsService: CommandesService) { }

  ngOnInit(): void {
    this.listPrds()
  }

  async listPrds() {
    await this.cmdsService.listPrds().then((res: any) => {
      console.log(res)
      if (res) {
        this.allPrd = res;
      }
    });
  }

}
