import { Component, OnInit } from '@angular/core';
import { TransportorsService } from 'src/app/services/transportors/transportors.service';

@Component({
  selector: 'app-invalid-return',
  templateUrl: './invalid-return.component.html',
  styleUrls: ['./invalid-return.component.css']
})
export class InvalidReturnComponent implements OnInit {

  allReturn: any = []

  constructor(private transServices: TransportorsService,) { }

  ngOnInit(): void {
    this.listReturns()
  }

  async listReturns() {
    await this.transServices.getReturnsValid("invalid").then((res: any) => {
      if (res) {
        this.allReturn = res;
      }
    });
  }

  async updateReturn(idEmptyReturn) {
    await this.transServices.updateEmptyReturn(idEmptyReturn, "valid").then((res: any) => {
      // console.log("resss update")
      // console.log(res)
      if (res) {
        this.listReturns()
      }
    });
  }

}
