import { Component, OnInit } from '@angular/core';
import { TeamsService } from 'src/app/services/teams-wassali/teams.service';

@Component({
  selector: 'app-add-user-fedex',
  templateUrl: './add-user-fedex.component.html',
  styleUrls: ['./add-user-fedex.component.css']
})
export class AddUserFedexComponent implements OnInit {

  allProfiles: any = ["simple_user", "stock", "planner", "validator", "admin"]
  profiles: any = []

  imageUrl: string = '';
  fileToUpload: File = null;

  constructor(private teamsService: TeamsService) { }

  ngOnInit(): void {
  }

  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  async addUserPH(first_name, email_user, last_name, tel_user,
    password_user, confirme_password_user) {
    if (last_name.value != '' && first_name.value != '' && tel_user.value != '' &&
      email_user.value != '' && password_user.value != '' &&
      password_user.value == confirme_password_user.value && this.profiles.length > 0) {

      // const formData: any = new FormData();

      // if (this.fileToUpload != null) {
      //   formData.append("profile", this.fileToUpload, this.fileToUpload['name']);
      // }

      var newUserPH = {
        nom: last_name.value,
        prenom: first_name.value,
        numtel: tel_user.value,
        username: email_user.value,
        password: password_user.value,
        profiles: this.profiles
      }

      // for (var key in newUserPH) {
      //   formData.append(key, newUserPH[key]);
      // }

      await this.teamsService.addUserFX(newUserPH).then((res: any) => {
        // console.log("res")
        // console.log(res)
        if (res && res.response) {
          alert('Utilisateur est ajouté avec succés');
          location.href = '/teams-fedex';
        } else {
          alert(res.msg);
        }
      }).catch((e) => { console.log(e) });
    } else {
      alert('Données utilisateurs est invalide');
    }

  }

}
