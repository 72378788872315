<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                    <div class="widget-content-left mr-3">
                        <div class="widget-content-left">
                            <ngx-avatar class="rounded-circle" name="{{client.prenom_client}}
                            {{client.nom_client}}">
                            </ngx-avatar>
                        </div>
                    </div>
                    <div class="widget-content-left flex2">
                        <div class="widget-heading">{{client.prenom_client}}
                            {{client.nom_client}}</div>
                        <div class="widget-subheading opacity-7">{{client.company_name}}</div>
                    </div>
                </div>
            </div>

            <!-- <div class="page-title-icon">
                <i class="pe-7s-users icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Commandes
                <div class="page-title-subheading">La liste des commandes du client xxxx.
                </div>
            </div> -->
        </div>
        <!-- <div class="page-title-actions">
            <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                class="btn-shadow mr-3 btn btn-dark">
                <i class="fa fa-star"></i>
            </button>
            <div class="d-inline-block dropdown">
                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    class="btn-shadow dropdown-toggle btn btn-info">
                    <span class="btn-icon-wrapper pr-2 opacity-7">
                        <i class="fa fa-business-time fa-w-20"></i>
                    </span>
                    Buttons
                </button>
                <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu dropdown-menu-right">
                    <ul class="nav flex-column">
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-inbox"></i>
                                <span>
                                    Inbox
                                </span>
                                <div class="ml-auto badge badge-pill badge-secondary">86</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-book"></i>
                                <span>
                                    Book
                                </span>
                                <div class="ml-auto badge badge-pill badge-danger">5</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-picture"></i>
                                <span>
                                    Picture
                                </span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a disabled href="javascript:void(0);" class="nav-link disabled">
                                <i class="nav-link-icon lnr-file-empty"></i>
                                <span>
                                    File Disabled
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div> -->
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Colis
                <!-- <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button class="active btn btn-focus">Last Week</button>
                        <button class="btn btn-focus">All Month</button>
                    </div>
                </div> -->
            </div>
            <div class="table-responsive">
                <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th class="text-left">Référence</th>
                            <th class="text-center">Date Création</th>
                            <th class="text-center">Nom Client & Téléphone</th>
                            <th class="text-center">Ville</th>
                            <th class="text-center">Produits<br>
                                Quantité - Nom produit</th>
                            <th class="text-center">Status</th>
                            <th class="text-center">Total<br>Quantité</th>
                            <th class="text-center">Total<br>Volume</th>
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let cmd of cmdList, let index = index">
                            <td class="text-center tnt">#<b>{{index+1}}</b></td>
                            <!-- <td>
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left mr-3">
                                            <div class="widget-content-left">
                                                <ngx-avatar class="rounded-circle" name="{{client.prenom_client}}
                                                {{client.nom_client}}">
                                                </ngx-avatar>
                                            </div>
                                        </div>
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading">{{client.prenom_client}}
                                                {{client.nom_client}}</div>
                                            <div class="widget-subheading opacity-7">{{client.company_name}}</div>
                                        </div>
                                    </div>
                                </div>
                            </td> -->
                            <td class="text">{{cmd?.tra_ref_id}}</td>
                            <td class="text-center">{{cmd.date_creation_cmd | date:'d MMM y'}}<br>
                                <strong>{{cmd.date_creation_cmd | date:'HH:mm'}}</strong>
                            </td>
                            <td class="text-center">
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading">
                                                {{cmd?.client_cmd?.prenom_client + " " +
                                                cmd?.client_cmd?.nom_client}}</div>
                                            <div class="widget-subheading opacity-7">
                                                {{cmd?.client_cmd?.username_client}}
                                            </div>
                                            <div class="widget-subheading opacity-7">
                                                {{cmd?.client_cmd?.numTel_client}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center">
                                {{cmd?.client_cmd?.adresse_client}}<br>
                                <strong>{{cmd?.client_cmd?.zone_client}}</strong>
                            </td>
                            <td>
                                <div *ngFor="let prd of cmd?.products?.prdts, let index = index">
                                    {{prd?.quantite}} - {{prd?.prd_id?.item_name}}<br>
                                </div>
                                <!-- <b>{{cmd?.type_colis}}</b> -->
                            </td>
                            <td class="text-center">
                                <div class="badge badge-{{statusCmds[cmd?.etat_cmd]?.color}}">
                                    {{statusCmds[cmd?.etat_cmd]?.label}}</div>
                            </td>
                            <td class="text-center">
                                <span class="badge badge-pill badge-danger">
                                    {{cmd?.products?.total_qt}}
                                </span>
                            </td>
                            <td class="text-center">
                                <span class="badge badge-pill badge-danger">
                                    {{cmd?.products?.total_vm | number:'0.2'}} m³
                                </span>
                            </td>
                            <td class="text-center">
                                <!-- <button [routerLink]="['/detail-cmd']"
                                    [queryParams]="{ cmd: cmd._id }" type="button"  
                                    id="PopoverCustomT-1"
                                    class="btn btn-primary btn-sm">Détails</button>
                                <button [routerLink]="['/facture']"
                                    [queryParams]="{ cmd: cmd._id }" type="button"
                                    id="PopoverCustomT-1"
                                    class="btn btn-primary btn-sm">Facture</button> -->
                                <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                    <button class="btn-wide btn btn-info">Actions</button>
                                    <button type="button" ngbDropdownToggle
                                        class="dropdown-toggle-split dropdown-toggle btn btn-info"><span
                                            class="sr-only">Actions</span></button>
                                    <div ngbDropdownMenu>
                                        <button type="button" (click)="goToNewTab(cmd._id, '/detail-cmd')" tabindex="0"
                                            class="dropdown-item">
                                            <i class="metismenu-icon pe-7s-news-paper"
                                                style="margin-right: 10px;"></i>Détails
                                        </button>
                                        <button type="button" tabindex="0" (click)="goToNewTab(cmd._id, '/facture-cmd')"
                                            class="dropdown-item"><i class="metismenu-icon pe-7s-print"
                                                style="margin-right: 10px;"></i>Facture</button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="d-block text-center card-footer">
                <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                        class="pe-7s-trash btn-icon-wrapper"> </i></button>
                <button class="btn-wide btn btn-success">Save</button>
            </div> -->
        </div>
    </div>
</div>