import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommandesService } from 'src/app/services/commandes/commandes.service';
import { StoresService } from 'src/app/services/stores/stores.service';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import { EnterCommandesStoreComponent } from 'src/app/components/shared/dialogs/enter-commandes-store/enter-commandes-store.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-details-store',
  templateUrl: './details-store.component.html',
  styleUrls: ['./details-store.component.css']
})
export class DetailsStoreComponent implements OnInit {

  idStore: string = null
  tagCmds: string = "all"
  store: any = {}
  statusCmd: any = []
  listCmds: any = []

  cmds: any = []
  @ViewChild('file')
  myfileImport: ElementRef;

  constructor(private cmdsService: CommandesService,
    private storesService: StoresService,
    private route: ActivatedRoute,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.cmdsService.getLabeledStatus().then(
      (lst) => {
        this.statusCmd = lst
      }
    )

    this.route.queryParams.subscribe(async (params) => {
      this.idStore = params.store;
      this.getStore()
    });
  }

  async getStore() {
    await this.storesService.getStore(this.idStore).then((res: any) => {
      if (res) {
        // console.log("store res");
        // console.log(res);
        this.tagCmds = "all"
        this.store = res;
        // this.listCmds.push(...res.pendingCmds)
        this.listCmds = []
        this.listCmds.push(...res.inStoreCmds)
        this.listCmds.push(...res.preparedCmds)
      }

    });
  }

  selectStatus(array, tag) {
    this.tagCmds = tag
    this.listCmds = array
  }

  getTypeColis(cmds: any, type) {
    if (cmds != null && cmds.length > 0) {
      var array = cmds.filter(cmd => cmd.type_colis.includes(type));
      return array.length
    }
    return 0
  }

  filterByType(type) {
    /*if(this.tagCmds == 'validated'){
      this.listCmds = this.store.pendingCmds.filter(cmd => cmd.type_colis.includes(type));
    } else */if (this.tagCmds == 'transporting_depot') {
      this.listCmds = this.store.inStoreCmds.filter(cmd => cmd.type_colis.includes(type));
    } else if (this.tagCmds == 'prepared') {
      this.listCmds = this.store.preparedCmds.filter(cmd => cmd.type_colis.includes(type));
    }

  }

  goToNewTab(idCmd, route) {
    this.cmdsService.goToNewTab(idCmd, route)
  }

  readExcel(event) {

    const workbook = new Excel.Workbook();
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }

    /**
     * Final Solution For Importing the Excel FILE
     */
    const header = [] //["nameDest", "adressDest", "numTelDest", "destination", "nom_prod", "quantite", "prix_unitaire"]

    const arryBuffer = new Response(target.files[0]).arrayBuffer();
    arryBuffer.then((data) => {
      workbook.xlsx.load(data)
        .then(async () => {

          // play with workbook and worksheet now
          // console.log(workbook);
          const worksheet = workbook.getWorksheet(1);
          // console.log('rowCount: ', worksheet.rowCount);

          const promises = [];
          worksheet.eachRow((row, rowNumber) => {
            // console.log('Row: ' + rowNumber + ' Value: ' + row.values);
            // console.log('Row ' + rowNumber + ' = ' + JSON.stringify(row.values));
            let cmd = {}
            if (rowNumber == 1) {
              row.eachCell((cell, colNumber) => {
                // console.log('Cell ' + colNumber + ' , ' + header[colNumber - 1] + ' = ' + cell.value);
                header.push(cell.value.trim())
              });

            } else {

              // const promise = db.insert(row); // <-- whatever async operation you have here
              row.eachCell((cell, colNumber) => {
                // console.log('Cell ' + colNumber + ' , ' + header[colNumber - 1] + ' = ' + cell.value);
                cmd[header[colNumber - 1]] = cell.value
              });
              promises.push({ cmd: cmd, msg: null });
              // this.cmds.push(cmd)
              // return cmd
            }
          });
          this.cmds = await Promise.all(promises)
          // console.log("all cmds : ")
          // console.log(this.cmds)

          let promisess: Promise<any>[] = [];
          this.cmds.forEach(item => {
            let promise: Promise<any> = this.enterCommande(item).then((value: any) => {
              return { cmd: item.cmd, msg: value };
            });

            promisess.push(promise);
          });

          Promise.all(promisess)
            .then(async (results) => {
              // console.log("promiseeesssssssssssssssssssssss : ")
              // console.log(results)
              this.cmds = await results.filter(item => item.msg != null && item.msg.response == false);
              // console.log("this.cmdssssssss : ")
              // console.log(this.cmds)
              this.showCommandes(this.cmds)
            })
        });
    });

    this.myfileImport.nativeElement.value = "";
  }

  async showCommandes(cmds) {
    window.scrollTo(0, 0);
    // console.log("amineeeeeeeeee")
    // this.selectedVirement = index
    const confirmDialog = this.dialog.open(EnterCommandesStoreComponent, {
      data: {
        commandes: cmds,
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        // this.listCmdsClient(this.statusCmds, this.isPaied)
      }
    });

  }

  enterCommande(cmd) {
    // console.log("itemmmmmmmmmmm")
    // console.log(cmd)
    // console.log(cmd.cmd["Tracking Number"].result)
    let promise = new Promise((resolve, reject) => {
      this.cmdsService.enterCmdInStore(
        (cmd.cmd["Tracking Number"].hasOwnProperty("result")) 
              ? cmd.cmd["Tracking Number"].result 
              : cmd.cmd["Tracking Number"], 
          this.idStore
    /*cmd.quantite * cmd.prix_unitaire, products*/)
        .then((res: any) => {
          // console.log("resssssssss : ")
          // console.log(res)
          if (res) {
            resolve(res);
          }
        });
    });
    return promise
  }

  exportToExcel() {

    // this.cmdList.forEach((row: any) => {
    //   this.dataForExcel.push(Object.values(row))
    // })

    let reportData = {
      title: (this.tagCmds == "all") 
      ? ("Tous les colis - magasin " + this.store.store_name) 
      : ('Les Colis ' + this.statusCmd[this.tagCmds]?.label + " - magasin " + this.store.store_name),
      data: this.listCmds,
      headers: ["#", "Référence", "Date Création", "Nom Client & Téléphone", "Ville", "Zone", "Status", "Quantité", "Volume"], // Object.keys(this.cmdList[0])
      etatCmd: (this.tagCmds == "all") 
      ? "Tous Colis" : this.statusCmd[this.tagCmds]?.label,
      startDate: null,
      endDate: null,
      storeName: this.store.store_name,
      statusCmd: this.statusCmd
    }

    this.cmdsService.exportExcel(reportData);
  }
}
