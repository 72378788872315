<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-box2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Détail
                <div class="page-title-subheading">Détail du tourné.
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                class="btn-shadow mr-3 btn btn-danger" [routerLink]="['/bon-achat-sac']"
                [queryParams]="{ sac: sac._id }">
                <i class="fa fa-star"> Etat</i>
            </button>
        </div>
    </div>
</div>
<div class="tab-content">
    <div class="main-card mb-12 card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-7">
                    <div class="text space">
                        <h4><strong>Tourné : {{sac.ref_sac}}</strong></h4>
                        <div class="my-google-map" id="marker" #marker>
                            <google-map [options]="mapOptions" width="100%" [height]="500">

                                <map-marker #marker="mapMarker" *ngFor="let m of sac.commandes; let i = index"
                                    [position]="{ lat: m.latitude, lng: m.longitude }"
                                    [options]="{icon: (true) ? 'http://maps.google.com/mapfiles/ms/icons/green-dot.png' : 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'}"
                                    (mapClick)="openInfoWindow(marker, i)">

                                    <map-info-window>
                                        <!-- <img width="40" height="40" class="rounded-circle"
                                            src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + m?.user_transportor?.profile_user}}"
                                            alt=""><br> -->
                                        <strong>{{m?.nom_destination}}</strong><br>
                                        <strong>{{m?.numtel_destination}}</strong>
                                    </map-info-window>
                                </map-marker>

                                <map-marker #marker="mapMarker" *ngFor="let m of sac.pickupClient; let i = index"
                                    [position]="{ lat: m.latitude, lng: m.longitude }"
                                    [options]="{icon: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'}"
                                    (mapClick)="openInfoWindow(marker, i)">

                                    <map-info-window>
                                        <!-- <img width="40" height="40" class="rounded-circle"
                                            src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + m?.user_transportor?.profile_user}}"
                                            alt=""><br> -->
                                        <strong>{{m?.nom_destination}}</strong><br>
                                        <strong>{{m?.numtel_destination}}</strong>
                                    </map-info-window>
                                </map-marker>
                            </google-map>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 text-md-right" id="qrcode" #qrcode>
                    <div class="text-center space">
                        <h2><strong>{{sac.ref_sac + ": " + sac.destination_sac}}</strong></h2>
                        <qrcode print [width]="400" usesvg="true" [elementType]="'svg'" [qrdata]="sac._id" [margin]=0>
                        </qrcode>
                    </div>
                </div>
                <div class="col-md-12">
                    <button class="btn btn-danger float-left" (click)="generatePDF('marker')"><i
                            class="fas fa-print"></i> Imprimer Map</button>

                    <button class="btn btn-warning float-right text-white" (click)="generatePDF('qrcode')"><i
                            class="fas fa-print"></i> Imprimer tourné</button>


                    <!-- <button class="btn btn-warning btn-icon icon-left"><i
                            class="fas fa-print"></i>
                        Imprimer
                    </button> -->
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="main-card mb-3 card">
                        <div class="card-header" *ngIf="sac?.pickupClient?.length > 0">List des pickup clients
                            <!-- <div class="btn-actions-pane-right">
                                <div role="group" class="btn-group-sm btn-group">
                                    <button class="active btn btn-focus">Last Week</button>
                                    <button class="btn btn-focus">All Month</button>
                                </div>
                            </div> -->
                        </div>

                        <div class="table-responsive">
                            <div class="budget-price justify-content-center" *ngIf="sac?.pickupClient?.length === 0">
                                <h2 class="text-center" style="color: red;">Aucun pickup client</h2>
                            </div>
                            <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                                *ngIf="sac?.pickupClient?.length > 0">
                                <thead>
                                    <tr>
                                        <th class="text-center">#</th>
                                        <!-- <th>Fournisseur</th> -->
                                        <th class="text-left">Référence</th>
                                        <th class="text-center">Date Création</th>
                                        <th class="text-center">Nom Client & Téléphone</th>
                                        <th class="text-center">Ville</th>
                                        <th class="text-center">Produits<br>
                                            Quantité - Nom produit</th>
                                        <th class="text-center">Status</th>
                                        <th class="text-center">Total<br>Quantité</th>
                                        <th class="text-center">Total<br>Volume</th>
                                        <th class="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let cmd of sac.pickupClient, let index = index">
                                        <td class="text-center tnt">
                                            #<b>{{index+1}}</b></td>
                                        <!-- <td>
                                            <div class="widget-content p-0">
                                                <div class="widget-content-wrapper">
                                                    <div class="widget-content-left mr-3">
                                                        <div class="widget-content-left">
                                                            <ngx-avatar class="rounded-circle" name="{{cmd.client_cmd.prenom_client}}
                                                            {{cmd.client_cmd.nom_client}}">
                                                            </ngx-avatar>
                                                        </div>
                                                    </div>
                                                    <div class="widget-content-left flex2">
                                                        <div class="widget-heading">
                                                            {{cmd.client_cmd.company_name}}
                                                        </div>
                                                        <div class="widget-subheading opacity-7">
                                                            {{cmd.client_cmd.prenom_client}}
                                                            {{cmd.client_cmd.nom_client}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td> -->
                                        <td class="text">{{cmd?.tra_ref_id}}</td>
                                        <td class="text-center">
                                            {{cmd?.date_creation_cmd | date:'d MMM y'}}<br>
                                            <b>{{cmd?.date_creation_cmd | date:'HH:mm'}}</b>
                                        </td>
                                        <td class="text-center">
                                            <div class="widget-content p-0">
                                                <div class="widget-content-wrapper">
                                                    <div class="widget-content-left flex2">
                                                        <div class="widget-heading">
                                                            {{cmd?.client_cmd?.prenom_client + " " +
                                                            cmd?.client_cmd?.nom_client}}</div>
                                                        <div class="widget-subheading opacity-7">
                                                            {{cmd?.client_cmd?.username_client}}
                                                        </div>
                                                        <div class="widget-subheading opacity-7">
                                                            {{cmd?.client_cmd?.numTel_client}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            {{cmd?.client_cmd?.adresse_client}}<br>
                                            <strong>{{cmd?.client_cmd?.zone_client}}</strong>
                                        </td>
                                        <td>
                                            <div *ngFor="let prd of cmd?.products?.prdts, let index = index">
                                                {{prd?.quantite}} - {{prd?.prd_id?.item_name}}<br>
                                            </div>
                                            <!-- <b>{{cmd?.type_colis}}</b> -->
                                        </td>
                                        <td class="text-center">
                                            <!-- <div class="badge badge-{{statusCmds[cmd.etat_cmd].color}}">
                                                {{statusCmds[cmd.etat_cmd].label}}</div> -->
                                            <div
                                                class="badge badge-{{statusCmds[cmd.etat_cmd].color}}">
                                                {{statusCmds[cmd?.etat_cmd]?.label}}</div>
                                            <!-- <div *ngIf="cmd.etat_cmd == 'transporting_depot'"
                                                class="badge badge-{{statusCmds[cmd.etat_cmd].color}}">
                                                {{cmd?.store?.store_name}}</div> -->
                                        </td>
                                        <td class="text-center">
                                            <span class="badge badge-pill badge-danger">
                                                {{cmd?.products?.total_qt}}
                                            </span>
                                        </td>
                                        <td class="text-center">
                                            <span class="badge badge-pill badge-danger">
                                                {{cmd?.products?.total_vm | number:'0.2'}} m³
                                            </span>
                                        </td>
                                        <td class="text-center">
                                            <button [routerLink]="['/detail-cmd']" [queryParams]="{ cmd: cmd._id }"
                                                type="button" id="PopoverCustomT-1"
                                                class="btn btn-primary btn-sm">Détails</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="main-card mb-3 card">
                        <div class="card-header" *ngIf="sac?.commandes?.length > 0">List des colis
                            <!-- <div class="btn-actions-pane-right">
                                <div role="group" class="btn-group-sm btn-group">
                                    <button class="active btn btn-focus">Last Week</button>
                                    <button class="btn btn-focus">All Month</button>
                                </div>
                            </div> -->
                        </div>

                        <div class="table-responsive">
                            <div class="budget-price justify-content-center" *ngIf="sac?.commandes?.length === 0">
                                <h2 class="text-center" style="color: red;">Aucun colis affecté dans le sac</h2>
                            </div>
                            <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                                *ngIf="sac?.commandes?.length > 0">
                                <thead>
                                    <tr>
                                        <th class="text-center">#</th>
                                        <!-- <th>Fournisseur</th> -->
                                        <th class="text-left">Référence</th>
                                        <th>Master</th>
                                        <th class="text-center">Date Création</th>
                                        <th class="text-center">Nom Client & Téléphone</th>
                                        <th class="text-center">Ville</th>
                                        <th class="text-center">Produits<br>
                                            Quantité - Nom produit</th>
                                        <th class="text-center">Status</th>
                                        <th class="text-center">Total colis</th>
                                        <th class="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let cmd of sac.commandes, let index = index">
                                        <td class="text-center tnt">
                                            #<b>{{index+1}}</b></td>
                                        <!-- <td>
                                            <div class="widget-content p-0">
                                                <div class="widget-content-wrapper">
                                                    <div class="widget-content-left mr-3">
                                                        <div class="widget-content-left">
                                                            <ngx-avatar class="rounded-circle" name="{{cmd.client_cmd.prenom_client}}
                                                            {{cmd.client_cmd.nom_client}}">
                                                            </ngx-avatar>
                                                        </div>
                                                    </div>
                                                    <div class="widget-content-left flex2">
                                                        <div class="widget-heading">
                                                            {{cmd.client_cmd.company_name}}
                                                        </div>
                                                        <div class="widget-subheading opacity-7">
                                                            {{cmd.client_cmd.prenom_client}}
                                                            {{cmd.client_cmd.nom_client}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td> -->
                                        <td class="text">{{cmd?.tra_ref_id}}</td>
                                        <td class="text-center">
                                            {{cmd?.date_creation_cmd | date:'d MMM y'}}<br>
                                            <b>{{cmd?.date_creation_cmd | date:'HH:mm'}}</b>
                                        </td>
                                        <td class="text-center">
                                            <div class="widget-content p-0">
                                                <div class="widget-content-wrapper">
                                                    <div class="widget-content-left flex2">
                                                        <div class="widget-heading">
                                                            {{cmd?.client_cmd?.prenom_client + " " +
                                                            cmd?.client_cmd?.nom_client}}</div>
                                                        <div class="widget-subheading opacity-7">
                                                            {{cmd?.client_cmd?.username_client}}
                                                        </div>
                                                        <div class="widget-subheading opacity-7">
                                                            {{cmd?.client_cmd?.numTel_client}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            {{cmd?.client_cmd?.adresse_client}}<br>
                                            <strong>{{cmd?.client_cmd?.zone_client}}</strong>
                                        </td>
                                        <td>
                                            <div *ngFor="let prd of cmd?.products?.prdts, let index = index">
                                                {{prd?.quantite}} - {{prd?.prd_id?.item_name}}<br>
                                            </div>
                                            <!-- <b>{{cmd?.type_colis}}</b> -->
                                        </td>
                                        <td class="text-center">
                                            <!-- <div class="badge badge-{{statusCmds[cmd.etat_cmd].color}}">
                                                {{statusCmds[cmd.etat_cmd].label}}</div> -->

                                            <div
                                                class="badge badge-{{statusCmds[cmd.etat_cmd].color}}">
                                                {{statusCmds[cmd?.etat_cmd]?.label}}</div>
                                            <!-- <div *ngIf="cmd.etat_cmd == 'transporting_depot'"
                                                class="badge badge-{{statusCmds[cmd.etat_cmd].color}}">
                                                {{cmd?.store?.store_name}}</div> -->
                                        </td>
                                        <td class="text-center">
                                            <span class="badge badge-pill badge-danger">
                                                {{cmd?.products?.total_qt}}
                                            </span>
                                        </td>
                                        <td class="text-center">
                                            <span class="badge badge-pill badge-danger">
                                                {{cmd?.products?.total_vm | number:'0.2'}} m³
                                            </span>
                                        </td>
                                        <td class="text-center">
                                            <button [routerLink]="['/detail-cmd']" [queryParams]="{ cmd: cmd._id }"
                                                type="button" id="PopoverCustomT-1"
                                                class="btn btn-primary btn-sm">Détails</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="main-card mb-3 card">
                        <div class="card-header" *ngIf="historicSac?.actions?.length > 0">L'historique de tourné
                            <!-- <div class="btn-actions-pane-right">
                                <div role="group" class="btn-group-sm btn-group">
                                    <button class="active btn btn-focus">Last Week</button>
                                    <button class="btn btn-focus">All Month</button>
                                </div>
                            </div> -->
                        </div>

                        <div class="table-responsive">
                            <div class="budget-price justify-content-center" *ngIf="historicSac?.actions?.length === 0">
                                <h2 class="text-center" style="color: red;">L'historique de tourné est vide</h2>
                            </div>
                            <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                                *ngIf="historicSac?.actions?.length > 0">
                                <thead>
                                    <tr>
                                        <th class="text-center">#</th>
                                        <!-- <th>Fournisseur</th> -->
                                        <th class="text-left">Référence</th>
                                        <th class="text-center">Date de l'action</th>
                                        <th class="text-center">Nombre des colis</th>
                                        <th class="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let cmd of historicSac?.actions, let index = index">
                                        <td>#<b>{{index+1}}</b></td>
                                        <td class="text">
                                            <b>{{historicSac?._id}}</b>
                                        </td>
                                        <td class="text-center">
                                            {{cmd?.date_action | date:'d MMM y'}}<br>
                                            <b>{{cmd?.date_action | date:'HH:mm'}}</b>
                                        </td>
                                        <td class="text-center">
                                            <div class="badge badge-danger">
                                                {{cmd?.commandes?.length}}</div>

                                        </td>
                                        <td class="text-center">
                                            <!-- <button [routerLink]="['/detail-cmd']" [queryParams]="{ cmd: cmd._id }"
                                                type="button" id="PopoverCustomT-1"
                                                class="btn btn-primary btn-sm">Détails</button> -->
                                            <button type="button" data-toggle="tooltip" title="Example Tooltip"
                                                data-placement="bottom" class="btn-shadow mr-3 btn btn-danger"
                                                [routerLink]="['/bon-achat-sac']"
                                                [queryParams]="{ historicSac: cmd?._id }">
                                                <i class="fa fa-star"> Etat</i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>