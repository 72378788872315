<!-- <i class="material-icons" style="font-size: 100px;color: #ff0000">info</i> -->
<div mat-dialog-content>
    <!-- <h1 style="text-align:center"><strong></strong></h1> -->
    <h2 style="text-align:center;font-size: x-large; color: rgb(170, 32, 32);">Retour d'ajout au dépôt :
    </h2>
    <div class="div-form">
        <div class="form-group">
            <div class="table-responsive">
                <div class="budget-price justify-content-center" *ngIf="data.commandes.length === 0">
                    <h5 class="text-center">Tous les colis sont affecté dans le dépôt avec succés</h5>
                </div>
                <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                    *ngIf="data.commandes.length > 0">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th class="text-left">Code à barre</th>
                            <th class="text-center">Remarque</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of data.commandes, let index = index">
                            <td class="text-center text-muted">#{{index+1}}</td>
                            <td class="text">{{(item.cmd["Tracking Number"].hasOwnProperty("result")) 
                                ? item.cmd["Tracking Number"].result 
                                : item.cmd["Tracking Number"] }}</td>
                            <td class="text-center" style="color: red;">
                                <strong>{{item?.msg?.msg}}</strong>
                            </td>
                            <!-- <td>
                                {{cmd?.pkg_count}} - {{cmd?.commodity_desc}}
                            </td>
                            <td class="text-center">
                                <div class="badge badge-{{statusCmds[cmd?.etat_cmd]?.color}}">
                                    {{statusCmds[cmd?.etat_cmd]?.label}}</div>
                            </td>
                            <td class="text-center">{{cmd?.total_ca_cmd |
                                number:'0.2'}} {{cmd?.currency}}</td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="row float-right margin-top">
    <!-- <button style="margin-right: 15px;" [mat-dialog-close]="false" class="btn btn-info">NON</button> -->

    <button style="margin-right: 15px;" [mat-dialog-close]="true" class="btn btn-danger">OUI</button>
</div>