import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-import-excel-poducts',
  templateUrl: './import-excel-poducts.component.html',
  styleUrls: ['./import-excel-poducts.component.css']
})
export class ImportExcelPoductsComponent implements OnInit {

  @ViewChild('file')
  myfileImport: ElementRef;

  msgError: string = null
  itemsError: any = []
  products: any = []

  constructor(private cmdsService: CommandesService) { }

  ngOnInit(): void {
  }

  docIsValidate(array) {
    // console.log("array")
    // console.log(array)
    if (array.includes("ia_item_code")
      && array.includes("ia_name")
      && array.includes('ia_fa_code')
      && array.includes("Colonne1")
      && array.includes('SubFamily')
      && array.includes('Family')
      && array.includes('ia_br_id')
      && array.includes('brand')
      && array.includes('VAT')
      && array.includes('vatperc')
      && array.includes('custom')
      && array.includes('customperc')
      && array.includes('smallest_unit')
      && array.includes('base_unit')
      && array.includes('UOM')
      && array.includes('ia_ty_code')
      && array.includes('item_Type')
      && array.includes('ia_minstk')
      && array.includes('ia_maxstk')

    ) {
      // this.labelType = "Fedex"
      // this.isFedex = true
      return true
    } else {
      if (!array.includes("ia_item_code")) {
        this.itemsError.push("ia_item_code")
      }
      if (!array.includes("ia_name")) {
        this.itemsError.push("ia_name")
      }
      if (!array.includes("ia_fa_code")) {
        this.itemsError.push("ia_fa_code")
      }
      if (!array.includes("Colonne1")) {
        this.itemsError.push("Colonne1")
      }
      if (!array.includes("SubFamily")) {
        this.itemsError.push("SubFamily")
      }
      if (!array.includes("Family")) {
        this.itemsError.push("Family")
      }
      if (!array.includes("ia_br_id")) {
        this.itemsError.push("ia_br_id")
      }
      if (!array.includes("brand")) {
        this.itemsError.push("brand")
      }
      if (!array.includes("VAT")) {
        this.itemsError.push("VAT")
      }
      if (!array.includes("vatperc")) {
        this.itemsError.push("vatperc")
      }
      if (!array.includes("custom")) {
        this.itemsError.push("custom")
      }
      if (!array.includes("customperc")) {
        this.itemsError.push("customperc")
      }
      if (!array.includes("smallest_unit")) {
        this.itemsError.push("smallest_unit")
      }
      if (!array.includes("base_unit")) {
        this.itemsError.push("base_unit")
      }
      if (!array.includes("UOM")) {
        this.itemsError.push("UOM")
      }
      if (!array.includes("Factor")) {
        this.itemsError.push("Factor")
      }
      if (!array.includes("ia_ty_code")) {
        this.itemsError.push("ia_ty_code")
      }
      if (!array.includes("item_Type")) {
        this.itemsError.push("item_Type")
      }
      if (!array.includes("ia_minstk")) {
        this.itemsError.push("ia_minstk")
      }
      if (!array.includes("ia_maxstk")) {
        this.itemsError.push("ia_maxstk")
      }
      return false
    }
  }

  readExcel(event) {

    const workbook = new Excel.Workbook();
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }

    /**
     * Final Solution For Importing the Excel FILE
     */
    this.msgError = null
    // this.labelType = ""
    // this.isFedex = false
    // this.isTNT = false
    this.products = []
    const header = [] //["nameDest", "adressDest", "numTelDest", "destination", "nom_prod", "quantite", "prix_unitaire"]

    const arryBuffer = new Response(target.files[0]).arrayBuffer();
    arryBuffer.then((data) => {
      workbook.xlsx.load(data)
        .then(async () => {

          // play with workbook and worksheet now
          // console.log(workbook);
          const worksheet = workbook.getWorksheet('Stock master');
          // console.log('rowCount: ', worksheet.getRow(1).values);
          if (worksheet == null) {
            this.msgError = "Impossible d'importer, veuillez vérifier le fichier excel"
            return false
          } else {
            if (!this.docIsValidate(worksheet.getRow(1).values.map(Function.prototype.call, String.prototype.trim))
                /*&& !this.docIsTNT(worksheet.getRow(1).values.map(Function.prototype.call, String.prototype.trim))*/) {
              // console.log("file incorrecte !!!")
              this.msgError = "Impossible d'importer le fichier excel à cause d'un champ manquant"
              return false
            }
          }

          const promises = [];
          // worksheet.
          worksheet.eachRow((row, rowNumber) => {
            // console.log('Row: ' + rowNumber + ' Value: ' + row.values);
            // console.log('Row ' + rowNumber + ' = ' + JSON.stringify(row.values));
            let product = {}
            if (rowNumber == 1) {
              row.eachCell((cell, colNumber) => {
                // console.log('Cell ' + colNumber + ' , ' + header[colNumber - 1] + ' = ' + cell.value);
                header.push(cell.value.toString().trim())
              });
            } else {
              // const promise = db.insert(row); // <-- whatever async operation you have here
              row.eachCell((cell, colNumber) => {
                // console.log('Cell ' + colNumber + ' , ' + header[colNumber - 1] + ' = ' + cell.value);
                product[header[colNumber - 1]] = cell.value
              });
              promises.push({ product: product, msg: null });
              // this.cmds.push(cmd)
              // return cmd
            }
          });
          this.products = await Promise.all(promises)
          console.log("all cmds : ")
          console.log(this.products)
        });
    });

    this.myfileImport.nativeElement.value = "";
  }
  getValue(item) {
    // console.log("itemmmmmmm")
    // console.log(item)
    if (item != null && item.hasOwnProperty("result")) {
      return item.result
    } else {
      return item
    }
  }
  deleteCmd(index) {

  }
  addProduct(item) {
    let promise = new Promise((resolve, reject) => {
      var prd = {
        item_code: item.product.ia_item_code,
        item_name: item.product.ia_name,
        ia_fa_code: item.product.ia_fa_code,
        Colonne1: item.product.Colonne1,
        SubFamily: item.product.SubFamily,
        Family: item.product.Family,
        ia_br_id: item.product.ia_br_id,
        brand: item.product.brand,
        netweight: item.product.netweight,
        grossWeight: item.product.grossWeight,
        vat: item.product.vat,
        vatperc: item.product.vatperc,
        custom: item.product.custom,
        customperc: item.product.customperc,
        smallest_unit: item.product.smallest_unit,
        base_unit: item.product.base_unit,
        UOM: item.product["UOM"],
        Factor: item.product.Factor,
        ia_ty_code: item.product.ia_ty_code,
        Colonne2: item.product.Colonne2,
        item_Type: item.product.item_Type,
        ia_ReorderLevel: item.product.ia_ReorderLevel,
        ia_MinStkHolding: item.product.ia_MinStkHolding,
        ia_LeadTime: item.product.ia_LeadTime,
        ia_minstk: item.product.ia_minstk,
        ia_maxstk: item.product.ia_maxstk
      }
      this.cmdsService.addPrd(prd)
        .then((res: any) => {
          // console.log("resssssssss : ")
          // console.log(res)
          if (res) {
            resolve(res);
          }
        });
    });
    return promise

  }
  addProducts() {
    let promises: Promise<any>[] = [];
    this.products.forEach(item => {
      let promise: Promise<any>
      // if(this.isFedex) {
      promise = this.addProduct(item).then((value: any) => {
        // console.log("value")
        // console.log(value)
        return { product: item.product, msg: value };
      });
      // } else if(this.isTNT) {
      //   promise = this.addCommandeTNT(item).then((value: any) => {
      //     return { cmd: item.cmd, msg: value };
      //   });
      // }
      // console.log("promiseeeee")
      // console.log(promise)


      promises.push(promise);
    });

    Promise.all(promises)
      .then((results) => {
        // console.log("promiseeesssssssssssssssssssssss : ")
        // console.log(results)
        this.products = results.filter(item => item.msg != null && item.msg.response == false);
      })
  }
}
