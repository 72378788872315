import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import { ActivatedRoute } from '@angular/router';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';
import { EnterCommandesStoreComponent } from 'src/app/components/shared/dialogs/enter-commandes-store/enter-commandes-store.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-status-commandes',
  templateUrl: './status-commandes.component.html',
  styleUrls: ['./status-commandes.component.css']
})
export class StatusCommandesComponent implements OnInit {

  statusCmds: Array<string> = []
  idStore: string = null
  isPaied: boolean = null
  listCmds: any = []

  currentPage: number = 0
  totalPages: number = 1
  pages: Array<number> = [];
  pagesDisplaied: Array<number> = [];

  statusCmd: any = []

  cmds: any = []
  @ViewChild('file')
  myfileImport: ElementRef;

  cmdsListToAffect: any = [];

  constructor(private route: ActivatedRoute,
    private cmdsService: CommandesService,
    private clientService: ClientsService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.cmdsService.getLabeledStatus().then(
      (lst) => {
        this.statusCmd = lst
      }
    )

    this.route.queryParams.subscribe((params) => {
      this.statusCmds = params.status;
      this.idStore = params.idStore
      this.isPaied = params.paied
      // console.log("params.status")
      // console.log(params.status)
      // console.log("params.paied")
      // console.log(params.paied)
      this.listCmdsClient(this.statusCmds, this.isPaied)
    });
  }

  itemIsAffected(idCmd) {
    return this.cmdsListToAffect.some(function (el) {
      return el._id === idCmd;
    });
  }

  addALLCmdToAffect(event: any) {
    if (event.target.checked) {
      this.cmdsListToAffect = this.listCmds
    } else {
      //const index = this.cmdsListToAffect.controls.findIndex(x => x === cmd);
      //this.cmdsListToAffect.remove(cmd);
      this.cmdsListToAffect = [];
    }
    // console.log("this.cmdsListToAffect")
    // console.log(this.cmdsListToAffect)
  }

  addCmdToAffect(event: any, cmd) {

    // console.log("event")
    // console.log(this.itemIsAffected(cmd._id))

    if (event.target.checked) {
      this.cmdsListToAffect.push(cmd)
    } else {
      //const index = this.cmdsListToAffect.controls.findIndex(x => x === cmd);
      //this.cmdsListToAffect.remove(cmd);
      this.cmdsListToAffect = this.cmdsListToAffect.filter(item => item !== cmd);
    }
    // console.log("this.cmdsListToAffect")
    // console.log(this.cmdsListToAffect)
  }

  // readExcel(event) {

  //   const workbook = new Excel.Workbook();
  //   const target: DataTransfer = <DataTransfer>(event.target);
  //   if (target.files.length !== 1) {
  //     throw new Error('Cannot use multiple files');
  //   }

  //   /**
  //    * Final Solution For Importing the Excel FILE
  //    */
  //   const header = [] //["nameDest", "adressDest", "numTelDest", "destination", "nom_prod", "quantite", "prix_unitaire"]

  //   const arryBuffer = new Response(target.files[0]).arrayBuffer();
  //   arryBuffer.then((data) => {
  //     workbook.xlsx.load(data)
  //       .then(async () => {

  //         // play with workbook and worksheet now
  //         // console.log(workbook);
  //         const worksheet = workbook.getWorksheet(1);
  //         // console.log('rowCount: ', worksheet.rowCount);

  //         const promises = [];
  //         worksheet.eachRow((row, rowNumber) => {
  //           // console.log('Row: ' + rowNumber + ' Value: ' + row.values);
  //           // console.log('Row ' + rowNumber + ' = ' + JSON.stringify(row.values));
  //           let cmd = {}
  //           if (rowNumber == 1) {
  //             row.eachCell((cell, colNumber) => {
  //               // console.log('Cell ' + colNumber + ' , ' + header[colNumber - 1] + ' = ' + cell.value);
  //               header.push(cell.value.trim())
  //             });

  //           } else {

  //             // const promise = db.insert(row); // <-- whatever async operation you have here
  //             row.eachCell((cell, colNumber) => {
  //               // console.log('Cell ' + colNumber + ' , ' + header[colNumber - 1] + ' = ' + cell.value);
  //               cmd[header[colNumber - 1]] = cell.value
  //             });
  //             promises.push({ cmd: cmd, msg: null });
  //             // this.cmds.push(cmd)
  //             // return cmd
  //           }
  //         });
  //         this.cmds = await Promise.all(promises)
  //         console.log("all cmds : ")
  //         console.log(this.cmds)

  //         let promisess: Promise<any>[] = [];
  //         this.cmds.forEach(item => {
  //           let promise: Promise<any> = this.enterCommande(item).then((value: any) => {
  //             return { cmd: item.cmd, msg: value };
  //           });

  //           promisess.push(promise);
  //         });

  //         Promise.all(promisess)
  //           .then(async (results) => {
  //             console.log("promiseeesssssssssssssssssssssss : ")
  //             console.log(results)
  //             this.cmds = await results.filter(item => item.msg != null && item.msg.response == false);
  //             console.log("this.cmdssssssss : ")
  //             console.log(this.cmds)
  //             this.showCommandes(this.cmds)
  //           })
  //       });
  //   });

  //   this.myfileImport.nativeElement.value = "";
  // }

  affectMoreCmds() {
    if (this.cmdsListToAffect.length > 0) {
      let promisess: Promise<any>[] = [];
      this.cmdsListToAffect.forEach(item => {
        let promise: Promise<any> = this.enterCommande(item).then((value: any) => {
          return { cmd: item, msg: value };
        });

        promisess.push(promise);
      });

      Promise.all(promisess)
        .then(async (results) => {
          // console.log("promiseeesssssssssssssssssssssss : ")
          // console.log(results)
          this.cmds = await results.filter(item => item.msg != null && item.msg.response == false);
          // console.log("this.cmdssssssss : ")
          // console.log(this.cmds)
          this.showCommandes(this.cmds)
        })
    } else {
      alert("Merci de selectionner les colis à affecter !!!")
    }
  }

  async showCommandes(cmds) {
    window.scrollTo(0, 0);
    // console.log("amineeeeeeeeee")
    // this.selectedVirement = index
    const confirmDialog = this.dialog.open(EnterCommandesStoreComponent, {
      data: {
        commandes: cmds,
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.listCmdsClient(this.statusCmds, this.isPaied)
      }
    });

  }

  enterCommande(cmd) {
    // console.log("itemmmmmmmmmmm")
    // console.log(cmd)
    let promise = new Promise((resolve, reject) => {
      this.cmdsService.enterCmdInStore(cmd.qr_code, /*cmd.type_colis,*/ null
    /*cmd.quantite * cmd.prix_unitaire, products*/)
        .then((res: any) => {
          // console.log("resssssssss : ")
          // console.log(res)
          if (res) {
            resolve(res);
          }
        });
    });
    return promise
  }

  goToNewTab(idCmd, route) {
    this.cmdsService.goToNewTab(idCmd, route)
  }

  includes(array) {
    for (var i = 0; i < array.length; i++) {
      if (this.statusCmds.includes(array[i])) {
        return true
      }
    }
    return false
  }

  async listCmdsClient(status, isPaied) {
    await this.cmdsService.allCmdsClientByStatus(this.currentPage, null, [status], isPaied, this.idStore, null).then((res: any) => {
      // console.log("res listCmdsClient")
      // console.log(res)
      if (res) {
        this.listCmds = res.items;
        this.totalPages = res.totalPages
        this.currentPage = res.currentPage
        this.pages = Array.from(Array(res.totalPages)).map((x, i) => i)
        this.splicePages()
      }
    });
  }

  splicePages() {
    // console.log("currentPage : ")
    //     console.log(this.currentPage)
    if (this.pages.length > 10) {
      // if(this.currentPage < this.pages.length-10) {
      //   this.pagesDisplaied = this.pages.splice(this.currentPage, 10);
      //   console.log("if 1 pagesDisplaied : ")
      //   console.log(this.pagesDisplaied)
      // } else 
      if (this.currentPage < 5) {
        this.pagesDisplaied = this.pages.splice(0, 10);
        // console.log("if 1 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else if (this.currentPage >= 5 && this.currentPage < this.pages.length - 5) {
        this.pagesDisplaied = this.pages.splice(this.currentPage - 4, 10);
        // console.log("if 2 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else {
        this.pagesDisplaied = this.pages.splice(this.pages.length - 10, 10);
        // console.log("else pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      }
    } else {
      this.pagesDisplaied = this.pages
    }
  }

  nextPage() {
    this.currentPage++
    this.listCmdsClient(this.statusCmds, this.isPaied)
  }

  previousPage() {
    this.currentPage--
    this.listCmdsClient(this.statusCmds, this.isPaied)
  }

  selectPage(page) {
    this.currentPage = page
    this.listCmdsClient(this.statusCmds, this.isPaied)
  }

}
