<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-users icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Produits
                <div class="page-title-subheading">La liste des produits enregistrés.
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Produits
                <!-- <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button class="active btn btn-focus">Last Week</button>
                        <button class="btn btn-focus">All Month</button>
                    </div>
                </div> -->
            </div>
            <div class="table-responsive">
                <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th>Code article</th>
                            <th class="text-center">Désignation</th>
                            <th class="text-center">Code FA</th>
                            <th class="text-center">Famille</th>
                            <!-- <th class="text-center">Date<br>Pick UP</th> -->
                            <th class="text-center">Sous famille</th>
                            <!-- <th class="text-center">Actions</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let element of allPrd, let index = index">
                            <td class="text-center text-muted">#{{index+1}}</td>
                            <td><strong>{{element?.item_code}}</strong>
                            </td>
                            <td class="text-center">{{element?.item_name}}
                            </td>
                            <td class="text-center">{{element?.ia_fa_code}}</td>
                            <td class="text-center">
                                <b>{{element?.Family}}</b>
                            </td>
                            <td class="text-center">
                                {{element?.SubFamily}}
                            </td>
                            <!-- <td class="text-center">
                                
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="d-block text-center card-footer">
                <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                        class="pe-7s-trash btn-icon-wrapper"> </i></button>
                <button class="btn-wide btn btn-success">Save</button>
            </div> -->
        </div>
    </div>
</div>