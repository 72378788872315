<!-- <i class="material-icons" style="font-size: 100px;color: #ff0000">info</i> -->
<div mat-dialog-content>
    <!-- <h1 style="text-align:center"><strong></strong></h1> -->
    <h2 style="text-align:center;font-size: x-large; color: rgb(170, 32, 32);">Détail de virement :
    </h2>
    <div class="div-form">
        <div class="form-group">
            <div class="table-responsive">
                <div class="budget-price justify-content-center" *ngIf="data.virement.commandes.length === 0">
                    <h2 class="text-center" style="color: red;">Aucun colis créé</h2>
                </div>
                <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                    *ngIf="data.virement.commandes.length > 0">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th class="text-left">Référence</th>
                            <th class="text-center">Date Création</th>
                            <th class="text-center">Nom Client<br>&<br>Téléphone</th>
                            <th class="text-center">Ville</th>
                            <th class="text-center">Produits<br>
                                Quantité - Nom produit</th>
                            <th class="text-center">Status</th>
                            <th class="text-center">Total<br>Quantité</th>
                            <th class="text-center">Total<br>Volume</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let cmd of data.virement.commandes, let index = index">
                            <td class="text-center tnt">#<b>{{index+1}}</b></td>
                            <td class="text">{{cmd?.tra_ref_id}}</td>
                            <td class="text-center">
                                {{cmd.date_creation_cmd | date:'d MMM y'}}<br>
                                <b>{{cmd.date_creation_cmd | date:'HH:mm'}}</b>
                            </td>
                            <td class="text-center">
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading">
                                                {{cmd?.client_cmd?.prenom_client + " " +
                                                cmd?.client_cmd?.nom_client}}</div>
                                            <div class="widget-subheading opacity-7">
                                                {{cmd?.client_cmd?.username_client}}
                                            </div>
                                            <div class="widget-subheading opacity-7">
                                                {{cmd?.client_cmd?.numTel_client}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center">
                                {{cmd?.client_cmd?.adresse_client}}<br>
                                <strong>{{cmd?.client_cmd?.zone_client}}</strong>
                            </td>
                            <td>
                                <div *ngFor="let prd of cmd?.products?.prdts, let index = index">
                                    {{prd?.quantite}} - {{prd?.prd_id?.item_name}}<br>
                                </div>
                                <!-- <b>{{cmd?.type_colis}}</b> -->
                            </td>
                            <td class="text-center">
                                <!-- <div class="badge badge-{{statusCmds[cmd?.etat_cmd]?.color}}">
                                    {{statusCmds[cmd?.etat_cmd]?.label}}</div> -->
                                <div
                                    class="badge badge-{{statusCmds[cmd.etat_cmd].color}}">
                                    {{statusCmds[cmd?.etat_cmd]?.label}}</div>
                                <!-- <div *ngIf="cmd.etat_cmd == 'transporting_depot'"
                                    class="badge badge-{{statusCmds[cmd.etat_cmd].color}}">
                                    {{cmd?.store?.store_name}}</div> -->
                            </td>
                            <td class="text-center">
                                <span class="badge badge-pill badge-danger">
                                    {{cmd?.products?.total_qt}}
                                </span>
                            </td>
                            <td class="text-center">
                                <span class="badge badge-pill badge-danger">
                                    {{cmd?.products?.total_vm | number:'0.2'}} m³
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="row float-right margin-top">
    <!-- <button style="margin-right: 15px;" [mat-dialog-close]="false" class="btn btn-info">NON</button> -->

    <button style="margin-right: 15px;" [mat-dialog-close]="true" class="btn btn-danger">OUI</button>
</div>