import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SacsService } from 'src/app/services/sacs/sacs.service';
import { QRCodeModule } from 'angularx-qrcode';
import { CommandesService } from 'src/app/services/commandes/commandes.service';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';

@Component({
  selector: 'app-detail-sac',
  templateUrl: './detail-sac.component.html',
  styleUrls: ['./detail-sac.component.css']
})


export class DetailSacComponent implements OnInit {

  @ViewChildren(MapInfoWindow) infoWindowsView: QueryList<MapInfoWindow>;

  mapOptions: google.maps.MapOptions = {
    // center: { lat: 36.841177, lng: 10.211049 },
    center: { lat: 34.225129, lng: 9.407312 },
    zoom: 6
  }

  sac: any = {}
  sacID: string = '';
  historicSac: any = {}
  
  statusCmds: any = [];

  constructor(
    private route: ActivatedRoute,
    private cmdsServices: CommandesService,
    private sacsServices: SacsService,
  ) { }

  ngOnInit(): void {
    this.cmdsServices.getLabeledStatus().then(
      (lst) => {
        this.statusCmds = lst
      }
    )

    this.route.queryParams.subscribe((params) => {
      this.sacID = params.sac;
      this._fetchSac()
    });
  }

  _fetchSac() {
    // console.log(cid);
    this.sacsServices.getSac(this.sacID).then(async (d) => {
      if (d) {
        // console.log("dddddddddddddd")
        // console.log(d)
        this.sac = d;
        this._fetchHistoricSac()
      } else {
        alert('Aucune sac valide');
        location.href = '/sacs';
      }
    });
  }

  _fetchHistoricSac() {
    // console.log(cid);
    this.sacsServices.getHistoricSac(this.sac.ref_sac).then((d) => {
      // console.log("dddddddddddddd")
      // console.log(d)
      if (d) {
        this.historicSac = d;
      } else {
        this.historicSac = {};
      }
    });
  }

  generatePDF(idDiv) {
    var divContents = document.getElementById(idDiv).innerHTML;
    var a = window.open('', '', 'height=860, width=1600');
    a.document.write('<html><head> ');
    a.document.write(
      '<link rel="stylesheet" type="text/css" href="/assets/css/bootstrap.min.css" />'
    );
    a.document.write(
      '<link rel="stylesheet" type="text/css" href="/assets/css/style.css" />'
    );
    a.document.write(
      '<link rel="stylesheet" type="text/css" href="/assets/css/components.css" />'
    );
    a.document.write(
      '</head><body style="background:#FFFFFF"><div class="container invoice"><center><strong><h2>'
    );

    a.document.write(divContents);
    a.document.write('</h2></strong></center></div></body></html>');
    a.document.close();
    a.print();
  }

  openInfoWindow(marker: MapMarker, windowIndex: number) {
    /// stores the current index in forEach
    let curIdx = 0;
    this.infoWindowsView.forEach((window: MapInfoWindow) => {
      if (windowIndex === curIdx) {
        window.open(marker);
        curIdx++;
      } else {
        curIdx++;
      }
    });
  }

}
