import { Component, OnInit } from '@angular/core';
import { StoresService } from 'src/app/services/stores/stores.service';

@Component({
  selector: 'app-add-store',
  templateUrl: './add-store.component.html',
  styleUrls: ['./add-store.component.css']
})
export class AddStoreComponent implements OnInit {

  typeColis = [
    // "DOC",
    "LV",
    "HV"
  ]
  selectedTypeColis: any = [];

  
  villes = ["Selectionnez une ville",
  "Abengourou",
  "ABOBO",
  "ABOISSO",
  "ADIAKE",
  "ADJAME",
  "ADZOPE",
  "Adzope",
  "AFFERY",
  "AGBOVILLE",
  "Agboville",
  "AKOUPE",
  "ANYAMA",
  "AYAME",
  "AZAGUIE",
  "BASSAM",
  "BINGERVILLE",
  "BONGOUANOU",
  "BONOUA",
  "Bouafle",
  "BOUAKE",
  "COCODY",
  "COMMERCIAL",
  "DABOU",
  "Daloa",
  "Divo",
  "Duikeue",
  "EXPORT",
  "Gagnoa",
  "GRAND BASSAM",
  "GRAND LAHOU",
  "GUIGLO",
  "HERMANKONO",
  "Issa",
  "JACQUEVILLE",
  "KORHOGO",
  "Korogho",
  "KOUMASSI",
  "MAFERE",
  "MAN",
  "MARCORY",
  "MARKETING",
  "Meagui",
  "N'DOUCI",
  "NANO",
  "Odienne",
  "PK26",
  "PLATEAU",
  "PORT BOUET",
  "PROPRE MOYEN",
  "SAN PEDRO",
  "SIKENSI",
  "SINFRA",
  "SOUBRE",
  "Soubre",
  "TIASSALE",
  "Toumodi",
  "TREICHVILLE",
  "Vavoua",
  "Vente directe",
  "YAKASSE",
  "YAMOUSSOUKRO",
  "YOPOUGON"
  ];
  selectedVille: any = {};

  constructor(private storesService: StoresService) {}

  ngOnInit(): void {
  }

  async addStore(storeName) {
    // console.log(this.selectedTypeColis)
    if (this.selectedVille != '' && storeName.value!=null && storeName.value!="") {
      await this.storesService.addStore(storeName.value, this.selectedTypeColis, this.selectedVille)
        .then((res: any) => {
          // console.log("resssssssss : ")
          // console.log(res)
          if (res) {
            location.href = '/stores';
            //this.sacsList = res;
          }
        });
    } else {
      alert("Il faut ajouter les champs obligatoire !!!")
    }
  }

}
