import { Component, OnInit } from '@angular/core';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.css']
})
export class AddProductComponent implements OnInit {

  constructor(private cmdsService: CommandesService) { }

  ngOnInit(): void {
  }

  async addProduct(item_code, item_name, ia_fa_code,
    Colonne1, SubFamily,
    Family, ia_br_id, brand, netweight, grossWeight, vat, vatperc,
    custom, customperc, smallest_unit,
    base_unit, UOM, Factor,
    ia_ty_code, Colonne2, item_Type,
    ia_ReorderLevel, ia_MinStkHolding, ia_LeadTime,
    ia_minstk, ia_maxstk) {
    var prd = {
      item_code: item_code.value,
      item_name: item_name.value,
      ia_fa_code: ia_fa_code.value,
      Colonne1: Colonne1.value,
      SubFamily: SubFamily.value,
      Family: Family.value,
      ia_br_id: ia_br_id.value,
      brand: brand.value,
      netweight: netweight.value,
      grossWeight: grossWeight.value,
      vat: vat.value,
      vatperc: vatperc.value,
      custom: custom.value,
      customperc: customperc.value, 
      smallest_unit: smallest_unit.value,
      base_unit: base_unit.value, 
      UOM: UOM.value, 
      Factor: Factor.value,
      ia_ty_code: ia_ty_code.value, 
      Colonne2: Colonne2.value, 
      item_Type: item_Type.value,
      ia_ReorderLevel: ia_ReorderLevel.value, 
      ia_MinStkHolding: ia_MinStkHolding.value, 
      ia_LeadTime: ia_LeadTime.value,
      ia_minstk: ia_minstk.value, 
      ia_maxstk: ia_maxstk.value
    }
    await this.cmdsService.addPrd(prd)
      .then((res: any) => {
        // console.log("resssssssss : ")
        // console.log(res)
        if (res) {
          location.href = '/products';
          //this.sacsList = res;
        }
      });
  }

}
