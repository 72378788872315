import { Component, OnInit } from '@angular/core';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-statistics-commandes',
  templateUrl: './statistics-commandes.component.html',
  styleUrls: ['./statistics-commandes.component.css']
})
export class StatisticsCommandesComponent implements OnInit {

  
  villes = ["Selectionnez une ville",
  "Abengourou",
  "ABOBO",
  "ABOISSO",
  "ADIAKE",
  "ADJAME",
  "ADZOPE",
  "Adzope",
  "AFFERY",
  "AGBOVILLE",
  "Agboville",
  "AKOUPE",
  "ANYAMA",
  "AYAME",
  "AZAGUIE",
  "BASSAM",
  "BINGERVILLE",
  "BONGOUANOU",
  "BONOUA",
  "Bouafle",
  "BOUAKE",
  "COCODY",
  "COMMERCIAL",
  "DABOU",
  "Daloa",
  "Divo",
  "Duikeue",
  "EXPORT",
  "Gagnoa",
  "GRAND BASSAM",
  "GRAND LAHOU",
  "GUIGLO",
  "HERMANKONO",
  "Issa",
  "JACQUEVILLE",
  "KORHOGO",
  "Korogho",
  "KOUMASSI",
  "MAFERE",
  "MAN",
  "MARCORY",
  "MARKETING",
  "Meagui",
  "N'DOUCI",
  "NANO",
  "Odienne",
  "PK26",
  "PLATEAU",
  "PORT BOUET",
  "PROPRE MOYEN",
  "SAN PEDRO",
  "SIKENSI",
  "SINFRA",
  "SOUBRE",
  "Soubre",
  "TIASSALE",
  "Toumodi",
  "TREICHVILLE",
  "Vavoua",
  "Vente directe",
  "YAKASSE",
  "YAMOUSSOUKRO",
  "YOPOUGON"
  ];
  selectedVille: string = '';
  userId: String = null
  statsCmds: any = {}
  cmdList: any = [];
  statusCmds: any = []
  clientsList: any = []

  constructor(private cmdsService: CommandesService,
    private clientService: ClientsService) { }

  ngOnInit(): void {
    this.cmdsService.getLabeledStatus().then(
      (lst) => {
        this.statusCmds = lst
      }
    )
    this.listClients()
  }

  async inventaire(datein, datefin) {
    // let userId = await this.clientService.getIdUser()
    await this.cmdsService.statisticsCmds(this.userId, datein.value, datefin.value, this.selectedVille).then((res: any) => {
      // console.log("res statisticsCmds : ")
      // console.log(res)
      if (res) {
        this.statsCmds = res;
      }
    });
  }

  selectChangeVille(event: any) {
    if (event.target.value == "Selectionnez une ville") {
      this.selectedVille = "";
    } else {
      this.selectedVille = event.target.value;
    }
  }

  selectClient(event: any) {
    // console.log(event.target.value)
    if (event.target.value == "Selectionnez le client") {
      this.userId = null;
    } else {
      this.userId = event.target.value;
    }
  }

  displayCmdsByStatus(status) {
    if (status == "validated") {
      this.cmdList = this.statsCmds.allCmdsValidated
    } else if (status == "transporting_depot") {
      this.cmdList = this.statsCmds.allCmdsDepot
    } else if (status == "transporting") {
      this.cmdList = this.statsCmds.allCmdsTransporting
    } else if (status == "delivered") {
      this.cmdList = this.statsCmds.allCmdsLivred
    } else if (status == "paied") {
      this.cmdList = this.statsCmds.allCmdsPaied
    } else if (status == "returned") {
      this.cmdList = this.statsCmds.allCmdsReturned
    } else if (status == "canceled") {
      this.cmdList = this.statsCmds.allCmdsCanceled
    } else {
      this.cmdList = []
    }
  }

  async listClients() {
    await this.clientService.getClients().then((res: any) => {
      // console.log(res)
      if (res) {
        this.clientsList = res;
        // this.clientsList.splice(0, 0, "Selectionnez le client");
      }
    });
  }
}
