<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-users icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Clients
                <div class="page-title-subheading">La liste des clients enregistrés.
                </div>
            </div>
        </div>
        <!-- <div class="page-title-actions">
            <div class="d-inline-block dropdown">
                <div>
                    <form class="form-inline" action="" method="post" onsubmit="return false;"
                        (onsubmit)="getClient(keyword);">
                        <div class="mb-2 mr-sm-2 mb-sm-0 position-relative form-group">
                            <input name="keyword" id="keyword" placeholder="Client ?" type="keyword"
                                class="form-control" #keyword ngModel>
                        </div>
                        <button type="submit" class="btn-shadow mr-3 btn btn-dark"
                            (click)="getClient(keyword)">
                            <i class="pe-7s-search"></i>
                        </button>
                    </form>
                </div>
            </div>
        </div> -->
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Clients
                <!-- <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button class="active btn btn-focus">Last Week</button>
                        <button class="btn btn-focus">All Month</button>
                    </div>
                </div> -->
            </div>
            <div class="table-responsive">
                <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th>Client</th>
                            <th class="text-center">Téléphone</th>
                            <th class="text-center">Ville</th>
                            <th class="text-center">Zone</th>
                            <th class="text-center">TYPE DE MAGASINS</th>
                            <th class="text-center">DELAI DE LIVRAISON</th>
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let element of clientsList, let index = index">
                            <td class="text-center text-muted">{{index+1}}</td>
                            <td>
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left mr-3">
                                            <div class="widget-content-left">
                                                <!-- <img width="40" class="rounded-circle"
                                                    src="assets/images/avatars/2.jpg" alt=""> -->
                                                <ngx-avatar class="rounded-circle"
                                                    name="{{getFirstName(element.client.prenom_client)}}">
                                                </ngx-avatar>
                                            </div>
                                        </div>
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading">{{element.client.company_name}}</div>
                                            <!-- <div class="widget-heading">{{element.client.prenom_client}}
                                                {{element.client.nom_client}}</div> -->
                                            <!-- <div class="widget-subheading opacity-7">
                                                {{element.client.company_name}}</div> -->
                                            <div class="widget-subheading opacity-7">
                                                {{element.client.username_client}}</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center"><strong>{{element.client.numTel_client}}</strong>
                            </td>
                            <td class="text-center">{{element.client.adresse_client}}</td>
                            <!-- <td class="text-center">
                                {{element.client?.pickUpCollector?.name_collector}}
                                <br><strong>{{element.client?.pickUpCollector?.numTel_collector}}</strong>
                            </td> -->
                            <td class="text-center">
                                <b>{{element.client?.zone_client}}</b>
                            </td>
                            <td class="text-center">
                                <b>{{element.client?.store_client}}</b>
                            </td>
                            <td class="text-center">
                                <div class="badge badge-warning">{{element?.client?.delivery_time_client}}</div>
                            </td>
                            <td class="text-center">
                                <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                    <button class="btn-wide btn btn-info">Actions</button>
                                    <button type="button" ngbDropdownToggle
                                        class="dropdown-toggle-split dropdown-toggle btn btn-info"><span
                                            class="sr-only">Actions</span></button>
                                    <div ngbDropdownMenu>
                                        <button type="button" [routerLink]="['/detail-cmds-client']"
                                            [queryParams]="{ client: element.client._id }" tabindex="0"
                                            class="dropdown-item">
                                            <i class="metismenu-icon pe-7s-news-paper"
                                                style="margin-right: 10px;"></i>Détails
                                        </button>
                                        <!-- <button type="button" tabindex="0" *ngIf="element.client.fraisDelivery == null"
                                            [routerLink]="['/frais-client']"
                                            [queryParams]="{ client: element.client._id }" class="dropdown-item"><i
                                                class="metismenu-icon pe-7s-print"
                                                style="margin-right: 10px;"></i>Ajouter frais</button> -->
                                        <!-- <button type="button" tabindex="0" *ngIf="element.client.fraisDelivery != null"
                                            [routerLink]="['/edit-frais-client']"
                                            [queryParams]="{ client: element.client._id }" class="dropdown-item"><i
                                                class="metismenu-icon pe-7s-print"
                                                style="margin-right: 10px;"></i>Modifier frais</button> -->
                                        <button type="button" tabindex="0" [routerLink]="['/compte-client']"
                                            [queryParams]="{ client: element.client._id }" class="dropdown-item"><i
                                                class="metismenu-icon pe-7s-print"
                                                style="margin-right: 10px;"></i>Compte client</button>
                                        <!-- <button type="button" tabindex="0" (click)="pickUpCollector(element.client._id)"
                                            class="dropdown-item"><i class="metismenu-icon pe-7s-print"
                                                style="margin-right: 10px;"></i>Affecter le pick-up</button> -->
                                    </div>
                                </div>
                                <!-- <button [routerLink]="['/detail-cmds-client']"
                                    [queryParams]="{ client: element.client._id }" type="button"
                                    id="PopoverCustomT-1"
                                    class="btn btn-primary btn-sm">Détails</button> -->

                                <!-- <button *ngIf="element.fraisClient == null"
                                    [routerLink]="['/frais-client']"
                                    [queryParams]="{ client: element.client._id }" type="button"
                                    id="PopoverCustomT-1" class="btn btn-info btn-sm">+</button> -->
                                <!-- <button *ngIf="element.fraisClient != null"
                                    [routerLink]="['/edit-frais-client']"
                                    [queryParams]="{ client: element.client._id }" type="button"
                                    id="PopoverCustomT-1" class="btn btn-info btn-sm">-</button> -->

                                <!-- <button [routerLink]="['/compte-client']"
                                    [queryParams]="{ client: element.client._id }" type="button"
                                    id="PopoverCustomT-1" class="btn btn-primary btn-sm">Compte</button> -->

                                <!-- <button type="button" (click)="pickUpCollector(element.client._id)"
                                    id="PopoverCustomT-1"
                                    class="btn btn-warning btn-sm">Affecter</button> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="d-block text-center card-footer">
                <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                        class="pe-7s-trash btn-icon-wrapper"> </i></button>
                <button class="btn-wide btn btn-success">Save</button>
            </div> -->
        </div>
    </div>
</div>