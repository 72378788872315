<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-culture icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Équipes NANO
                <div class="page-title-subheading">La liste des équipes enregistrées.
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <div class="d-inline-block dropdown">
                <button type="button" class="btn btn-warning" [routerLink]="['/add-user-fedex']">
                    <span class="btn-icon-wrapper pr-2 opacity-7">
                        <i class="fa fa-business-time fa-w-20"></i>
                    </span>
                    Ajouter un membre
                </button>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Équipes
                <!-- <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button class="active btn btn-focus">Last Week</button>
                        <button class="btn btn-focus">All Month</button>
                    </div>
                </div> -->
            </div>
            <div class="table-responsive">
                <div class="budget-price justify-content-center" *ngIf="allTeams.length === 0">
                    <h2 class="text-center">Pas d'utilisateur</h2>
                </div>
                <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                    *ngIf="allTeams.length > 0">
                    <!-- <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th>Client</th>
                            <th class="text-center">Commandes<br>en<br>attente</th>
                            <th class="text-center">Commandes<br>en<br>cours</th>
                            <th class="text-center">Commandes<br>livrée</th>
                            <th class="text-center">Commandes<br>payée</th>
                            <th class="text-center">Commandes<br>annulée</th>
                            <th class="text-center">Total<br>des<br>commandes</th>
                            <th class="text-center">Etat</th>
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead> -->
                    <tbody>
                        <tr *ngFor="let user of allTeams, let index = index">
                            <td class="text-center text-muted">#{{index+1}}</td>
                            <td>
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left mr-3">
                                            <div class="widget-content-left">
                                                <!-- <img width="40" height="40" class="rounded-circle"
                                                    src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + society?.user_transportor?.profile_user}}"
                                                    alt=""> -->


                                                <div
                                                    *ngIf="(user.profile_user!=null && user.profile_user!='');else contenu">
                                                    <img class="rounded-circle" style="width: 50px; height: 50px;"
                                                        src="{{env.plannersUsersAssetsBaseURL + user.profile_user}}"
                                                        alt="product">
                                                </div>
                                                <ng-template #contenu>
                                                    <ngx-avatar class="rounded-circle"
                                                        name="{{user.prenom_user}} {{user.nom_user}}">
                                                    </ngx-avatar>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading">{{user.email_user}}</div>
                                            <!-- <div class="widget-subheading opacity-7">
                                                {{society?.user_transportor?.email_user}}</div>
                                            <div class="widget-subheading opacity-7">
                                                <b>{{society?.user_transportor?.tel_user}}</b>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center">
                                {{user.prenom_user}} {{user.nom_user}}
                            </td>
                            <td class="text-center">
                                {{user.tel_user}}
                            </td>
                            <td class="text-center">
                                {{user.profiles}}
                            </td>
                            <!-- <td class="text-center">
                                <div class="badge badge-success">
                                    {{filterCommandes(society?.grouping, ['paied'])}}</div>
                            </td>
                            <td class="text-center">
                                <div class="badge badge-danger">
                                    {{filterCommandes(society?.grouping, ['canceled'])}}</div>
                            </td>
                            <td class="text-center"><b>{{society?.grouping?.length}}</b></td>
                            <td class="text-center">
                                <div class="badge badge-success">
                                    Validé
                                </div>
                            </td> -->
                            <td class="text-center">
                                <a title="Modifier" class="btn btn-success btn-action trigger--fire-modal-1"><i
                                        class="fas fa-pencil-alt"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>