import { Component, OnInit } from '@angular/core';
import { CommandesService } from 'src/app/services/commandes/commandes.service';
import { UsersTrackingService } from 'src/app/services/users-tracking/users-tracking.service';

@Component({
  selector: 'app-statistics-transportor',
  templateUrl: './statistics-transportor.component.html',
  styleUrls: ['./statistics-transportor.component.css']
})
export class StatisticsTransportorComponent implements OnInit {

  userId: string = null
  statsCmds: any = {}
  cmdList: any = [];
  statusCmds: any = []
  leadersList: any = []

  constructor(private cmdsService: CommandesService,
    private trackingServices: UsersTrackingService) { }

  ngOnInit(): void {
    this.cmdsService.getLabeledStatus().then(
      (lst) => {
        this.statusCmds = lst
      }
    )
    this.listTransportors()
  }

  selectTransportor(event: any) {
    if (event.target.value == "Selectionnez le transporteur") {
      this.userId = null;
    } else {
      this.userId = event.target.value;
    }
  }

  async listTransportors() {
    await this.trackingServices.getResponsables().then((res: any) => {
      if (res) {
        // console.log("leader res");
        // console.log(res);
        this.leadersList = res;
      }
    });
  }

  async inventaire(datein, datefin) {
    // let userId = await this.clientService.getIdUser()
    await this.trackingServices.statisticsTransportors(datein.value, datefin.value, this.userId).then((res: any) => {
      // console.log("res statisticsCmds : ")
      // console.log(res)
      if (res) {
        this.statsCmds = res;
      }
    });
  }

  displayCmdsByStatus(status) {
    if (status == "validated") {
      this.cmdList = this.statsCmds.allCmdsValidated
    } else if (status == "transporting_depot") {
      this.cmdList = this.statsCmds.allCmdsDepot
    } else if (status == "transporting") {
      this.cmdList = this.statsCmds.allCmdsTransporting
    } else if (status == "delivered") {
      this.cmdList = this.statsCmds.allCmdsLivred
    } else if (status == "paied") {
      this.cmdList = this.statsCmds.allCmdsPaied
    } else if (status == "returned") {
      this.cmdList = this.statsCmds.allCmdsReturned
    } else if (status == "canceled") {
      this.cmdList = this.statsCmds.allCmdsCanceled
    } else {
      this.cmdList = []
    }
  }

}
