<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-box2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Ajouter station
                <div class="page-title-subheading">Ajouter une nouvelle station.
                </div>
            </div>
        </div>
        <!-- <div class="page-title-actions">
            <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                class="btn-shadow mr-3 btn btn-dark">
                <i class="fa fa-star"></i>
            </button>
            <div class="d-inline-block dropdown">
                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    class="btn-shadow dropdown-toggle btn btn-info">
                    <span class="btn-icon-wrapper pr-2 opacity-7">
                        <i class="fa fa-business-time fa-w-20"></i>
                    </span>
                    Buttons
                </button>
                <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu dropdown-menu-right">
                    <ul class="nav flex-column">
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-inbox"></i>
                                <span>
                                    Inbox
                                </span>
                                <div class="ml-auto badge badge-pill badge-secondary">86</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-book"></i>
                                <span>
                                    Book
                                </span>
                                <div class="ml-auto badge badge-pill badge-danger">5</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-picture"></i>
                                <span>
                                    Picture
                                </span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a disabled href="javascript:void(0);" class="nav-link disabled">
                                <i class="nav-link-icon lnr-file-empty"></i>
                                <span>
                                    File Disabled
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div> -->
    </div>
</div>
<div class="tab-content">
    <div class="main-card mb-12 card">
        <div class="card-body">
            <form class="" action="" method="post" onsubmit="return false;" (onsubmit)="addStore(storeName);">
                <div class="position-relative row form-group">
                    <div class="col-sm-4">
                        <legend for="storeName" class="space-bottom">Nom de la station
                        </legend>
                        <input name="storeName" id="storeName" type="text" class="form-control" #storeName ngModel>
                        <mat-error style="color: red;font-weight: bold;" *ngIf="storeName.value==''">
                            Ajoutez le nom de la station
                        </mat-error>
                    </div>

                    <div class="col-sm-4">
                        <legend for="storeName" class="space-bottom">Type des colis
                        </legend>
                        <!-- <div class="position-relative form-group"> -->
                        <select class="form-control" multiple [(ngModel)]="selectedTypeColis" name="profile">
                            <option [value]="type" *ngFor="let type of typeColis">
                                {{type}}</option>
                        </select>
                        <!-- </div> -->
                    </div>

                    <div class="col-sm-4">
                        <legend for="exampleSelect" class="space-bottom">
                            Localisation de la station </legend>
                        <select name="destination" id="destination" class="form-control" [(ngModel)]="selectedVille">
                            <option *ngFor="let destination of villes" [ngValue]="destination" ngDefaultControl>
                                {{destination}}
                            </option>
                        </select>
                        <mat-error style="color: red;font-weight: bold;"
                            *ngIf="selectedVille=='' || selectedVille=='Selectionnez une ville'">
                            Ajoutez la localisation de la station
                        </mat-error>
                    </div>
                </div>

                <div class="position-relative row form-check">
                    <div class="col-sm-10 offset-sm-10">
                        <button type="submit" (click)="addStore(storeName)" class="btn btn-primary">Ajouter
                            station</button>
                    </div>
                </div>
            </form>

            <!-- <form action="" method="post" enctype="multipart/form-data">
                <div class="form-group">
                    <label>Select video</label>
                    <input type="file" name="video" accept="video/*" class="form-control-file" (change)="handleVideoInput($event)">
                </div>

                <div class="form-group">
                    <label>Title</label>
                    <input type="text" name="title" class="form-control" #title
                    ngModel>
                </div>

                <input type="submit" class="btn btn-primary" value="Upload" (click)="upload(title)">
            </form> -->
        </div>
    </div>
</div>