<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-user icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Produit
                <div class="page-title-subheading">Ajouter un nouveau produit.
                </div>
            </div>
        </div>
    </div>
</div>
<div class="tab-content">
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="tab-content">
                    <div class="tab-pane tabs-animation fade show active">
                        <div class="card-body">
                            <form class="" action="" method="post" onsubmit="return false;" (onsubmit)="addProduct(item_code, item_name, ia_fa_code, 
                            Colonne1, SubFamily, 
                            Family, ia_br_id, brand, netweight, grossWeight, vat, vatperc,
                            custom, customperc, smallest_unit,
                            base_unit, UOM, Factor,
                            ia_ty_code, Colonne2, item_Type,
                            ia_ReorderLevel, ia_MinStkHolding, ia_LeadTime, 
                            ia_minstk, ia_maxstk);">
                                <div class="form-row">
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="item_code" class="">Code</legend>
                                            <input name="item_code" id="item_code" type="text" class="form-control"
                                                #item_code ngModel>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="item_name" class="">Nom du produit</legend>
                                            <input name="item_name" id="item_name" type="text" class="form-control"
                                                #item_name ngModel>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="ia_fa_code" class="">Code FA</legend>
                                            <input name="ia_fa_code" id="ia_fa_code" type="text" class="form-control"
                                                #ia_fa_code ngModel>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="Colonne1" class="">Colonne1</legend>
                                            <input name="Colonne1" id="Colonne1" type="text" class="form-control"
                                                #Colonne1 ngModel>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="SubFamily" class="">Sous famille</legend>
                                            <input name="SubFamily" id="SubFamily" type="text" class="form-control"
                                                #SubFamily ngModel>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="Family" class="">Famille</legend>
                                            <input name="Family" id="Family" type="text" class="form-control" #Family
                                                ngModel>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="ia_br_id" class="">BR ID</legend>
                                            <input name="ia_br_id" id="ia_br_id" type="number" class="form-control"
                                                #ia_br_id ngModel>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="brand" class="">Marque</legend>
                                            <input name="brand" id="brand" type="text" class="form-control" #brand
                                                ngModel>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="netweight" class="">Poids net</legend>
                                            <input name="netweight" id="netweight" type="number" class="form-control"
                                                #netweight ngModel>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="grossWeight" class="">Poids brut</legend>
                                            <input name="grossWeight" id="grossWeight" type="number" class="form-control"
                                                #grossWeight ngModel>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="vat" class="">TVA</legend>
                                            <input name="vat" id="vat" type="text" class="form-control" #vat ngModel>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="vatperc" class="">Pourcentage TVA</legend>
                                            <input name="vatperc" id="vatperc" type="number" class="form-control"
                                                #vatperc ngModel>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="custom" class="">Personnalisé</legend>
                                            <input name="custom" id="custom" type="text" class="form-control" #custom
                                                ngModel>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="customperc" class="">Personnalisé pourcentage</legend>
                                            <input name="customperc" id="customperc" type="number" class="form-control"
                                                #customperc ngModel>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="smallest_unit" class="">Plus petite unité</legend>
                                            <input name="smallest_unit" id="smallest_unit" type="text"
                                                class="form-control" #smallest_unit ngModel>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="base_unit" class="">Unité de base</legend>
                                            <input name="base_unit" id="base_unit" type="text" class="form-control" 
                                            #base_unit ngModel>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="UOM" class="">UOM</legend>
                                            <input name="UOM" id="UOM" type="text" class="form-control"
                                                #UOM ngModel>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="Factor" class="">L'élément</legend>
                                            <input name="Factor" id="Factor" type="number"
                                                class="form-control" #Factor ngModel>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="ia_ty_code" class="">ia_ty_code</legend>
                                            <input name="ia_ty_code" id="ia_ty_code" type="text" class="form-control" 
                                            #ia_ty_code ngModel>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="Colonne2" class="">Colonne2</legend>
                                            <input name="Colonne2" id="Colonne2" type="text" class="form-control"
                                                #Colonne2 ngModel>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="item_Type" class="">Type d'élément</legend>
                                            <input name="item_Type" id="item_Type" type="text"
                                                class="form-control" #item_Type ngModel>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="ia_ReorderLevel" class="">ia_ReorderLevel</legend>
                                            <input name="ia_ReorderLevel" id="ia_ReorderLevel" type="text" class="form-control" 
                                            #ia_ReorderLevel ngModel>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="ia_MinStkHolding" class="">ia_MinStkHolding</legend>
                                            <input name="ia_MinStkHolding" id="ia_MinStkHolding" type="text" class="form-control"
                                                #ia_MinStkHolding ngModel>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <legend for="ia_LeadTime" class="">ia_LeadTime</legend>
                                            <input name="ia_LeadTime" id="ia_LeadTime" type="text"
                                                class="form-control" #ia_LeadTime ngModel>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-6">
                                        <div class="position-relative form-group">
                                            <legend for="ia_minstk" class="">ia_minstk</legend>
                                            <input name="ia_minstk" id="ia_minstk" type="number" class="form-control" 
                                            #ia_minstk ngModel>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="position-relative form-group">
                                            <legend for="ia_maxstk" class="">ia_maxstk</legend>
                                            <input name="ia_maxstk" id="ia_maxstk" type="number" class="form-control"
                                                #ia_maxstk ngModel>
                                        </div>
                                    </div>
                                </div>

                                <div class="position-relative row form-check">
                                    <div class="col-sm-10 offset-sm-10">
                                        <button type="submit" (click)="addProduct(item_code, item_name, ia_fa_code, 
                                                Colonne1, SubFamily, 
                                                Family, ia_br_id, brand, netweight, grossWeight, vat, vatperc,
                                                custom, customperc, smallest_unit,
                                                base_unit, UOM, Factor,
                                                ia_ty_code, Colonne2, item_Type,
                                                ia_ReorderLevel, ia_MinStkHolding, ia_LeadTime, 
                                                ia_minstk, ia_maxstk)" class="btn btn-primary">Ajouter Produit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>