import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AffectationPrepaDialogComponent } from 'src/app/components/shared/dialogs/affectation-prepa-dialog/affectation-prepa-dialog.component';
import { DetailsCommandesComponent } from 'src/app/components/shared/dialogs/details-commandes/details-commandes.component';
import { StoresService } from 'src/app/services/stores/stores.service';
import { UsersTrackingService } from 'src/app/services/users-tracking/users-tracking.service';

@Component({
  selector: 'app-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.css']
})
export class StoresComponent implements OnInit {

  storesList: Observable<Array<any>>;

  constructor(private storesService: StoresService,
    private dialog: MatDialog,
    private trackingServices: UsersTrackingService,) { }

  ngOnInit(): void {
    this.listStores()
  }

  async listStores() {
    await this.storesService.getDetailsStores().then((res: any) => {
      if (res) {
        // console.log("leader res");
        // console.log(res);
        this.storesList = res;
      }
     
    });
  }

  async showCommandes(cmds) {
    window.scrollTo(0, 0);
    // console.log("amineeeeeeeeee")
    // this.selectedVirement = index
    const confirmDialog = this.dialog.open(DetailsCommandesComponent, {
      data: {
        commandes: cmds,
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
      }
    });
  }

  async affectStorePrepa(idStore) {
    // console.log("amineeeeeeeeee")
    const confirmDialog = this.dialog.open(AffectationPrepaDialogComponent, {
      data: {
        idStore: idStore,
        // destinationSac: destinationSac,
        user: null,
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        var data = confirmDialog.componentInstance.data
        // console.log("dataaaaaaaaaa : ")
        // console.log(data.user)
        // console.log("idSac : ")
        // console.log(idSac)
        this.trackingServices.affectPrepaToStore(idStore, data.user).then((res: any) => {
          // console.log("affectStorePrepa : ");
          // console.log(res);
          if (res && !res.response) {
            alert(res.msg)
          }
          this.listStores();
          // else {
          //   // console.log("erreur");
          //   this.notif.notify('error', "Une erreur s'est produite");
          // }
        });
        
      }
    });

  }

}
