import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { SacsService } from 'src/app/services/sacs/sacs.service';
import { UsersTrackingService } from 'src/app/services/users-tracking/users-tracking.service';

@Component({
  selector: 'app-add-sac',
  templateUrl: './add-sac.component.html',
  styleUrls: ['./add-sac.component.css']
})
export class AddSacComponent implements OnInit {

  
  villes = ["Selectionnez une ville",
  "Abengourou",
  "ABOBO",
  "ABOISSO",
  "ADIAKE",
  "ADJAME",
  "ADZOPE",
  "Adzope",
  "AFFERY",
  "AGBOVILLE",
  "Agboville",
  "AKOUPE",
  "ANYAMA",
  "AYAME",
  "AZAGUIE",
  "BASSAM",
  "BINGERVILLE",
  "BONGOUANOU",
  "BONOUA",
  "Bouafle",
  "BOUAKE",
  "COCODY",
  "COMMERCIAL",
  "DABOU",
  "Daloa",
  "Divo",
  "Duikeue",
  "EXPORT",
  "Gagnoa",
  "GRAND BASSAM",
  "GRAND LAHOU",
  "GUIGLO",
  "HERMANKONO",
  "Issa",
  "JACQUEVILLE",
  "KORHOGO",
  "Korogho",
  "KOUMASSI",
  "MAFERE",
  "MAN",
  "MARCORY",
  "MARKETING",
  "Meagui",
  "N'DOUCI",
  "NANO",
  "Odienne",
  "PK26",
  "PLATEAU",
  "PORT BOUET",
  "PROPRE MOYEN",
  "SAN PEDRO",
  "SIKENSI",
  "SINFRA",
  "SOUBRE",
  "Soubre",
  "TIASSALE",
  "Toumodi",
  "TREICHVILLE",
  "Vavoua",
  "Vente directe",
  "YAKASSE",
  "YAMOUSSOUKRO",
  "YOPOUGON"
  ];

  form: FormGroup;
  leadersList: any = []
  transportor: any = null

  constructor(private sacsServices: SacsService,
    private trackingServices: UsersTrackingService,
    private fb: FormBuilder,) {
      this.form = fb.group({
        destinations: fb.array([])
      })
    }

  ngOnInit(): void {
    this.listTransportors()
    this.addNewDestinationGroup();
  }

  async listTransportors() {
    await this.trackingServices.getTrakingNotAffectedToTurned().then((res: any) => {
      if (res) {
        // console.log("leader res");
        // console.log(res);
        this.leadersList = res;
      }
    });
  }

  selectVille(destination: any) {
    // console.log("event.target.value")
    // console.log(this.selectedVille)
    // this.selectedVille = event.target.value;
  }

  async addSac() {
    // console.log("this.form.value.destinations")
    // console.log(this.transportor)
    // console.log("addSac : ")
    if (this.destinationsNotEmpty()) {
      await this.sacsServices.addSac(this.form.value.destinations)
        .then((res: any) => {
          // console.log("resssssssss : ")
          // console.log(res)
          if (res) {
            location.href = '/sacs';
            //this.sacsList = res;
          }
        });
    }
  }

  destinationsNotEmpty() {
    for (var i = 0; i < this.form.value.destinations.length; i++) {
      if (this.form.value.destinations[i].destination == null || 
        this.form.value.destinations[i].destination == "" || this.form.value.destinations[i].destination == "Selectionnez une ville") {
        return false
      }
    }
    return true
  }

  addNewDestinationGroup() {
    const add = this.form.get('destinations') as FormArray;
    add.push(this.fb.group({
      // nom_prod: [],
      // quantite: [],
      destination: []
    }))
  }

  deleteProductGroup(index: number) {
    const add = this.form.get('destinations') as FormArray;
    add.removeAt(index)
  }

}
