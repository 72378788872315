<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-shuffle icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Retours validés
                <div class="page-title-subheading">La liste des retours validés.
                </div>
            </div>
        </div>
        <!-- <div class="page-title-actions">
            <div class="d-inline-block dropdown">
                <div>
                    <form class="form-inline" action="" method="post" onsubmit="return false;"
                        (onsubmit)="getClient(keyword);">
                        <div class="mb-2 mr-sm-2 mb-sm-0 position-relative form-group">
                            <input name="keyword" id="keyword" placeholder="Client ?" type="keyword"
                                class="form-control" #keyword ngModel>
                        </div>
                        <button type="submit" class="btn-shadow mr-3 btn btn-dark"
                            (click)="getClient(keyword)">
                            <i class="pe-7s-search"></i>
                        </button>
                    </form>
                </div>
            </div>
        </div> -->
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Retours validés
                <!-- <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button class="active btn btn-focus">Last Week</button>
                        <button class="btn btn-focus">All Month</button>
                    </div>
                </div> -->
            </div>
            <div class="table-responsive">
                <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th>Transporteur</th>
                            <th class="text-center">Numéro<br>de téléphone</th>
                            <th class="text-center">Date<br>de retour</th>
                            <th class="text-center">Origine</th>
                            <th class="text-center">Déstination</th>
                            <th class="text-center">Prix</th>
                            <th class="text-center">Etat</th>
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let trans of allReturn, let index = index">
                            <td class="text-center text-muted">#{{index+1}}</td>
                            <td>
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left mr-3">
                                            <div class="widget-content-left">
                                                <img width="40" height="40" class="rounded-circle"
                                                    src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + trans?.driver_id?.user_transportor?.profile_user}}" alt="">
                                                <!-- <ngx-avatar class="rounded-circle" name="{{trans.user_transportor.prenom_user}}
                                                {{trans.user_transportor.nom_user}}">
                                                </ngx-avatar> -->
                                            </div>
                                        </div>
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading">{{trans?.driver_id?.user_transportor?.prenom_user}}
                                                {{trans?.driver_id?.user_transportor?.nom_user}}</div>
                                            <div class="widget-subheading opacity-7">
                                                {{trans?.driver_id?.user_transportor?.email_user}}</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center"><strong>{{trans?.driver_id?.user_transportor?.tel_user}}</strong>
                            </td>
                            <td class="text-center">
                                {{trans?.plan_return_date | date:'d MMM y'}}<br>
                                {{trans?.plan_return_date | date:'HH:mm'}}
                            </td>
                            <td class="text-center">
                                <b>{{trans?.start_palce}}</b>
                            </td>
                            <td class="text-center">
                                <b>{{trans?.end_palce}}</b>
                            </td>
                            <td class="text-center">
                                <b>{{trans?.price}}</b>
                            </td>
                            <td class="text-center">
                                <div class="badge badge-success">Validé</div>
                            </td>
                            <td class="text-center">
                                <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                    <button class="btn-wide btn btn-info">Actions</button>
                                    <button type="button" ngbDropdownToggle
                                        class="dropdown-toggle-split dropdown-toggle btn btn-info"><span
                                            class="sr-only">Actions</span></button>
                                    <div ngbDropdownMenu>
                                        <button type="button" [routerLink]="['/detail-transportor']"
                                            [queryParams]="{ transportor: trans._id }" tabindex="0"
                                            class="dropdown-item">
                                            <i class="metismenu-icon pe-7s-news-paper"
                                                style="margin-right: 10px;"></i>Détails transporteur
                                        </button>
                                        <!-- <button type="button" tabindex="0" *ngIf="element.client.fraisDelivery == null"
                                            [routerLink]="['/frais-client']"
                                            [queryParams]="{ client: element.client._id }" class="dropdown-item"><i
                                                class="metismenu-icon pe-7s-print"
                                                style="margin-right: 10px;"></i>Ajouter frais</button>
                                        <button type="button" tabindex="0" *ngIf="element.client.fraisDelivery != null"
                                            [routerLink]="['/edit-frais-client']"
                                            [queryParams]="{ client: element.client._id }" class="dropdown-item"><i
                                                class="metismenu-icon pe-7s-print"
                                                style="margin-right: 10px;"></i>Modifier frais</button>
                                        <button type="button" tabindex="0" [routerLink]="['/compte-client']"
                                            [queryParams]="{ client: element.client._id }" class="dropdown-item"><i
                                                class="metismenu-icon pe-7s-print"
                                                style="margin-right: 10px;"></i>Compte client</button>
                                        <button type="button" tabindex="0" (click)="pickUpCollector(element.client._id)"
                                            class="dropdown-item"><i class="metismenu-icon pe-7s-print"
                                                style="margin-right: 10px;"></i>Affecter le pick-up</button> -->
                                    </div>
                                </div>
                                <!-- <button [routerLink]="['/detail-cmds-client']"
                                    [queryParams]="{ client: element.client._id }" type="button"
                                    id="PopoverCustomT-1"
                                    class="btn btn-primary btn-sm">Détails</button> -->

                                <!-- <button *ngIf="element.fraisClient == null"
                                    [routerLink]="['/frais-client']"
                                    [queryParams]="{ client: element.client._id }" type="button"
                                    id="PopoverCustomT-1" class="btn btn-info btn-sm">+</button> -->
                                <!-- <button *ngIf="element.fraisClient != null"
                                    [routerLink]="['/edit-frais-client']"
                                    [queryParams]="{ client: element.client._id }" type="button"
                                    id="PopoverCustomT-1" class="btn btn-info btn-sm">-</button> -->

                                <!-- <button [routerLink]="['/compte-client']"
                                    [queryParams]="{ client: element.client._id }" type="button"
                                    id="PopoverCustomT-1" class="btn btn-primary btn-sm">Compte</button> -->

                                <!-- <button type="button" (click)="pickUpCollector(element.client._id)"
                                    id="PopoverCustomT-1"
                                    class="btn btn-warning btn-sm">Affecter</button> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="d-block text-center card-footer">
                <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                        class="pe-7s-trash btn-icon-wrapper"> </i></button>
                <button class="btn-wide btn btn-success">Save</button>
            </div> -->
        </div>
    </div>
</div>